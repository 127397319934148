import { useDebounced } from '@/hooks';
import {
  ChangeEvent,
  KeyboardEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import { Button } from '../Button';
import { Container } from '../Container';
import { CrossDelete, SearchIcon } from '../Icons';
import { Input } from '../Input';

interface FilterSearchBarProps {
  title: string;
  total: number;
  isLoading?: boolean;
  onSearch: (value: string) => void;
  variant?: 'primary' | 'secondary';
  handleInputSearchChange: (value: string) => void;
  searchInputValue: string;
}

export const FilterSearchBar = ({
  total = 0,
  title = '',
  isLoading,
  onSearch,
  children,
  variant = 'primary',
  handleInputSearchChange,
  searchInputValue,
}: PropsWithChildren<FilterSearchBarProps>): ReactElement => {
  const { t } = useTranslation();
  const searchTermDebounced = useDebounced(searchInputValue, 500) as string;

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputSearchChange(event.target.value);
  };

  const handleOnSearch = useCallback(() => {
    onSearch(searchInputValue);
  }, [onSearch, searchInputValue]);

  const handleClearSearch = useCallback(() => {
    handleInputSearchChange('');
    onSearch('');
  }, [onSearch, handleInputSearchChange]);

  const handleKeyPressed = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      onSearch(searchInputValue);
    },
    [onSearch, searchInputValue],
  );

  useEffect(() => {
    onSearch(searchTermDebounced);
  }, [searchTermDebounced, onSearch]);

  return (
    <Container variant={variant} className="flex-row justify-between gap-4">
      <div className="flex items-center gap-2 text-ml font-bold text-blueNuit">
        <div className="flex h-8.75 min-h-8.75 min-w-8.75 items-center justify-center rounded-ml bg-rinseGray px-1.25">
          {total}
        </div>
        <div className="flex h-full items-center self-end align-middle">
          {title}
        </div>
      </div>
      <div className="flex justify-end gap-3">
        <div className="relative flex items-center gap-3">
          {children}
          <Input
            onKeyUp={handleKeyPressed}
            value={searchInputValue}
            type="text"
            isDisabled={isLoading}
            name="key_words"
            className={twJoin(
              'h-10 w-36 rounded-md p-2',
              variant === 'primary' ? 'bg-white' : 'bg-brightGray',
            )}
            placeHolder={t('key_words')}
            onChange={handleSearchChange}
          />
          {!!searchInputValue && (
            <div
              className="pointer-events-auto absolute right-2  flex h-auto cursor-pointer items-center justify-center pl-3 hover:cursor-pointer"
              onClick={handleClearSearch}
            >
              <CrossDelete />
            </div>
          )}
        </div>
        <Button
          variant="text"
          onClick={handleOnSearch}
          className="flex cursor-pointer rounded-md bg-darkBlue p-2"
        >
          <SearchIcon className="text-white" />
        </Button>
      </div>
    </Container>
  );
};
