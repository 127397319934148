import { Button, FilterSearchBar, Table } from '@/components';
import { AttachUserToOrganizationModal } from '@/modules/Organizations/pages/OrganizationsPage/components/AttachUserToOrganizationModal';
import {
  companiesCountColumnConfig,
  organizationNameColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
} from '@/utils';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContext } from '../TabContainer';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { useNavigate } from 'react-router-dom';
import { TableRow } from '../Table/types';

interface OrganizationsTabProps {
  canAttachUser?: boolean;
  canCreate?: boolean;
  userId?: string;
}

export const OrganizationsTab = ({
  canAttachUser,
  canCreate,
  userId,
}: OrganizationsTabProps): ReactElement => {
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const navigate = useNavigate();

  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    page,
    onPageChange,
    handleSearchChange,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/organizations/${row?.id}`;
    navigate(path);
  };

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={organizations?.totalRows || 0}
          title={t('organizations')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        <div className="flex items-center">
          {canCreate && (
            <Button /*onClick={} TODO: add action*/ variant="primary">
              {t('add_organizations')}
            </Button>
          )}
          {canAttachUser && userId && (
            <AttachUserToOrganizationModal userId={parseInt(userId)} />
          )}
        </div>
      </div>
      <Table
        onRowClick={routeChange}
        columns={[
          organizationNameColumnConfig({}),
          statusColumnConfig(),
          usersCountColumnConfig(),
          companiesCountColumnConfig(),
        ]}
        rows={organizations?.items ?? []}
        isLoading={isLoadingOrganizations}
        noResultsLabel={t('no_companies_available')}
        withPagination
        totalItems={organizations?.totalRows}
        totalPages={organizations?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    </>
  );
};
