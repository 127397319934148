import { SortDirection } from '@/components/Table/types';
import { usersRepo } from '@/constants/providers';
import { UserModel } from '@/modules/Users/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UsersKeys } from './types';
export const useGetUsersByCompany = (
  companyId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) =>
  useQuery<DataWithPagination<UserModel[]>>(
    [
      UsersKeys.GET_USERS_BY_COMPANY,
      { companyId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      usersRepo.getUsersByCompany(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
