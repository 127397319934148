import { getFirstLastNameLetters } from '@/utils';
import { ReactElement } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

export type AvatarSize = 'small' | 'medium' | 'large';

export interface UserAvatarProps {
  name?: string;
  size?: AvatarSize;
}

export const UserAvatar = ({
  size = 'medium',
  name,
}: UserAvatarProps): ReactElement => {
  return (
    <div className="relative">
      <div
        className={twMerge(
          'flex items-center justify-center overflow-hidden rounded-full border border-primaryBlue',
          size === 'small' && ' h-6 w-6',
          size === 'medium' && 'h-11 w-11 border-2',
          size === 'large' && 'h-20 w-20 border-2',
        )}
      >
        <div className="font-heading flex h-full w-full items-center justify-center bg-indigo-300 font-bold">
          <span
            className={twJoin(
              'text-primaryBlue',
              size === 'small' && 'text-xs',
              size === 'medium' && 'text-md',
              size === 'large' && 'text-lg',
            )}
          >
            {getFirstLastNameLetters(name ?? '')}
          </span>
        </div>
      </div>
    </div>
  );
};
