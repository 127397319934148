import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type WorkflowsCountColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const workflowsColumnConfig = <T extends TableRow>({
  options,
}: WorkflowsCountColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'workflowsCount',
  label: <Trans i18nKey="workflows" />,
  acessorKey: 'workflowsCount',
  sortable: true,
  ...options,
});
