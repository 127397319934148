import { PropsWithChildren, ReactElement } from 'react';
import { PageContainer } from '../';
import { PageHeader } from './PageHeader';

interface CustomPageContainerProps {
  className?: string;
}

export const CustomPageContainer = ({
  children,
  className,
}: PropsWithChildren<CustomPageContainerProps>): ReactElement => {
  return <PageContainer className={className}>{children}</PageContainer>;
};

CustomPageContainer.Header = PageHeader;
