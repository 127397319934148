import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  Method,
} from 'axios';

type HttpOnRequest = (
  data: any,
  headers: Record<string, string | number | boolean>,
) => any;

interface HttpOnResponse {
  success?: (data: any, headers: Record<string, any>) => any;
  error?: (error: AxiosError, headers: Record<string, any>) => any;
}

export class HttpError<T> extends Error {
  code: number;
  data?: T;

  constructor(message: string, code = 400, data?: T) {
    super(message);
    this.code = code;
    this.data = data;
    this.name = this.constructor.name;
  }
}

export class HttpClient {
  private client: AxiosInstance;
  private readonly resource: string;

  constructor(
    resource: string,
    baseURL = import.meta.env.VITE_API_URL,
    config: AxiosRequestConfig = {},
  ) {
    this.resource = resource;

    this.client = axios.create({
      baseURL,
      ...config,
    });
  }

  protected setOnRequest(onRequest: HttpOnRequest) {
    this.client.interceptors.request.use(
      function onFullFilled(config) {
        return onRequest(config, config.headers);
      },
      function onError(error) {
        return Promise.reject(error);
      },
    );

    return this;
  }

  protected setOnResponse(onResponse: HttpOnResponse) {
    this.client.interceptors.response.use(
      (success) => {
        onResponse.success?.(success.data, success.headers);
        return success;
      },
      (error: AxiosError) => {
        onResponse.error?.(error, error.response?.headers ?? {});
        return Promise.reject(error);
      },
    );

    return this;
  }

  private qs(url: string, query?: Record<string, string>): string {
    const keyValues = query ? Object.entries(query) : [];
    const u = url.charAt(0) === '/' ? url : `${this.resource}/${url}`;

    if (!keyValues.length) return u;

    const qs = keyValues
      .filter(([, v]) => !!v)
      .map((keyValue) => {
        const [k, v] = keyValue;

        if (Array.isArray(v)) {
          return v.map((value) => [k, value].join('=')).join('&');
        }

        return [k, v].join('=');
      })
      .filter(Boolean)
      .join('&');

    if (!qs) return u;

    const joinString = u.includes('?') ? '&' : '?';

    return [u, qs].join(joinString);
  }

  private async request<T, E>(
    method: Method,
    url: string,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    try {
      const request = {
        method,
        url,
        ...config,
      };

      const result = await this.client.request(request);
      return result.data;
    } catch (error) {
      if (!(error as AxiosError).isAxiosError) throw error;

      const err = error as AxiosError;

      const { message, response } = err;
      const { status = 400, data } = response || {};

      throw new HttpError<E>(message, status, data as E);
    }
  }

  async get<T>(options?: {
    url?: string;
    query?: Record<string, any>;
    config?: AxiosRequestConfig;
  }): Promise<T> {
    return this.request(
      'GET',
      this.qs(options?.url ?? '', options?.query),
      options?.config,
    );
  }

  async post<T>(
    options?: { url?: string; body?: any },
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.request('POST', this.qs(options?.url ?? ''), {
      data: options?.body ?? {},
      ...config,
    });
  }

  async put<T>(url = '', body = {}, config?: AxiosRequestConfig): Promise<T> {
    return this.request('PUT', this.qs(url), {
      data: body,
      ...config,
    });
  }

  async patch<T>(url = '', body = {}, config?: AxiosRequestConfig): Promise<T> {
    return this.request('PATCH', this.qs(url), {
      data: body,
      ...config,
    });
  }

  async delete<T>(
    url = '',
    query = {},
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.request('DELETE', this.qs(url, query), config);
  }
}
