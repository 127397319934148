import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  ReactElement,
} from 'react';
import { UseFormRegister } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export interface InputProps {
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  placeHolder?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  isDisabled?: boolean;
  name: string;
  register?: UseFormRegister<any>;
  accept?: string;
  step?: string;
}

export const Input = ({
  type = 'text',
  className,
  placeHolder,
  value,
  name,
  onChange,
  isDisabled,
  register,
  onKeyUp,
  accept,
  step,
}: InputProps): ReactElement => (
  <input
    onKeyUp={onKeyUp}
    onChange={onChange}
    disabled={isDisabled}
    type={type}
    value={value}
    accept={accept}
    step={step}
    className={twMerge(
      'h-10 w-36 rounded-md bg-white p-2 text-md font-normal',
      !value && 'placeholder-spartanBlue',
      value && 'text-primaryBlue',
      className,
    )}
    placeholder={placeHolder}
    {...(register && { ...register(name) })}
  />
);
