import { z } from 'zod';

export const TaskSchema = z.object({
  taskName: z.string().min(1, { message: 'error_field_required' }),
  organizationId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  companyId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  businessAreaId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  workflowId: z.string().optional(),
  taskDescription: z.string().min(1, { message: 'error_field_required' }),
  taskPeriodicity: z.string().min(1, { message: 'error_field_required' }),
  labourWorkload: z
    .string()
    .regex(/\d/)
    .min(1, { message: 'error_field_required' }),
  labourWorkloadType: z.string().min(1, { message: 'error_field_required' }),
  documentation: z.any().optional(),
  averageCases: z
    .string()
    .regex(/^(0*[1-9]\d*(\.\d+)?|0*\.[1-9]\d*)$/, {
      message: 'error_field_must_be_positive',
    })
    .optional(),
});

export type FormTaskSchemaType = z.infer<typeof TaskSchema>;

export interface TaskSchemaType extends FormTaskSchemaType {
  id?: number;
}
