import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { TaskModel } from '@/modules/Tasks/domain';
import { ReactElement } from 'react';

interface TaskPerformanceCardsProps {
  isLoading: boolean;
  data?: TaskModel;
}

export const TaskPerformanceCards = ({
  isLoading,
  data,
}: TaskPerformanceCardsProps): ReactElement => {
  return (
    <PerformanceCardsContainer
      predictedManualTime={data?.predictedManualTime || 0}
      predictedAverageManualTime={data?.predictedAverageManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      averageAutomationRunTime={data?.averageAutomationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      averageCost={data?.averageCost || 0}
      averageManualCost={data?.averageManualCost || 0}
      isLoading={isLoading}
    />
  );
};
