import { ReactNode, useCallback, useMemo } from 'react';
import { EnumSortDirection, SortProps, TableVariant } from '../../types';
import { Button } from '@/components/Button';
import { twJoin, twMerge } from 'tailwind-merge';
import { ArrowUpIcon } from '@/components/Icons';

interface TableHeadSortableCellProps extends SortProps {
  label: ReactNode;
  cellId: string;
  variant: TableVariant;
}

export const TableHeadSortableCell = ({
  cellId,
  label,
  onSort,
  sorting = [],
}: TableHeadSortableCellProps) => {
  const handleSort = useCallback(() => onSort?.(cellId), [cellId, onSort]);

  const sort = useMemo(
    () => sorting.find((s) => s.columnId === cellId),
    [cellId, sorting],
  );

  return (
    <Button
      variant="text"
      disablePadding
      className="group/item gap-1.25 text-xs"
      onClick={handleSort}
    >
      {label}
      <div
        className={twMerge(
          'flex flex-col gap-0.2 text-intercoastalGray group-hover/item:visible',
          !sort && 'invisible',
        )}
      >
        <ArrowUpIcon
          className={twJoin(
            sort?.direction === EnumSortDirection.ascending && 'text-plumBlue',
          )}
        />
        <ArrowUpIcon
          className={twJoin(
            'rotate-180 transform',
            sort?.direction === EnumSortDirection.descending && 'text-plumBlue',
          )}
        />
      </div>
    </Button>
  );
};
