interface PieChartInnerTextProps {
  cx: string;
  cy: string;
  mainLabel: string;
  subtitle?: string;
}

export const renderPieChartInnerText = ({
  cx,
  cy,
  mainLabel,
  subtitle,
}: PieChartInnerTextProps) => (
  <g>
    <text
      className="fill-primaryBlue font-sans text-xl font-black"
      x={cx}
      y={cy}
      dy={4}
      textAnchor="middle"
    >
      {mainLabel}
    </text>
    <text
      className="fill-primaryBlue font-sans text-sm font-normal"
      x={cx}
      y={cy}
      dy={20}
      textAnchor="middle"
    >
      {subtitle}
    </text>
  </g>
);
