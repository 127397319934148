import {
  AutomationsTab,
  BusinessAreasTab,
  Button,
  ChevronLeft,
  CompaniesTab,
  CustomPageContainer,
  ProcessesTab,
  TabItemProps,
  TableWithFilter,
  TasksTab,
  UsersTab,
  WorkflowsTab,
} from '@/components';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { AutomationModel } from '@/modules/Automations/domain';
import { getAutomationColumnsNamedMapped } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationsByOrganization } from '@/modules/Automations/queries';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { getBusinessAreaColumnsNamedMapped } from '@/modules/BusinessAreas/pages/BusinessAreasPage/utils';
import { useGetBusinessAreasByOrganization } from '@/modules/BusinessAreas/queries';
import { CompanyModel } from '@/modules/Companies/domain';
import { getCompaniesColumnsNamedMapped } from '@/modules/Companies/pages/CompaniesPage/utils';
import { useGetCompaniesByOrganization } from '@/modules/Companies/queries';
import { getProcessesColumnsNamedMapped } from '@/modules/Processes';
import { ProcessModel } from '@/modules/Processes/domain';
import { useGetProcessesByOrganization } from '@/modules/Processes/queries';
import { TaskModel } from '@/modules/Tasks/domain';
import { getTasksColumnsNamedMapped } from '@/modules/Tasks/pages/TasksPage';
import { useGetTasksByOrganization } from '@/modules/Tasks/queries';
import { UserModel } from '@/modules/Users/domain';
import { getUsersColumnsNamedMapped } from '@/modules/Users/pages/UsersPage/utils';
import { useGetUsersByOrganization } from '@/modules/Users/queries';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { getWorflowsColumnsNamedMapped } from '@/modules/Workflows/pages/WorkflowsPage/utils';
import { useGetWorkflowsByOrganization } from '@/modules/Workflows/queries';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OrganizationStatus } from '../..';
import { useDisableOrganization } from '../../queries/disabled-organization';
import { useEnableOrganization } from '../../queries/enable-organization';
import { useGetOrganization } from '../../queries/get-organization';
import { HeaderRow } from './components/HeaderRow';
import { OrganizationPerformanceCards } from './components/OrganizationPerformanceCards';

export const OrganizationDetailPage = (): ReactElement => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    data: organization,
    isLoading: isLoadingOrganizations,
    isError,
    error,
  } = useGetOrganization(organizationId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_organization_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(organization?.status === OrganizationStatus.Active);
  }, [organization]);

  const { mutate: enableOrganization } = useEnableOrganization();
  const { mutate: disableOrganization } = useDisableOrganization();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!organization?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableOrganization(organization?.id, {
          onError: () => {
            toast.error(t('error_enable_organization'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_organization'));
          },
        });
      } else {
        disableOrganization(organization?.id, {
          onError: () => {
            toast.error(t('error_disable_organization'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_organization'));
          },
        });
      }
    },
    [organization, enableOrganization, disableOrganization, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('users'), badgeNumber: organization?.usersCount },
      { label: t('companies'), badgeNumber: organization?.companiesCount },
      {
        label: t('business_areas'),
        badgeNumber: organization?.businessAreasCount,
      },
      {
        label: t('processes'),
        badgeNumber: organization?.processesCount,
      },
      { label: t('workflows'), badgeNumber: organization?.workflowsCount },
      { label: t('tasks'), badgeNumber: organization?.tasksCount },
      { label: t('automations'), badgeNumber: organization?.automationsCount },
    ],
    [t, organization],
  );

  const routeChange = useCallback(() => {
    navigate('/organizations');
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!organizationId) {
      return [];
    }
    return [
      {
        tab: (
          <TabContainer<UserModel>
            parentId={parseInt(organizationId)}
            useGetData={useGetUsersByOrganization}
            getColumnsNamedMapped={getUsersColumnsNamedMapped}
          >
            <UsersTab organizationId={organization?.id} showRole />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<CompanyModel>
            parentId={parseInt(organizationId)}
            useGetData={useGetCompaniesByOrganization}
            getColumnsNamedMapped={getCompaniesColumnsNamedMapped}
          >
            <CompaniesTab
              canCreate
              disableFields={['role', 'organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<BusinessAreaModel>
            parentId={parseInt(organizationId)}
            useGetData={useGetBusinessAreasByOrganization}
            getColumnsNamedMapped={getBusinessAreaColumnsNamedMapped}
          >
            <BusinessAreasTab
              canCreate
              disableFields={['organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<ProcessModel>
            parentId={parseInt(organizationId)}
            useGetData={useGetProcessesByOrganization}
            getColumnsNamedMapped={getProcessesColumnsNamedMapped}
          >
            <ProcessesTab
              canCreate
              disableFields={['organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },

      {
        tab: (
          <TabContainer<WorkflowModel>
            parentId={parseInt(organizationId)}
            useGetData={useGetWorkflowsByOrganization}
            getColumnsNamedMapped={getWorflowsColumnsNamedMapped}
          >
            <WorkflowsTab
              canCreate
              disableFields={['organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<TaskModel>
            parentId={parseInt(organizationId)}
            useGetData={useGetTasksByOrganization}
            getColumnsNamedMapped={getTasksColumnsNamedMapped}
          >
            <TasksTab
              canCreate
              disableFields={['organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<AutomationModel>
            parentId={parseInt(organizationId)}
            getColumnsNamedMapped={getAutomationColumnsNamedMapped}
            useGetData={useGetAutomationsByOrganization}
          >
            <AutomationsTab
              canCreate
              disableFields={['organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
    ];
  }, [organizationId, organization]);

  return (
    <CustomPageContainer>
      <div>
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        {organization && (
          <p className="mt-3 text-xs">
            {t('organizations')} /{' '}
            <span className="font-black">{organization?.name || ''}</span>
          </p>
        )}
      </div>
      <HeaderRow
        organization={organization}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingOrganizations}
      />
      <OrganizationPerformanceCards
        isLoading={isLoadingOrganizations}
        data={organization}
      />
      <TableWithFilter>
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TableWithFilter>
    </CustomPageContainer>
  );
};
