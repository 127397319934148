import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowsQueryKey } from './types';
import { WorkflowModel } from '../domain';
import { DataWithPagination } from '@/shared/types';
import { SortDirection } from '@/components/Table/types';

export const useGetWorkflows = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<WorkflowModel[]>,
    any,
    DataWithPagination<WorkflowModel[]>
  >,
) =>
  useQuery<DataWithPagination<WorkflowModel[]>>(
    [WorkflowsQueryKey.GET_All, { pageNumber, filter, sortBy, sortOrder }],
    () => workflowsRepo.getWorkflows(pageNumber, filter, sortBy, sortOrder),
    options,
  );
