import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { BusinessAreasQueryKey } from './types';
import { BusinessAreaModel } from '../domain';
import { businessAreasRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { SortDirection } from '@/components/Table/types';

export const useGetBusinessAreas = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<BusinessAreaModel[]>,
    any,
    DataWithPagination<BusinessAreaModel[]>
  >,
) =>
  useQuery<DataWithPagination<BusinessAreaModel[]>>(
    [BusinessAreasQueryKey.GET_ALL, { pageNumber, filter, sortBy, sortOrder }],
    () =>
      businessAreasRepo.getAllBusinessAreas(
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
