import { SortDirection } from '@/components/Table/types';
import { usersRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UserRateModel } from '../domain/user-rate-model';
import { UsersKeys } from './types';

export const useGetUserRates = (
  userId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserRateModel[]>,
    any,
    DataWithPagination<UserRateModel[]>
  >,
) =>
  useQuery<DataWithPagination<UserRateModel[]>>(
    [
      UsersKeys.GET_USER_RATES,
      userId,
      { pageNumber, filter, sortBy, sortOrder },
    ],
    () => usersRepo.getUserRate(userId),
    options,
  );
