import { TableColumn, TableRow } from '@/components/Table/types';
import {
  dateColumnConfig,
  nameWithAvatarColumnConfig,
  rateColumnConfig,
  statusColumnConfig,
} from '@/utils';
import { emailColumnConfig } from '@/utils/tableColumns/email-column-config';
import { UserModel } from '../../domain';
import { EntityTypes } from '../../types/users-csv-import-company-model';
import { UserRateModel } from '../../domain/user-rate-model';
import { actionsEditRowColumnConfig } from '@/utils/tableColumns/actions-edit-row-column-config';

type UsersColumnsConfig = {
  onNameClick?: (row: TableRow) => void;
  onEditClick?: (row: TableRow) => void;
};

export const getUsersColumns = ({
  onNameClick,
}: UsersColumnsConfig): Array<TableColumn<UserModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick: onNameClick }),
    emailColumnConfig(),
    statusColumnConfig(),
  ];
};
export const getUsersColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'FirstName',
    email: 'Email',
    status: 'Status',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

export const getEntityType = (data: {
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  taskId?: number;
}): EntityTypes | null => {
  if (data.businessAreaId) {
    return EntityTypes.BusinessArea;
  }
  if (data.companyId) {
    return EntityTypes.Company;
  }
  if (data.taskId) {
    return EntityTypes.Task;
  }
  if (data.organizationId) {
    return EntityTypes.Organization;
  }
  return null;
};

export const getUserRatesColumns = ({
  onClick,
  columnsToEdit,
}: {
  onClick: (row: number) => void;
  columnsToEdit?: number[];
}): Array<TableColumn<UserRateModel>> => {
  return [
    rateColumnConfig(),
    dateColumnConfig({ key: 'startDate' }, 'start_date'),
    dateColumnConfig({ key: 'endDate' }, 'end_date'),
    actionsEditRowColumnConfig({
      onClick(row) {
        onClick(row.id as number);
      },
      canEdit: (row) => !!columnsToEdit?.includes(row.id as number),
    }),
  ];
};
