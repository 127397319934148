import { Modal } from '@/components';
import { TaskModel } from '@/modules/Tasks/domain';
import { WorkflowSchemaType } from '@/modules/Workflows/types';
import { ReactElement } from 'react';
import { WorkflowForm } from '../WorkflowForm';

interface WorkflowModalProps {
  handleOnSubmit: (e: WorkflowSchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  processId?: number;
  description?: string;
  workflowName?: string;
  disableFields?: string[];
  tasks?: TaskModel[];
}

export const WorkflowModal = (props: WorkflowModalProps): ReactElement => {
  const { isOpen, title, handleClose } = props;

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      <WorkflowForm {...props} />
    </Modal>
  );
};
