import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type RoleColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const roleColumnConfig = <T extends TableRow>({
  options,
}: RoleColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'profileType',
  label: <Trans i18nKey="role" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{value}</div>
  ),
  acessorKey: 'profileType',
  sortable: true,
  ...options,
});
