import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProcessesQueryKey } from './types';
import { processesRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { ProcessModel } from '../domain';
import { SortDirection } from '@/components/Table/types';

export const useGetProcesses = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<ProcessModel[]>,
    any,
    DataWithPagination<ProcessModel[]>
  >,
) =>
  useQuery<DataWithPagination<ProcessModel[]>>(
    [ProcessesQueryKey.ALL, { pageNumber, filter, sortBy, sortOrder }],
    () => processesRepo.getAllProcesses(pageNumber, filter, sortBy, sortOrder),
    options,
  );
