import { toast } from 'react-toastify';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessAreaModal } from '../BusinessAreaModal';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateBusinessArea } from '@/modules/BusinessAreas/queries/create-businessArea';
import { BusinessAreasQueryKey } from '@/modules/BusinessAreas/queries/types';
import { BusinessAreaSchemaType } from '@/modules/BusinessAreas/types/businessArea-schema';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/types';

interface CreateBusinessAreaModal {
  isOpen: boolean;
  handleClose: () => void;
  companyId?: number;
  disableFields?: string[];
  organizationId?: number;
  onSubmit?: () => void;
}

export const CreateBusinessAreaModal = ({
  isOpen,
  handleClose,
  companyId,
  disableFields,
  organizationId,
  onSubmit,
}: CreateBusinessAreaModal): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateBusinessArea();

  const handleConfirm = (data: BusinessAreaSchemaType) => {
    const convertedData = {
      id: data.id ?? 0,
      companyId: Number(data.companyId),
      name: data.businessAreaName,
      code: data.businessAreaCode,
      status: true,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_businessArea'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([BusinessAreasQueryKey.GET_ALL]);
        queryClient.invalidateQueries([
          BusinessAreasQueryKey.GET_BUSINESS_AREAS_BY_ORGANIZATION,
        ]);
        queryClient.invalidateQueries([
          BusinessAreasQueryKey.GET_BUSINESS_AREAS_BY_COMPANY,
        ]);
        queryClient.invalidateQueries([
          BusinessAreasQueryKey.GET_BUSINESS_AREAS_BY_USER,
        ]);
        queryClient.invalidateQueries([BusinessAreasQueryKey.GET]);
        queryClient.invalidateQueries([CompanyKeys.GET]);
        queryClient.invalidateQueries([OrganizationKeys.GET]);

        handleClose();
        toast.success(t('success_creating_businessArea'));
        onSubmit && onSubmit();
      },
    });
  };

  return (
    <BusinessAreaModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      isOpen={isOpen}
      isLoading={isLoading}
      title={t('create_business_area')}
      companyId={companyId}
      organizationId={organizationId}
      disableFields={disableFields}
    />
  );
};
