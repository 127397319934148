import { mapPagination } from '@/shared/mappers';
import { CompanyModel } from '../domain';
import { ApiResponseCompanies, CompanyJson, PagedCompanies } from '../types';

export const companyMapper = (companyJson: CompanyJson): CompanyModel => ({
  id: companyJson?.id ?? 0,
  organizationId: companyJson?.organizationId ?? 0,
  organizationName: companyJson?.organizationName ?? '',
  name: companyJson?.name ?? '',
  code: companyJson?.code ?? '',
  nif: companyJson?.nif ?? '',
  status: companyJson?.status ?? false,
  address: {
    id: companyJson?.id ?? 0,
    line1: companyJson?.address?.line1 ?? '',
    line2: companyJson?.address?.line2 ?? '',
    city: companyJson?.address?.city ?? '',
    country: companyJson?.address?.country ?? '',
    postalCode: companyJson?.address?.postalCode ?? '',
  },
  usersCount: companyJson?.usersCount ?? 0,
  businessAreasCount: companyJson?.businessAreasCount ?? 0,
  processesCount: companyJson?.processesCount ?? 0,
  tasksCount: companyJson?.tasksCount ?? 0,
  automationsCount: companyJson?.automationsCount ?? 0,
  predictedManualTime: companyJson?.predictedManualTime ?? 0,
  predictedAverageManualTime: companyJson?.predictedAverageManualTime ?? 0,
  automationRunTime: companyJson?.automationRunTime ?? 0,
  averageAutomationRunTime: companyJson?.averageAutomationRunTime ?? 0,
  totalInvestment: companyJson?.totalInvestment ?? 0,
  totalReturn: companyJson?.totalReturn ?? 0,
  averageCost: companyJson?.averageCost ?? 0,
  averageManualCost: companyJson?.averageManualCost ?? 0,
  jiraLink: companyJson?.jiraLink ?? '',
  workflowsCount: companyJson?.workflowsCount ?? 0,
});

export const companiesWithPaginationMapper = (
  companiesJson: ApiResponseCompanies<PagedCompanies>,
) => {
  const { data } = companiesJson;
  return {
    items: companiesMapper(data.companies.items),
    ...mapPagination(data.companies),
  };
};

export const companiesMapper = (
  companiesJson: Array<CompanyJson>,
): Array<CompanyModel> => companiesJson.map(companyMapper);
