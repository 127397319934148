import { ReactElement } from 'react';

interface PerformanceCardSkeletonProps {
  count?: number;
}

export const CardSkeleton = ({
  count,
}: PerformanceCardSkeletonProps): ReactElement => (
  <div className="flex w-full gap-2">
    {Array.from({ length: count ?? 1 }).map((_, index) => (
      <div
        key={index}
        className="min-w-36 flex-1 flex-col gap-2 rounded-md border border-transparent bg-white p-2"
      >
        <div className="loading-line h-5 w-full rounded-md" />
        <div className="loading-line h-3 w-[60%] rounded-md" />
        <div className="loading-line h-7 w-[30%] rounded-md" />
      </div>
    ))}
  </div>
);
