export enum AutomationQueryKey {
  AutomationPerformanceById = 'automation-performance-by-id',
  ById = 'automation-by-id',
  GET_ALL = 'get-automations',
  GET_STAGES = 'get-automation-stages',
  TasksPerformanceById = 'tasks-performance-by-id',
  GET_AUTOMATIONS_BY_BUSINESS_AREA = 'get-automations-by-business-area',
  GET_AUTOMATIONS_BY_COMPANY = 'get-automation-by-company',
  GET_AUTOMATIONS_BY_ORGANIZATION = 'get-automations-by-organization',
  GET_AUTOMATIONS_BY_TASK = 'get-automations-by-task',
}
