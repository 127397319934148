import { Button, FilterSearchBar, Table } from '@/components';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { AttachUserToBusinessModal } from '@/modules/BusinessAreas/pages/BusinessAreasPage/components/AttachUserToBusinessModal';
import { CreateBusinessAreaModal } from '@/modules/BusinessAreas/pages/BusinessAreasPage/components/CreateBusinessAreaModal';
import {
  automationsCountColumnConfig,
  codeColumnConfig,
  companyNameColumnConfig,
  nameWithAvatarColumnConfig,
  processesCountColumnConfig,
  statusColumnConfig,
  tasksCountColumnConfig,
  usersCountColumnConfig,
  workflowsColumnConfig,
} from '@/utils';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface BusinessAreasTabProps {
  canAttachUser?: boolean;
  canCreate?: boolean;
  userId?: string;
  disableFields?: string[];
  organizationId?: number;
  companyId?: number;
}

export const BusinessAreasTab = ({
  canAttachUser,
  canCreate,
  userId,
  disableFields,
  organizationId,
  companyId,
}: BusinessAreasTabProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();

  const routeChange = (row?: TableRow) => {
    const path = `/business-areas/${row?.id}`;
    navigate(path);
  };

  const {
    data,
    isLoading,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={data?.totalRows || 0}
          title={t('business_areas')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        <div className="flex items-center">
          {canCreate && (
            <Button onClick={handleModalState} variant="primary">
              {t('add_business_areas')}
            </Button>
          )}
          {canAttachUser && userId && (
            <AttachUserToBusinessModal userId={parseInt(userId)} />
          )}
        </div>
      </div>
      <CreateBusinessAreaModal
        handleClose={handleModalState}
        isOpen={isOpen}
        disableFields={disableFields}
        organizationId={organizationId}
        companyId={companyId}
      />
      <Table
        onRowClick={routeChange}
        columns={[
          nameWithAvatarColumnConfig({}),
          codeColumnConfig(),
          statusColumnConfig(),
          companyNameColumnConfig(),
          usersCountColumnConfig(),
          processesCountColumnConfig(),
          workflowsColumnConfig(),
          tasksCountColumnConfig(),
          automationsCountColumnConfig({
            options: {
              className: 'w-25',
            },
          }),
        ]}
        rows={data?.items ?? []}
        isLoading={isLoading}
        noResultsLabel={t('no_business_areas_available')}
        withPagination
        totalItems={data?.totalRows}
        totalPages={data?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </>
  );
};
