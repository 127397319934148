import { CompanyKeys, useUpdateCompany } from '@/modules/Companies/queries';
import { CompanySchemaType, EditCompanyJson } from '@/modules/Companies/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CompanyModal } from '../CompanyModal/CompanyModal';
import { useGetCompany } from '@/modules/Companies/queries/get-company';

interface EditCompanyModal {
  isOpen: boolean;
  id?: number;
  handleClose: () => void;
}

export const EditCompanyModal = ({
  isOpen,
  id,
  handleClose,
}: EditCompanyModal): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateCompany();
  const { data: company, isLoading: isLoadingGet } = useGetCompany(
    id?.toString(),
  );

  const handleConfirm = (data: CompanySchemaType) => {
    if (!company) {
      return;
    }

    const convertedData: EditCompanyJson = {
      id: data.id ?? company.id,
      organizationId: Number(data.organizationId),
      name: data.companyName ?? company.name,
      nif: Number(data.nif),
      code: data.companyCode ?? company.code,
      status: true,
      addressId: company.address.id,
      line1: data.addressLineOne ?? company.address.line1,
      line2: data.addressLineTwo ?? company.address.line2,
      city: data.city ?? company.address.city,
      country: data.country ?? company.address.country,
      postalCode: data.postCode ?? company.address.postalCode,
      jiraLink: data.jiraLink ?? company.jiraLink,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_company'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([CompanyKeys.GET]);
        handleClose();
        toast.success(t('success_update_company'));
      },
    });
  };

  return (
    <CompanyModal
      type="edit"
      handleOnSubmit={handleConfirm}
      handleClose={handleClose}
      title={t('edit_company')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingGet}
      organizationId={company?.organizationId}
      companyName={company?.name}
      companyCode={company?.code}
      nif={company?.nif}
      addressLineOne={company?.address?.line1}
      addressLineTwo={company?.address?.line2}
      postCode={company?.address.postalCode}
      country={company?.address?.country}
      city={company?.address?.city}
    />
  );
};
