import { StageModel } from '../domain/stage-model';
import { StageJson } from '../types/stage-json';

export const stageMapper = (stageJson: StageJson): StageModel => {
  return {
    id: stageJson?.id ?? 0,
    name: stageJson?.name ?? '',
  };
};

export const stagesMapper = (stagesJson: Array<StageJson>): Array<StageModel> =>
  stagesJson.map(stageMapper);
