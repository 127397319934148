import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import { ProcessModel } from '../domain';
import {
  mapProcessJsonToProcessModel,
  processesMapper,
  processesWithPaginationMapper,
} from '../mappers';
import {
  ApiResponseProcess,
  ApiResponseProcesses,
  AssociateWorkflowToProcess,
  CreateProcessJson,
  DisassociateWorkflowFromProcess,
  EditProcessJson,
  PagedProcesses,
  ProcessJson,
} from '../types';
import { AttachUserProcessModel } from '../types/attach-user-entity-model';
import { ProcessRepository } from './process.repository';

export class HttpProcessRepository extends ProcessRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getAllProcesses(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processesJson = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: '/me/processes',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processesJson);
  }

  createProcess = async (data: CreateProcessJson): Promise<ProcessModel> => {
    const newCompany = await this.client.post<ProcessJson>({ body: data });
    return mapProcessJsonToProcessModel(newCompany);
  };

  attachUserProcess = async (data: AttachUserProcessModel): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-user`,
    });
  };

  getProcess = async (id: string): Promise<ProcessModel> => {
    const process = await this.client.get<ApiResponseProcess<ProcessJson>>({
      url: `${id}`,
    });

    return mapProcessJsonToProcessModel(process.data.process);
  };

  enableProcess = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableProcess = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  updateProcess = async (
    data: EditProcessJson,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.put<ApiResponse<unknown>>(`${data.id}`, data);
  };

  async getProcessesByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/business-areas/${businessAreaId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  async getAllProcessesByBusinessArea(
    businessAreaId: number,
  ): Promise<ProcessModel[]> {
    const processes = await this.client.get<
      ApiResponseProcesses<ProcessJson[]>
    >({
      url: `/business-areas/${businessAreaId}/processes/base-info`,
    });

    return processesMapper(processes.data.processes);
  }

  async getProcessesByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/companies/${companyId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  async getProcessesByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/organizations/${organizationId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  async getProcessesByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/users/${userId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  associateWorkflowToProcess = async (
    data: AssociateWorkflowToProcess,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `associate-workflow`,
      body: data,
    });
  };

  disassociateWorkflowFromProcess = async (
    data: DisassociateWorkflowFromProcess,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `disassociate-workflow`,
      body: data,
    });
  };
}
