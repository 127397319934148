import { SelectDropdownOption } from '@/components';

export const getDropdownArray = (
  data: unknown,
): Array<SelectDropdownOption<string>> => {
  return [
    ...Object.values(data ?? []).map((val) => ({
      label: val.name,
      value: val?.id.toString(),
    })),
  ];
};
