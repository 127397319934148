import { useQuery } from '@tanstack/react-query';
import { TaskModel } from '../domain';
import { TasksQueryKey } from './types';
import { tasksRepo } from '@/constants/providers';

export const useGetTask = (taskId: string = '') =>
  useQuery<TaskModel>(
    [TasksQueryKey.GET, taskId],
    () => tasksRepo.getTask(taskId),
    {
      enabled: !!taskId,
    },
  );
