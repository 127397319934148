import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface SearchAutoCompleteProps {
  options: Array<{ id: number; value: string }>;
  value?: string;
  placeholder?: string;
  onChange: (e: string) => void;
  onSelect: (e: { id: number; value: string }) => void;
}

export const SearchAutoComplete = ({
  options = [],
  value,
  placeholder,
  onChange,
  onSelect,
}: SearchAutoCompleteProps) => {
  const autocomplete = useRef<HTMLDivElement>(null);

  const [isShow, setIsShow] = useState(false);

  const handleClickOutSide = (e: MouseEvent) => {
    if (!autocomplete?.current?.contains(e.target as Node)) {
      setIsShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  useEffect(() => {
    options?.length !== 0 ? setIsShow(true) : setIsShow(false);
  }, [options]);

  return (
    <div ref={autocomplete} className="relative">
      <input
        type={'search'}
        value={value}
        className={twMerge(
          'h-10 w-36 rounded-md bg-white p-2 text-md font-normal',
          !value && 'placeholder-spartanBlue',
          value && 'text-primaryBlue',
          'w-full bg-brightGray italic',
        )}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      {isShow && (
        <div className="absolute z-10 mt-1 flex max-h-80 w-full flex-col items-center justify-start overflow-y-auto rounded-md border-2 border-solid border-brightGray bg-white">
          {options.map((option, index) => (
            <div
              className="w-full cursor-pointer p-2 text-left hover:bg-brightGray"
              onClick={() => {
                setIsShow(false);
                onSelect(option);
              }}
              key={index}
            >
              {<div>{option.value}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
