import { PropsWithChildren, ReactElement, RefObject, MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonStyleVariant, buttonStylesVariants } from './styles';

interface ButtonProps {
  className?: string;
  variant?: ButtonStyleVariant;
  buttonRef?: RefObject<HTMLButtonElement>;
  isDisabled?: boolean;
  disablePadding?: boolean;
  buttonType?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (e: MouseEvent) => void;
}

export const Button = ({
  className,
  disablePadding,
  buttonRef,
  onClick,
  variant = 'primary',
  children,
  buttonType = 'button',
  isDisabled,
}: PropsWithChildren<ButtonProps>): ReactElement => (
  <button
    ref={buttonRef}
    disabled={isDisabled}
    type={buttonType}
    className={twMerge(
      'flex cursor-pointer items-center justify-center py-5 text-sm font-bold',
      variant && buttonStylesVariants[variant],
      isDisabled && 'opacity-50',
      disablePadding && 'p-0',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
