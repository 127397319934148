import { AssociatedTasksModel } from '../domain/associated-tasks-model';
import { AssociatedTasksJson } from '../types/associated-tasks-json';

export const associatedTaskMapper = (
  associatedTasksJson: AssociatedTasksJson,
): AssociatedTasksModel => {
  return {
    id: associatedTasksJson?.id ?? '',
    title: associatedTasksJson.title ?? '',
    timeSaved: {
      total: {
        value: associatedTasksJson?.timeSaved?.total?.value ?? 0,
        predicted: associatedTasksJson?.timeSaved?.total?.predicted ?? 0,
      },
      average: {
        value: associatedTasksJson?.timeSaved?.average?.value ?? 0,
        predicted: associatedTasksJson?.timeSaved?.average?.predicted ?? 0,
      },
    },
    investmentReturn: {
      total: {
        value: associatedTasksJson?.investmentReturn?.totalReturn?.value ?? 0,
        totalInvestment:
          associatedTasksJson?.investmentReturn?.totalReturn?.totalInvestment ??
          0,
      },
      average: {
        value:
          associatedTasksJson?.investmentReturn?.averageReturnPerCase?.value ??
          0,
      },
    },
  };
};

export const associatedTasksMapper = (
  associatedTasksJson: Array<AssociatedTasksJson>,
): Array<AssociatedTasksModel> => associatedTasksJson.map(associatedTaskMapper);
