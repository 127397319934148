import { CompanyKeys, useCreateCompany } from '@/modules/Companies/queries';
import {
  CompanySchemaType,
  CreateCompanyJson,
} from '@/modules/Companies/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CompanyModal } from '../CompanyModal';
import { OrganizationKeys } from '@/modules/Organizations/queries/types';

interface CreateCompanyModalProps {
  isOpen: boolean;
  handleClose: () => void;
  organizationId?: number;
  disableFields?: string[];
}

export const CreateCompanyModal = ({
  isOpen,
  handleClose,
  organizationId,
  disableFields,
}: CreateCompanyModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateCompany();

  const handleConfirm = (data: CompanySchemaType) => {
    const convertedData: CreateCompanyJson = {
      organizationId: Number(data.organizationId),
      status: true,
      address: {
        line1: data.addressLineOne,
        line2: data.addressLineTwo,
        postalCode: data.postCode,
        country: data.country,
        city: data.city,
      },
      name: data.companyName,
      code: data.companyCode,
      nif: Number(data.nif),
      jiraLink: data.jiraLink,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_company'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([CompanyKeys.ALL]);
        queryClient.invalidateQueries([OrganizationKeys.GET]);
        handleClose();
        toast.success(t('success_creating_company'));
      },
    });
  };

  return (
    <CompanyModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_company')}
      isOpen={isOpen}
      isLoading={isLoading}
      organizationId={organizationId}
      disableFields={disableFields}
    />
  );
};
