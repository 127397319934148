import { z } from 'zod';

export const UserSchema = z.object({
  status: z.boolean({ required_error: 'error_field_required' }),
  role: z.string({ required_error: 'error_field_required' }).nullable(),
  firstName: z.string().min(1, { message: 'error_field_required' }),
  lastName: z.string().min(1, { message: 'error_field_required' }),
  email: z.string().min(1, { message: 'error_field_required' }),
  organizationId: z.string().optional(),
  companyId: z.string().optional(),
  businessAreaId: z.string().optional(),
});

export const UserRoleSchema = z.object({
  role: z.number({ required_error: 'error_field_required' }),
});

export const UserWithoutRoleSchema = UserSchema.omit({ role: true });

export type FormUserSchemaType = z.infer<typeof UserSchema>;

export interface UserSchemaType extends FormUserSchemaType {
  id?: number;
}

export type UserWithoutRoleSchemaType = Omit<UserSchemaType, 'role'>;

export type FormUserRoleSchemaType = z.infer<typeof UserRoleSchema>;

export interface UserRoleSchemaType extends FormUserRoleSchemaType {
  id?: number;
}
