import { TableColumn, TableRow } from '@/components/Table/types';
import {
  nameWithAvatarColumnConfig,
  codeColumnConfig,
  businessAreaNameColumnConfig,
  statusColumnConfig,
  workflowsColumnConfig,
  tasksCountColumnConfig,
  estimatedCostColumnConfig,
} from '@/utils';
import { ProcessModel } from '../../domain';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';

type CompaniesColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getProcessesColumns = ({
  onClick,
}: CompaniesColumnsConfig): Array<TableColumn<ProcessModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    codeColumnConfig(),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    statusColumnConfig(),
    workflowsColumnConfig(),
    tasksCountColumnConfig(),
    estimatedCostColumnConfig(),
  ];
};

export const getProcessesColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    code: 'Code',
    businessAreaName: 'BusinessAreaName',
    tasks: 'TasksCount',
    workflowsCount: 'WorkflowsCount',
    runTime: 'RunTime',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};
