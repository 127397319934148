import { UserAvatar } from '@/components';
import { TableColumn, TableRow } from '@/components/Table/types';
import { nameColumnConfig } from './';

type NameWithAvatarColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onClick?: (row: T) => void;
};

export const nameWithAvatarColumnConfig = <T extends TableRow>({
  options,
  onClick,
}: NameWithAvatarColumnConfig<T>): TableColumn<T> =>
  nameColumnConfig({
    ...options,
    render: (value, row) => (
      <div
        className="flex items-center gap-2.5 overflow-hidden"
        onClick={() => onClick?.(row)}
      >
        <UserAvatar size="small" name={value as string} />
        <div className="cursor-pointer overflow-hidden text-ellipsis font-black">
          {value}
        </div>
      </div>
    ),
  });
