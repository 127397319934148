import { UserAvatar } from '@/components';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { useTranslation } from 'react-i18next';
import { SwitchStatus } from '@/components';
import { SyntheticEvent, useCallback, useState } from 'react';
import { OrganizationStatus } from '@/modules/Organizations/types';
import { EditOrganizationModal } from '../../../OrganizationsPage/components/EditOrganizationModal';

interface HeaderRowProps {
  organization?: OrganizationModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  status: boolean;
  isLoading?: boolean;
}

export const HeaderRow = ({
  organization,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <Container isLoading={!organization}>
      <div className="flex justify-between">
        <div className="flex items-center gap-5">
          <UserAvatar name={organization?.name || ''} />
          <h2>{organization?.name || ''}</h2>
        </div>
        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span
              className={`p-0 ${status ? 'text-americanGreen' : 'brightGray'}`}
            >
              {status ? OrganizationStatus.Active : OrganizationStatus.Inactive}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_organization')}
          </Button>
          <EditOrganizationModal
            isOpen={isOpen}
            organizationId={organization?.id}
            handleClose={handleModalState}
          />
        </div>
      </div>
      <Card title={t('description')}>
        <p>{organization?.description || ''}</p>
      </Card>
    </Container>
  );
};
