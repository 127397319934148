import { Button, FilterSearchBar, Table } from '@/components';
import { getAutomationsColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateAutomationModal } from '@/modules/Automations/pages/AutomationsPage/components/CreateAutomationModal';
import { TabContext } from '../TabContainer';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { useNavigate } from 'react-router-dom';
import { TableRow } from '../Table/types';

interface AutomationsTabProps {
  canCreate?: boolean;
  disableFields?: string[];
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
}

export const AutomationsTab = ({
  canCreate = true,
  disableFields,
  organizationId,
  companyId,
  businessAreaId,
}: AutomationsTabProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const {
    data: automations,
    isLoading: isLoadingAutomations,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/automations/${row?.id}`;
    navigate(path);
  };

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={automations?.totalRows || 0}
          title={t('automations')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        {canCreate && (
          <div className="flex items-center">
            <Button onClick={handleModalState} variant="primary">
              {t('add_automations')}
            </Button>
          </div>
        )}
      </div>
      <CreateAutomationModal
        isOpen={isOpen}
        handleClose={handleModalState}
        organizationId={organizationId}
        disableFields={disableFields}
        companyId={companyId}
        businessAreaId={businessAreaId}
      />
      <Table
        onRowClick={routeChange}
        columns={getAutomationsColumns({})}
        rows={automations?.items ?? []}
        isLoading={isLoadingAutomations}
        noResultsLabel={t('no_automations_available')}
        withPagination
        totalItems={automations?.totalRows}
        totalPages={automations?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </>
  );
};
