import { Button, ChevronLeft, CustomPageContainer } from '@/components';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FilesRow } from './components/FilesRow';
import { SchedulingRow } from './components/SchedulingRow';
import { HeaderRow } from './components/HeaderRow';
import { AutomationPerformance } from './components/AutomationPerformance';
import { HttpStatusCode } from 'axios';
import { RoutePath } from '@/core/router/route-paths';
import { HttpError } from '@/core/http';
import {
  useDisableAutomation,
  useEnableAutomation,
  useGetAutomation,
} from '../../queries';
import { t } from 'i18next';
import { toast } from 'react-toastify';

export const AutomationDetailPage = (): ReactElement => {
  const { automationId } = useParams<{ automationId: string }>();
  const navigate = useNavigate();
  const [status, setStatus] = useState<boolean>(false);

  const { mutate: enableAutomation } = useEnableAutomation();
  const { mutate: disableAutomation } = useDisableAutomation();

  const {
    data: automation,
    isLoading: isLoadingAutomation,
    isError,
    error,
  } = useGetAutomation(automationId);

  useEffect(() => {
    setStatus(automation?.status === true);
    if (
      isError &&
      error &&
      (error as HttpError<unknown>)?.code === HttpStatusCode.NotFound
    ) {
      navigate(RoutePath.notFound());
    }
  }, [isError, error, navigate, automation]);

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!automation?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableAutomation(automation?.id, {
          onError: () => {
            toast.error(t('error_enable_automation'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_automation'));
          },
        });
      } else {
        disableAutomation(automation?.id, {
          onError: () => {
            toast.error(t('error_disable_automation'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_automation'));
          },
        });
      }
    },
    [automation, enableAutomation, disableAutomation],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <CustomPageContainer>
      <div>
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        {automation && (
          <p className="mt-3 text-xs">
            {`${automation?.organization} / ${automation?.company} / ${automation?.businessAreaName} / ${t('automations')} / `}
            <span className="font-black"> {automation?.name}</span>
          </p>
        )}
      </div>
      <span className="flex flex-col gap-5">
        <HeaderRow
          automation={automation}
          isLoading={isLoadingAutomation}
          handleStatus={handleStatus}
          status={status}
        />
        <SchedulingRow
          scheduling={automation?.scheduling}
          averageCases={automation?.averageCases}
          isLoading={isLoadingAutomation}
        />
        <FilesRow automation={automation} isLoading={isLoadingAutomation} />
        <AutomationPerformance
          company={automation?.company}
          businessArea={automation?.businessAreaName}
          organization={automation?.organization}
          processId={automation?.processId}
          automationId={automation?.id}
          tasksCount={automation?.tasks?.length}
        />
      </span>
    </CustomPageContainer>
  );
};
