import { useQuery } from '@tanstack/react-query';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowsQueryKey } from './types';
import { WorkflowModel } from '../domain';

export const useGetWorkflow = (id: string = '') =>
  useQuery<WorkflowModel>(
    [WorkflowsQueryKey.GET, id],
    () => workflowsRepo.getWorkflow(id),
    {
      enabled: !!id,
    },
  );
