import { useCallback, useEffect, useState } from 'react';

export type UseTablePaginationReturn = {
  pageIndex: number;
  nextDisabled: boolean;
  prevDisabled: boolean;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handlePageChange: (page: number) => void;
};

export const useTablePagination = (
  currentPage: number,
  totalPages: number,
  onPageChange?: (page: number) => void,
): UseTablePaginationReturn => {
  const [pageIndex, setPageIndex] = useState(currentPage - 1);

  const nextDisabled = pageIndex === totalPages - 1;
  const prevDisabled = pageIndex === 0;

  const handleNextPage = () => {
    if (nextDisabled) {
      return;
    }
    if (onPageChange) {
      onPageChange(pageIndex + 1);
      return;
    }
    setPageIndex((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (prevDisabled) {
      return;
    }
    if (onPageChange) {
      onPageChange(pageIndex - 1);
      return;
    }
    setPageIndex((prev) => prev - 1);
  };

  const handlePageChange = useCallback(
    (page: number) => {
      if (onPageChange) {
        onPageChange(page);
        return;
      }
      setPageIndex(page);
    },
    [onPageChange],
  );

  useEffect(() => {
    setPageIndex(currentPage);
  }, [currentPage]);

  return {
    pageIndex,
    nextDisabled,
    prevDisabled,
    handleNextPage,
    handlePrevPage,
    handlePageChange,
  };
};
