import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type EstimatedCostColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const estimatedCostColumnConfig = <T extends TableRow>({
  options,
}: EstimatedCostColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'estimatedCost',
  label: <Trans i18nKey="estimated_cost" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{value.toFixed(2)}</div>
  ),
  acessorKey: 'estimatedCost',
  sortable: true,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
