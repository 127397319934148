import { useCallback, useState } from 'react';
import { Tab } from '../Tab';

export interface TabItemProps {
  label: string;
  badgeNumber?: number;
}

interface TabsProps {
  tabs: Array<TabItemProps>;
  onTabChange?: (tabNumber: number) => void;
}

export const Tabs = ({ tabs, onTabChange }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = useCallback(
    (tabNumber: number) => () => {
      setActiveTab(tabNumber);
      onTabChange?.(tabNumber);
    },
    [onTabChange],
  );

  return (
    <div className="flex flex-row gap-1">
      {tabs.map((tab, index) => (
        <Tab
          key={tab.label}
          badgeNumber={tab.badgeNumber}
          active={activeTab === index}
          onClick={handleTabClick(index)}
        >
          {tab.label}
        </Tab>
      ))}
    </div>
  );
};
