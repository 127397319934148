import { ReactElement } from 'react';
import { Pie, PieChart as RechartPieChart } from 'recharts';
import { renderPieChartInnerText } from '../';

export interface PieData {
  id?: string;
  name: string;
  value: number;
  fill?: string;
  textColor?: string;
}

interface PieChartProps {
  data: PieData[];
  width?: number;
  height?: number;
  mainLabel?: string;
  subtitle?: string;
}

export const PieChart = ({
  data = [],
  width = 120,
  height = 120,
  mainLabel = '',
  subtitle = '',
}: PieChartProps): ReactElement => (
  <div>
    <RechartPieChart
      width={width}
      height={height}
      data={data}
      margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
    >
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius="100%"
        innerRadius="70%"
        paddingAngle={0}
        stroke="none"
      />
      {renderPieChartInnerText({
        mainLabel,
        subtitle,
        cx: '50%',
        cy: '50%',
      })}
    </RechartPieChart>
  </div>
);
