import { useQuery } from '@tanstack/react-query';
import { OrganizationModel } from '../domain';
import { OrganizationKeys } from './types';
import { organizationsRepo } from '@/constants/providers';

export const useGetOrganization = (orgId: string = '') =>
  useQuery<OrganizationModel>(
    [OrganizationKeys.GET, orgId],
    () => organizationsRepo.getOrganization(orgId),
    {
      enabled: !!orgId,
    },
  );
