import { useQuery } from '@tanstack/react-query';
import { AutomationQueryKey } from './types';
import { automationsRepo } from '@/constants/providers';

export const useGetAutomationPerformance = (automationId: string = '') =>
  useQuery(
    [AutomationQueryKey.AutomationPerformanceById, automationId],
    () => automationsRepo.getAutomationPerformance(automationId),
    {
      enabled: !!automationId,
    },
  );
