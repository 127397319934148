import { Button, FilterSearchBar, Table } from '@/components';
import { AttachUserToCompanyModal } from '@/modules/Companies/pages/CompaniesPage/components/AttachUserToCompanyModal';
import { CreateCompanyModal } from '@/modules/Companies/pages/CompaniesPage/components/CreateCompanyModal';
import {
  automationsCountColumnConfig,
  businessAreasCountColumnConfig,
  nameWithAvatarColumnConfig,
  processesCountColumnConfig,
  usersCountColumnConfig,
} from '@/utils';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContext } from '../TabContainer';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { TableRow } from '../Table/types';
import { useNavigate } from 'react-router-dom';

interface CompaniesTabProps {
  userId?: string;
  canCreate?: boolean;
  canAttachUser?: boolean;
  organizationId?: number;
  disableFields?: string[];
}

export const CompaniesTab = ({
  userId,
  canCreate,
  canAttachUser,
  organizationId,
  disableFields,
}: CompaniesTabProps): ReactElement => {
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const navigate = useNavigate();

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/companies/${row?.id}`;
    navigate(path);
  };

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={companies?.totalRows || 0}
          title={t('companies')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        <div className="flex items-center">
          {canCreate && (
            <Button onClick={handleModalState} variant="primary">
              {t('add_companies')}
            </Button>
          )}
          {canAttachUser && userId && (
            <AttachUserToCompanyModal userId={parseInt(userId)} />
          )}
        </div>
      </div>
      <CreateCompanyModal
        isOpen={isOpen}
        handleClose={handleModalState}
        organizationId={organizationId}
        disableFields={disableFields}
      />
      <Table
        onRowClick={routeChange}
        columns={[
          nameWithAvatarColumnConfig({}),
          usersCountColumnConfig(),
          businessAreasCountColumnConfig(),
          processesCountColumnConfig(),
          automationsCountColumnConfig({
            options: {
              className: 'w-25',
            },
          }),
        ]}
        rows={companies?.items ?? []}
        isLoading={isLoadingCompanies}
        noResultsLabel={t('no_companies_available')}
        withPagination
        totalItems={companies?.totalRows}
        totalPages={companies?.totalPages}
        currentPage={page}
        onSort={onSort}
        onPageChange={onPageChange}
      />
    </>
  );
};
