import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type TasksCountIconColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onClick?: (row?: T) => void;
};

export const tasksCountIconColumnConfig = <T extends TableRow>({
  options,
}: TasksCountIconColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'tasks',
  label: <Trans i18nKey="tasks" />,
  render: (value) => (
    <div className="flex cursor-pointer	 overflow-hidden text-ellipsis">
      {value}
    </div>
  ),
  acessorKey: 'tasksCount',
  sortable: true,
  ...options,
});
