import { ReactElement } from 'react';
import { SortProps, TableColumn, TableRow, TableVariant } from '../../types';
import { TableHeadCell } from '../TableHeadCell';

interface TableHeadProps<R extends TableRow> extends SortProps {
  variant: TableVariant;
  columns: Array<TableColumn<R>>;
  showDots?: boolean;
}

export const TableHead = <R extends TableRow>({
  columns,
  showDots,
  ...otherProps
}: TableHeadProps<R>): ReactElement => {
  return (
    <tr className="bg-inherit">
      {showDots && <th></th>}
      {columns.map((headCol) => (
        <TableHeadCell key={headCol.id} cell={headCol} {...otherProps} />
      ))}
    </tr>
  );
};
