import { TableColumn, TableRow } from '@/components/Table/types';
import {
  automationsCountColumnConfig,
  codeColumnConfig,
  nameWithAvatarColumnConfig,
  processesCountColumnConfig,
  statusColumnConfig,
  tasksCountColumnConfig,
  usersCountColumnConfig,
  workflowsColumnConfig,
} from '@/utils';
import { businessOrganizationNameColumnConfig } from '@/utils/tableColumns/business-organization-name-column-config';
import { BusinessAreaModel } from '../../domain';
import { businessCompanyNameColumnConfig } from '@/utils/tableColumns/business-company-name-column-config';

type BusinessAreasColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getBusinessAreasColumns = ({
  onClick,
}: BusinessAreasColumnsConfig): Array<TableColumn<BusinessAreaModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    codeColumnConfig(),
    statusColumnConfig(),
    businessOrganizationNameColumnConfig(),
    businessCompanyNameColumnConfig(),
    usersCountColumnConfig(),
    processesCountColumnConfig(),
    workflowsColumnConfig(),
    tasksCountColumnConfig(),
    automationsCountColumnConfig({
      options: {
        className: 'w-25',
      },
    }),
  ];
};
export const getBusinessAreaColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    code: 'Code',
    status: 'Status',
    organizationName: 'Organization',
    CompanyName: 'CompanyName',
    businessAreasCount: 'BusinessAreasCount',
    processesCount: 'ProcessesCount',
    tasksCount: 'TasksCount',
    workflowsCount: 'WorkflowsCount',
    automationsCount: 'AutomationsCount',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};
