import { TableColumn, TableRow } from '@/components/Table/types';
import {
  automationsCountColumnConfig,
  businessAreasCountColumnConfig,
  companiesCountColumnConfig,
  nameWithAvatarColumnConfig,
  statusColumnConfig,
  tasksCountColumnConfig,
  usersCountColumnConfig,
} from '@/utils';
import { OrganizationModel } from '../../domain';

type OrganizationsColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getOrganizationsColumns = ({
  onClick,
}: OrganizationsColumnsConfig): Array<TableColumn<OrganizationModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    statusColumnConfig(),
    usersCountColumnConfig(),
    companiesCountColumnConfig(),
    businessAreasCountColumnConfig(),
    tasksCountColumnConfig(),
    automationsCountColumnConfig({
      options: {
        className: 'w-25',
      },
    }),
  ];
};

export const getOrganizationsColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    description: 'Description',
    status: 'OrganizationStatus',
    usersCount: 'UsersCount',
    companiesCount: 'CompaniesCount',
    businessAreasCount: 'BusinessAreasCount',
    processesCount: 'ProcessesCount',
    tasksCount: 'TasksCount',
    workflowsCount: 'WorkflowsCount',
    automationsCount: 'AutomationsCount',
    timeSaved: 'TimeSaved',
    investmentReturn: 'InvestmentReturn',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};
