import { useQuery } from '@tanstack/react-query';
import { businessAreasRepo } from '@/constants/providers';
import { BusinessAreaModel } from '../domain';
import { BusinessAreasQueryKey } from './types';

export const useGetBusinessArea = (businessAreaId: string = '') =>
  useQuery<BusinessAreaModel>(
    [BusinessAreasQueryKey.GET, businessAreaId],
    () => businessAreasRepo.getBusinessArea(businessAreaId),
    {
      enabled: !!businessAreaId,
    },
  );
