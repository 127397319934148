import { useMutation } from '@tanstack/react-query';
import { OrganizationModel } from '../domain';
import { OrganizationJson } from '../types';
import { organizationsRepo } from '@/constants/providers';

export const useCreateOrganization = () =>
  useMutation<
    OrganizationModel,
    unknown,
    OrganizationJson & { status: number }
  >(organizationsRepo.createOrganization);
