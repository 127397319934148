import { tasksRepo } from '@/constants/providers';
import { TaskModel } from '@/modules/Tasks/domain';
import { useQuery } from '@tanstack/react-query';
import { TasksQueryKey } from './types';

export const useGetAllTasksWithoutWorkflow = (businessAreaId: number) =>
  useQuery<TaskModel[]>(
    [TasksQueryKey.GET_TASKS_WITHOUT_WORKFLOW, { businessAreaId }],
    () => tasksRepo.getAllTasksWithoutWorkflow(businessAreaId),
    {
      enabled: !!businessAreaId,
    },
  );
