import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type UserCountColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const usersCountColumnConfig = <T extends TableRow>({
  options,
}: UserCountColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'users',
  acessorKey: 'usersCount',
  label: <Trans i18nKey="users" />,
  sortable: true,
  ...options,
});
