import { twMerge } from 'tailwind-merge';
import { ReactElement, useCallback, useEffect, useRef } from 'react';

export interface ModalProps {
  isOpen: boolean;
  className: string;
  children: ReactElement;
  title: string;
  handleClose: () => void;
}

export const Modal = ({
  isOpen,
  children,
  title,
  className = '',
  handleClose,
}: ModalProps): ReactElement => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutSide = useCallback(
    (e: MouseEvent) => {
      if (!modalRef?.current?.contains(e.target as Node) && isOpen) {
        handleClose();
      }
    },
    [modalRef, isOpen, handleClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, [isOpen, handleClickOutSide]);

  return (
    <>
      <div
        className={twMerge(
          'absolute bottom-0 left-0 right-0 top-0 z-10 h-full w-full bg-black opacity-60',
          !isOpen && 'invisible',
        )}
      />
      <div
        className={twMerge(
          'absolute bottom-0 left-20 right-0 top-0 z-20 m-auto h-fit w-fit bg-white p-5',
          !isOpen && 'invisible',
          className,
        )}
        ref={modalRef}
      >
        <div>
          <div className="text-xl font-bold">{title}</div>
        </div>
        {children}
      </div>
    </>
  );
};
