import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { ProcessModel } from '@/modules/Processes/domain';
import { ReactElement } from 'react';

interface ProcessPerformanceCardsProps {
  isLoading: boolean;
  data?: ProcessModel;
}

export const ProcessPerformanceCards = ({
  isLoading,
  data,
}: ProcessPerformanceCardsProps): ReactElement => {
  return (
    <PerformanceCardsContainer
      predictedManualTime={data?.predictedManualTime || 0}
      predictedAverageManualTime={data?.predictedAverageManualTime || 0}
      automationRunTime={data?.automationRunTime || 0}
      averageAutomationRunTime={data?.averageAutomationRunTime || 0}
      totalInvestment={data?.totalInvestment || 0}
      totalReturn={data?.totalReturn || 0}
      averageCost={data?.averageCost || 0}
      averageManualCost={data?.averageManualCost || 0}
      isLoading={isLoading}
    />
  );
};
