import { useQuery } from '@tanstack/react-query';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowsQueryKey } from './types';
import { WorkflowModel } from '@/modules/Workflows/domain';

export const useGetAllWorkflowsByProcess = (processId: number) =>
  useQuery<WorkflowModel[]>(
    [WorkflowsQueryKey.GET_ALL_WORKFLOWS_BY_PROCESS, { processId }],
    () => workflowsRepo.getWorkflowsByProcessId(processId),
    {
      enabled: !!processId,
    },
  );

export const useGetAllWorkflowsByProcessIdAutomation = (processId: number) =>
  useQuery<WorkflowModel[]>(
    [WorkflowsQueryKey.GET_ALL_WORKFLOWS_BY_PROCESS_AUTOMATION, { processId }],
    () => workflowsRepo.getWorkflowsByProcessIdAutomations(processId),
    {
      enabled: !!processId,
    },
  );
