import { PieChartCard } from '@/components/PieChartCard';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SuccessRate,
  TimeDistribution,
} from '@/core/domain/global-performance-model';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { useGetChartColorsAndLabel } from '@/hooks';

interface WorkflowPerformanceChartsProps {
  workflowPerformance?: WorkflowModel;
}

export const WorkflowPerformanceCharts = ({
  workflowPerformance,
}: WorkflowPerformanceChartsProps): ReactElement => {
  const { t } = useTranslation();
  const { rateChart, workflowTimeDistributionChart } =
    useGetChartColorsAndLabel();

  const lastRunSuccessRateData = useMemo(
    () =>
      rateChart.map((chartData) => ({
        ...chartData,
        value:
          workflowPerformance?.lastRunSuccessRate[
            chartData.id as keyof SuccessRate
          ] ?? 0,
      })),
    [workflowPerformance, rateChart],
  );

  const overallSuccessRateData = useMemo(
    () =>
      rateChart.map((chartData) => ({
        ...chartData,
        value:
          workflowPerformance?.overallSuccessRate[
            chartData.id as keyof SuccessRate
          ] ?? 0,
      })),
    [workflowPerformance, rateChart],
  );

  const workflowTimeDistributionData = useMemo(
    () =>
      workflowTimeDistributionChart.map((chartData) => ({
        ...chartData,
        value:
          workflowPerformance?.workflowTimeDistribution[
            chartData.id as keyof TimeDistribution
          ] ?? 0,
      })),
    [workflowPerformance, workflowTimeDistributionChart],
  );

  return (
    <div className="flex flex-col gap-0">
      <div className="flex flex-wrap gap-2">
        <PieChartCard
          data={workflowTimeDistributionData}
          chartTitle={t('workflow_time_distribution')}
          valueType="percentage"
          subtitle={t('total')}
          convert
        />
        <PieChartCard
          data={lastRunSuccessRateData}
          chartTitle={t('last_run_success_rate')}
          valueType="percentage"
          subtitle={t('cases')}
        />
        <PieChartCard
          data={overallSuccessRateData}
          chartTitle={t('overall_success_rate')}
          valueType="percentage"
          subtitle={t('cases')}
        />
      </div>
    </div>
  );
};
