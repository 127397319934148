import { Card } from '@/components/Card';
import { FileIcon, TrashIcon } from '@/components/Icons';
import { queryClient } from '@/constants';
import { FileModel } from '@/modules/Tasks/domain';
import {
  TasksQueryKey,
  useAddDocument,
  useDeleteDocument,
} from '@/modules/Tasks/queries';
import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { toast } from 'react-toastify';

interface FileCardProps {
  title: string;
  files?: Array<FileModel>;
  isLoading?: boolean;
  id?: number;
}

export const FileCard = ({ title, files, isLoading, id }: FileCardProps) => {
  const { mutate: addDocument } = useAddDocument();
  const { mutate: deleteDocument } = useDeleteDocument();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      handleAddDocument(selectedFile);
    }
  };

  const handleAddDocument = async (file: File) => {
    const formData = new FormData();
    formData.append('id', (id ?? '').toString());
    formData.append('file', file);

    addDocument(formData, {
      onError: () => {
        toast.error(t('error_adding_document'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([TasksQueryKey.GET]);
        toast.success(t('success_adding_document'));
      },
    });
  };

  const handleDeleteDocument = async (fileId: string, taskFileId: string) => {
    const convertedData = {
      fileId: Number(fileId),
      taskFileId: Number(taskFileId),
    };
    deleteDocument(convertedData, {
      onError: () => {
        toast.error(t('error_deleting_document'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([TasksQueryKey.GET]);
        toast.success(t('success_deleting_document'));
      },
    });
  };

  return (
    <Card
      title={title}
      footer={
        <div className="pointer flex cursor-pointer flex-row gap-1 text-shinyBlue">
          +
          <label
            htmlFor="file-upload"
            className="cursor-pointer text-xs font-black underline"
          >
            {t('add_document')}
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
      }
      isLoading={isLoading}
    >
      <div className="flex flex-col gap-2">
        {files?.map((file) => (
          <span key={file.id} className="items flex flex-row gap-2">
            <FileIcon />
            <a
              rel="noreferrer"
              href={file.url}
              target="_blank"
              download={file.name}
              className="font-black underline"
            >
              {file.name}
            </a>
            <TrashIcon
              className="cursor-pointer text-rinseGray"
              onClick={() => handleDeleteDocument(file.id, file.taskFileId)}
            />
          </span>
        ))}
      </div>
    </Card>
  );
};
