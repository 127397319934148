import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type BusinessAreasCountColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const businessAreasCountColumnConfig = <T extends TableRow>({
  options,
}: BusinessAreasCountColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'business-areas',
  label: <Trans i18nKey="business_areas" />,
  acessorKey: 'businessAreasCount',
  sortable: true,
  ...options,
});
