import { Button, SwitchStatus, UserAvatar } from '@/components';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { CompanyModel } from '@/modules/Companies/domain';
import { ChangeEvent, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditCompanyModal } from '../../../CompaniesPage/components/EditCompanyModal';
import { useImportRatesFile } from '@/modules/Companies/queries';
import { toast } from 'react-toastify';

interface HeaderRowProps {
  company?: CompanyModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const HeaderRow = ({
  company,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);
  const { mutate: importFile } = useImportRatesFile();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const selectedFile = e.target.files[0];
      handleImportFile(selectedFile);
    }
  };

  const handleImportFile = async (file: File) => {
    const formData = new FormData();
    formData.append('companyId', (company?.id ?? '').toString());
    formData.append('Rates', file);

    importFile(formData, {
      onError: () => {
        toast.error(t('error_importing_file'));
      },
      onSuccess: () => {
        toast.success(t('success_importing_file'));
      },
    });
  };

  return (
    <Container isLoading={!company}>
      <div className="flex justify-between">
        <div className="flex items-center gap-5">
          <UserAvatar name={company?.name} />
          <h2>{company?.name}</h2>
        </div>
        <div className="flex h-10 flex-row gap-3">
          <label
            htmlFor="file-upload"
            className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
          >
            <span className="cursor-pointer text-sm font-bold underline">
              {t('import_rates')}
            </span>
          </label>
          <input
            id="file-upload"
            type="file"
            accept=".xlsx,.xlsm,.xlsb,.xltx"
            onChange={handleFileChange}
            className="hidden"
          />
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span
              className={`p-0 ${status ? 'text-americanGreen' : 'brightGray'}`}
            >
              {status ? 'Active' : 'Inactive'}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_company')}
          </Button>
          <EditCompanyModal
            isOpen={isOpen}
            id={company?.id}
            handleClose={handleModalState}
          />
        </div>
      </div>
      <span className="flex flex-row gap-2.5">
        <Card title={t('nif_number')}>{company?.nif}</Card>
        <Card title={t('headquarters')}>
          {company?.address.line1 +
            ' ' +
            company?.address.line2 +
            company?.address.postalCode +
            ' ' +
            company?.address.city +
            ', ' +
            company?.address.country}
        </Card>{' '}
      </span>
    </Container>
  );
};
