import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type AutomatedColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const automatedColumnConfig = <T extends TableRow>({
  options,
}: AutomatedColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'automationId',
  acessorKey: 'automationId',
  label: <Trans i18nKey="automated" />,
  render: (value) => <Trans i18nKey={value ? 'yes' : 'no'} />,
  sortable: true,
  ...options,
});
