import { SortDirection } from '@/components/Table/types';
import { businessAreasRepo } from '@/constants/providers';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { BusinessAreasQueryKey } from './types';

export const useGetAllBusinessAreasByCompany = (companyId: number) =>
  useQuery<BusinessAreaModel[]>(
    [BusinessAreasQueryKey.GET_BUSINESS_AREAS_BY_COMPANY, { companyId }],
    () => businessAreasRepo.getAllBusinessAreasByCompany(companyId),
    {
      enabled: !!companyId,
    },
  );

export const useGetBusinessAreasByCompany = (
  companyId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<BusinessAreaModel[]>,
    any,
    DataWithPagination<BusinessAreaModel[]>
  >,
) =>
  useQuery<DataWithPagination<BusinessAreaModel[]>>(
    [
      BusinessAreasQueryKey.GET_BUSINESS_AREAS_BY_COMPANY,
      { companyId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      businessAreasRepo.getBusinessAreasByCompany(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
