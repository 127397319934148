import {
  Container,
  FilterAndPaginationContext,
  TabItemProps,
  Tabs,
} from '@/components';
import { ReactNode, useCallback, useContext, useState } from 'react';

type OrganizationTabsProps = {
  tabs: Array<TabItemProps>;
  tabList: Array<{ tab: ReactNode }>;
};

export const TabsWrapper = ({ tabs, tabList }: OrganizationTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { reset } = useContext(FilterAndPaginationContext);

  const onTabChange = useCallback(
    (tabIndex: number) => {
      reset();
      setSelectedTab(tabIndex);
    },
    [reset],
  );

  const elementToRender = tabList[selectedTab];

  return (
    <>
      <Container className="mb-2">
        <Tabs tabs={tabs} onTabChange={onTabChange} />
      </Container>
      {elementToRender?.tab}
    </>
  );
};
