import { ChangeEvent, ReactElement } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export interface TextAreaProps {
  className?: string;
  placeHolder?: string;
  isDisabled?: boolean;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  register?: UseFormRegister<any>;
}

export const TextArea = ({
  className,
  placeHolder,
  value,
  onChange,
  isDisabled,
  register,
  name,
}: TextAreaProps): ReactElement => (
  <textarea
    disabled={isDisabled}
    onChange={onChange}
    className={twMerge(
      'h-10 w-36 resize-none rounded-md bg-white p-2 text-md',
      className,
    )}
    placeholder={placeHolder}
    value={value}
    {...(register && { ...register(name || '') })}
  />
);
