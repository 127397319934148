import { PropsWithChildren } from 'react';

interface InputWithErrorProps {
  errorText?: string;
  hasError: boolean;
}

const InputWithError = ({
  children,
  errorText,
  hasError,
}: PropsWithChildren<InputWithErrorProps>) => {
  return (
    <div>
      {children}
      {hasError && (
        <span className="text-xs text-salmonOrange">{errorText}</span>
      )}
    </div>
  );
};

export default InputWithError;
