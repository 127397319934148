import { Button, FieldInfo, MultiSelectDropdownField } from '@/components';
import { queryClient } from '@/constants';
import { ProcessesQueryKey } from '@/modules/Processes/queries';
import {
  TasksQueryKey,
  useGetAllTasksWithoutWorkflow,
} from '@/modules/Tasks/queries';
import { AddTaskSchema, AddTaskSchemaType } from '@/modules/Tasks/types';
import {
  useAssociateTaskToWorkflow,
  WorkflowsQueryKey,
} from '@/modules/Workflows/queries';
import { AssociateTaskToWorkflow } from '@/modules/Workflows/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface AddTaskFormProps {
  businessAreaId: number;
  workflowId: number;
  handleClose: () => void;
}

export const AddTaskForm = ({
  businessAreaId,
  handleClose,
  workflowId,
}: AddTaskFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    control,
  } = useForm<AddTaskSchemaType>({
    resolver: zodResolver(AddTaskSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { mutate, isLoading } = useAssociateTaskToWorkflow();
  const { data: tasksOptions } = useGetAllTasksWithoutWorkflow(businessAreaId);

  const onSubmit: SubmitHandler<AddTaskSchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }
    const convertedData: AssociateTaskToWorkflow = {
      workflowId: workflowId,
      taskIds: (data.tasks as string[]).map((task) => Number(task)),
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_associate_tasks_to_workflow'));
      },
      onSuccess: () => {
        toast.success(t('success_associate_tasks_to_workflow'));
        handleClose();
        queryClient.invalidateQueries([WorkflowsQueryKey.GET]);
        queryClient.invalidateQueries([
          TasksQueryKey.GET_TASKS_WITHOUT_WORKFLOW,
        ]);
        queryClient.invalidateQueries([ProcessesQueryKey.GET]);
      },
    });
  };

  const dropdownTasksOptions = useMemo(() => {
    return getDropdownArray(tasksOptions);
  }, [tasksOptions]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2 pb-20">
        <FieldInfo
          title={t('existing_tasks')}
          hasError={!!errors.tasks?.message}
        >
          <MultiSelectDropdownField<AddTaskSchemaType>
            name="tasks"
            control={control}
            dropdownOptions={dropdownTasksOptions}
            placeholder={t('select_task')}
          />
        </FieldInfo>
      </div>
      <div className="mt-4 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={handleClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button isDisabled={isLoading} buttonType="submit" className="h-9 w-24">
          {t('confirm')}
        </Button>
      </div>
    </form>
  );
};
