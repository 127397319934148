import { PropsWithChildren } from 'react';

interface InputWithTitleProps {
  title: string;
}

const InputWithTitle = ({
  title,
  children,
}: PropsWithChildren<InputWithTitleProps>) => {
  return (
    <div className="mt-4 flex w-full flex-col gap-2">
      <div className="text-sm font-bold capitalize">{title}</div>
      {children}
    </div>
  );
};

export default InputWithTitle;
