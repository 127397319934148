import { useState } from 'react';

export const useSearchInputHandler = () => {
  const [searchInputValue, setSearchInputValue] = useState('');

  const handleInputSearchChange = (value: string) => {
    setSearchInputValue(value);
  };

  return {
    searchInputValue,
    handleInputSearchChange,
  };
};
