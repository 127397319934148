import { ComponentProps, FC, FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

export type SideNavItemProps = {
  to: string;
  label: string;
  icon: FunctionComponent<ComponentProps<'svg'> & { title?: string }>;
};

export const SideNavItem: FC<SideNavItemProps> = ({
  icon: Icon,
  to,
  label,
}) => (
  <li className="cursor-pointer">
    <NavLink
      to={to}
      className="group relative z-10 flex h-16 w-full items-center justify-center rounded-r-md"
    >
      {({ isActive }) => (
        <div className="flex h-full items-center rounded-r-md group-hover:absolute group-hover:left-0 group-hover:top-0 group-hover:bg-primaryBlue group-hover:shadow-lg">
          <span className="flex w-20 items-center justify-center ">
            <Icon className={isActive ? 'text-salmonOrange' : 'text-white'} />
          </span>
          <span
            className={twJoin(
              'text-base hidden whitespace-nowrap rounded-md pr-3 font-bold group-hover:inline-block',
              isActive ? 'text-salmonOrange' : 'text-white',
            )}
          >
            {label}
          </span>
        </div>
      )}
    </NavLink>
  </li>
);
