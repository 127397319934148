import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetUsers } from '../../queries';
import { getUsersColumnsNamedMapped, getUsersColumns } from './utils';
import { CreateUserModal } from './components/CreateUserModal';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';

export const UsersPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();

  const allUsersOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_users'));
      },
    }),
    [t],
  );

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const { data: users, isLoading: isLoadingUsers } = useGetUsers(
    page + 1,
    searchTerm,
    getUsersColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
    allUsersOptions,
  );

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleOnNameClick = useCallback(
    (row: TableRow) => {
      const path = `${row.id}`;
      navigate(path);
    },
    [navigate],
  );

  const onEditRow = useCallback((row: TableRow) => {
    // TODO implement edit row task: 86937npbq
    toast.error(`TODO Implement Edit row: ${row.id ?? ''}`);
  }, []);

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('users')}>
        <Button onClick={handleModalState} variant="primary">
          {t('add_user')}
        </Button>
      </CustomPageContainer.Header>
      <CreateUserModal handleClose={handleModalState} isOpen={isOpen} />
      <FilterSearchBar
        total={users?.totalRows || 0}
        title={t('users')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />
      <Table
        columns={getUsersColumns({
          onNameClick: handleOnNameClick,
          onEditClick: onEditRow,
        })}
        rows={users?.items ?? []}
        isLoading={isLoadingUsers}
        noResultsLabel={t('no_users_available')}
        withPagination
        totalItems={users?.totalRows}
        totalPages={users?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </CustomPageContainer>
  );
};
