import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type DividerColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  key?: string;
};

export const dividerColumnConfig = <T extends TableRow>({
  options,
  key = 'divider',
}: DividerColumnConfig<T> = {}): TableColumn<T> => ({
  id: key,
  label: <Trans i18nKey="divider" />,
  render: () => <div className="overflow-hidden text-ellipsis">|</div>,
  acessorKey: key,
  ...options,
});
