import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { SchedulingModel } from '@/modules/Automations/domain';
import { roundToDecimal } from '@/utils/roundToDecimals';
import { t } from 'i18next';

interface SchedulingRowProps {
  scheduling?: SchedulingModel;
  averageCases?: number;
  isLoading?: boolean;
}

export const SchedulingRow = ({
  scheduling,
  averageCases,
  isLoading,
}: SchedulingRowProps) => {
  return (
    <Container title={t('scheduling')}>
      <span className="flex flex-row gap-2.5">
        <Card title={t('schedule')} isLoading={isLoading}>
          {scheduling?.schedule}
        </Card>
        <Card title={t('next_run')} isLoading={isLoading}>
          {scheduling?.nextRun?.toLocaleString() ?? ''}
        </Card>
        <Card title={t('last_run')} isLoading={isLoading}>
          {scheduling?.lastRun?.toLocaleString() ?? ''}
        </Card>
        <Card title={t('average_cases_per_run')} isLoading={isLoading}>
          {roundToDecimal(averageCases || 0)}
        </Card>
      </span>
    </Container>
  );
};
