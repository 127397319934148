import { z } from 'zod';

export const UserRateSchema = z.object({
  rate: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  startDate: z.date({ required_error: 'error_field_required' }),
  endDate: z.date().optional(),
  jiraAccountId: z.string().optional(),
});

export type FormUserRateSchemaType = z.infer<typeof UserRateSchema>;

export interface UserRateSchemaType extends FormUserRateSchemaType {
  id?: number;
}
