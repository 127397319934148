import { PropsWithChildren, ReactElement } from 'react';
import { SideNavigation } from '../';

export const LoggedLayout = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <SideNavigation />
      <main className="flex w-full flex-grow flex-col overflow-auto scroll-smooth">
        {children}
      </main>
    </div>
  );
};
