import { z } from 'zod';

export const WorkflowSchema = z.object({
  organizationId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  companyId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  businessAreaId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  processId: z.string().optional(),
  tasks: z.any().optional(),
  workflowName: z.string().min(1, { message: 'error_field_required' }),
  description: z.string().min(1, { message: 'error_field_required' }),
});

export type FormWorkflowSchemaType = z.infer<typeof WorkflowSchema>;

export interface WorkflowSchemaType extends FormWorkflowSchemaType {
  id?: number;
}
