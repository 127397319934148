import { ChangeEvent } from 'react';
import { twMerge } from 'tailwind-merge';

export const Checkbox = ({
  checked,
  size,
  disabled = false,
  onChange,
  onClick,
}: {
  checked: boolean;
  size?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      className={twMerge('size-5 cursor-pointer', size)}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      readOnly={!onChange}
    />
  );
};
