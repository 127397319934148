import { TasksQueryKey } from '@/modules/Tasks/queries';
import {
  WorkflowsQueryKey,
  useGetWorkflow,
  useUpdateWorkflow,
} from '@/modules/Workflows/queries';
import { WorkflowSchemaType } from '@/modules/Workflows/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { WorkflowModal } from '../WorkflowModal';

interface EditWorkflowModalProps {
  isOpen: boolean;
  id?: number;
  handleClose: () => void;
}

export const EditWorkflowModal = ({
  isOpen,
  id,
  handleClose,
}: EditWorkflowModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateWorkflow();
  const { data: workflow, isLoading: isLoadingGet } = useGetWorkflow(
    id?.toString(),
  );

  const handleConfirm = (data: WorkflowSchemaType) => {
    const convertedData = {
      id: data.id ?? workflow?.id ?? 0,
      name: data.workflowName ?? workflow?.name,
      description: data.description ?? workflow?.description,
      businessAreaId: Number(data.businessAreaId) ?? workflow?.businessAreaId,
      processId: workflow?.processId ? Number(data.processId) : undefined,
      status: true,
      workflowStageId: 1,
      code: workflow?.code,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_workflow'));
      },
      onSuccess: async () => {
        handleClose();
        toast.success(t('success_update_workflow'));
        await queryClient.invalidateQueries([WorkflowsQueryKey.GET]);
        await queryClient.invalidateQueries({
          queryKey: [TasksQueryKey.GET_TASKS_WITHOUT_WORKFLOW],
        });
      },
    });
  };

  return (
    <WorkflowModal
      handleClose={handleClose}
      type="edit"
      handleOnSubmit={handleConfirm}
      title={t('edit_workflow')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingGet}
      workflowName={workflow?.name}
      description={workflow?.description}
      businessAreaId={workflow?.businessAreaId}
      processId={workflow?.processId}
      organizationId={workflow?.organizationId}
      companyId={workflow?.companyId}
    />
  );
};
