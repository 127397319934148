import { useMutation } from '@tanstack/react-query';
import { HourlyRateJson } from '../types';
import { usersRepo } from '@/constants/providers';
import { ApiResponse } from '@/shared/types';

export const useCreateUserRate = () =>
  useMutation<
    ApiResponse<{ data: { userId: number } }>,
    unknown,
    HourlyRateJson
  >(usersRepo.createUserRate);
