import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CreateBusinessAreaModal } from './components/CreateBusinessAreaModal/CreateBusinessAreaModal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetBusinessAreas } from '../../queries';
import { TableRow, TableSort } from '@/components/Table/types';
import {
  getBusinessAreasColumns,
  getBusinessAreaColumnsNamedMapped,
} from './utils';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';

export const BusinessAreasPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const getAllBusinessAreasOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_business_areas'));
      },
    }),
    [t],
  );

  const { data: businessAreas, isLoading: isLoadingBusinessAreas } =
    useGetBusinessAreas(
      page + 1,
      searchTerm,
      getBusinessAreaColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
      tableSort?.[0]?.direction ?? 'ascending',
      getAllBusinessAreasOptions,
    );

  const routeChange = (row?: TableRow) => {
    const path = `${row?.id}`;
    navigate(path);
  };

  const onCreateBusinessArea = () => {
    handleSearchChange('');
    handleInputSearchChange('');
  };
  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('business_areas')}>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('add_business_area')}
        </Button>
      </CustomPageContainer.Header>
      <CreateBusinessAreaModal
        onSubmit={onCreateBusinessArea}
        handleClose={handleModalState}
        isOpen={isOpen}
      />
      <FilterSearchBar
        total={businessAreas?.totalRows || 0}
        title={t('business_areas')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />
      {
        <Table
          columns={getBusinessAreasColumns({ onClick: routeChange })}
          rows={businessAreas?.items ?? []}
          isLoading={isLoadingBusinessAreas}
          noResultsLabel={t('no_business_areas_available')}
          withPagination
          totalItems={businessAreas?.totalRows}
          totalPages={businessAreas?.totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          onSort={onSort}
        />
      }
    </CustomPageContainer>
  );
};
