import { twMerge } from 'tailwind-merge';
import { CSSProperties, ReactElement, ReactNode } from 'react';

export interface TagProps {
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  size?: 'small' | 'medium' | 'large';
}

export const Tag = ({
  className,
  children,
  style,
  size = 'small',
}: TagProps): ReactElement => (
  <div
    className={twMerge(
      'bg-primary-500 inline-flex h-5 items-center justify-center gap-2 whitespace-nowrap rounded-xl px-2 py-1 font-medium text-white',
      size === 'small' && 'text-xs',
      size === 'medium' && 'h-6 text-sm',
      size === 'large' && 'h-8 rounded-3xl text-md',
      className,
    )}
    style={style}
  >
    {children}
  </div>
);
