import { useQuery } from '@tanstack/react-query';
import { UsersKeys } from './types';
import { QueryOptions } from '@/modules/types';
import { usersRepo } from '@/constants/providers';
import { UserModel } from '../domain';

export const useGetUser = (
  userId: string = '',
  options?: QueryOptions<UserModel>,
) =>
  useQuery<UserModel>(
    [UsersKeys.GET, userId],
    () => usersRepo.getUser(userId),
    {
      ...options,
    },
  );
