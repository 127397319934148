import { ReactElement, ReactNode, useCallback, useState } from 'react';
import { Button, SideNavItem, SideNavItemProps } from '../';
import { useKeycloak } from '@react-keycloak/web';
import { twJoin } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { ChangePasswordModal } from './components/ChangePasswordModal';

interface SideNavProps {
  header: ReactNode;
  items: SideNavItemProps[];
  footer: ReactNode;
}

export const SideNav = ({
  items,
  footer,
  header,
}: SideNavProps): ReactElement => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div>
      <div className="flex h-screen w-20 flex-col justify-between bg-primaryBlue text-white">
        <div className="flex flex-1 flex-col">
          <div className="group relative z-10 flex h-25 w-full items-center justify-center rounded-r-md border-b-1 ">
            <div className="flex h-full items-center rounded-r-md group-hover:absolute group-hover:left-0 group-hover:top-0 group-hover:bg-primaryBlue group-hover:shadow-lg">
              <span className="flex w-20 items-center justify-center ">
                {header}
              </span>
              <span
                className={twJoin(
                  'text-base hidden whitespace-nowrap rounded-md pr-3 font-bold group-hover:inline-block',
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="mr-4 flex flex-col">
                    <span className="font-bold">
                      {keycloak.tokenParsed?.given_name}{' '}
                      {keycloak.tokenParsed?.family_name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {keycloak.tokenParsed?.email}
                    </span>
                  </div>
                  <Button
                    className="h-5 border border-blue-500 bg-transparent p-3 text-blue-500"
                    onClick={handleModalState}
                  >
                    {t('change_password')}
                  </Button>
                </div>
              </span>
            </div>
          </div>
          <ul className="mt-4 flex flex-1 flex-col bg-primaryBlue">
            {items.map((item: SideNavItemProps) => (
              <SideNavItem
                key={item.label}
                to={item.to}
                label={item.label}
                icon={item.icon}
              />
            ))}
          </ul>
        </div>
        <div className="flex items-center justify-center border-t border-t-plumBlue bg-primaryBlue">
          {footer}
        </div>
      </div>
      <ChangePasswordModal isOpen={isOpen} handleClose={handleModalState} />
    </div>
  );
};
