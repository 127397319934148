import { z } from 'zod';

export const AddWorkflowSchema = z.object({
  workflows: z.any().optional(),
});

export type FormAddWorkflowSchemaType = z.infer<typeof AddWorkflowSchema>;

export interface AddWorkflowSchemaType extends FormAddWorkflowSchemaType {
  id?: number;
}
