import { CardValueType } from '@/utils';
import { useTranslation } from 'react-i18next';

export const useGetTagLabel = (value: number, type: CardValueType = 'time') => {
  const { t } = useTranslation();
  if (value < 0) {
    return type === 'time' ? t('deficit') : t('remaining');
  }

  return type === 'time' ? t('saved') : t('benefit');
};
