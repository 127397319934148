import { EditPencil } from '@/components';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type ActionsEditRowColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onClick?: (row: T) => void;
  canEdit?: (row: T) => boolean;
};

export const actionsEditRowColumnConfig = <T extends TableRow>({
  options,
  onClick,
  canEdit = () => true,
}: ActionsEditRowColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'actions',
  label: <Trans i18nKey="actions" />,
  sortable: true,
  render: (_, row) => (
    <div
      className="flex items-center"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(row);
      }}
    >
      {canEdit && canEdit(row) && <EditPencil className="cursor-pointer" />}
    </div>
  ),
  ...options,
});
