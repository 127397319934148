import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type CompaniesCountColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const companiesCountColumnConfig = <T extends TableRow>({
  options,
}: CompaniesCountColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'companies',
  label: <Trans i18nKey="companies" />,
  acessorKey: 'companiesCount',
  sortable: true,
  ...options,
});
