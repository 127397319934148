import { toast } from 'react-toastify';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationModal } from '../OrganizationModal';
import { useCreateOrganization } from '@/modules/Organizations/queries/create-organization';
import { useQueryClient } from '@tanstack/react-query';
import { OrganizationKeys } from '@/modules/Organizations/queries/types';
import { OrganizationSchemaType } from '@/modules/Organizations/types';

interface CreateOrganizationModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateOrganizationModal = ({
  isOpen,
  handleClose,
}: CreateOrganizationModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateOrganization();

  const handleConfirm = (data: OrganizationSchemaType) => {
    mutate(
      {
        description: data.organizationDescription,
        name: data.organizationName,
        status: 1,
      },
      {
        onError: () => {
          toast.error(t('error_creating_organization'));
        },
        onSuccess: () => {
          queryClient.invalidateQueries([OrganizationKeys.GET_ALL]);
          handleClose();
          toast.success(t('success_creating_organization'));
        },
      },
    );
  };

  return (
    <OrganizationModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_organization')}
      isOpen={isOpen}
      isLoading={isLoading}
    />
  );
};
