import { TableColumn, TableRow } from '@/components/Table/types';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  periodColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { TaskModel } from '../../domain';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { taskWorkflowNameColumnConfig } from '@/utils/tableColumns/task-workflow-name-column-config';

type TasksColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getTasksColumns = ({
  onClick,
}: TasksColumnsConfig): Array<TableColumn<TaskModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    codeColumnConfig(),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    taskWorkflowNameColumnConfig(),
    statusColumnConfig(),
    usersCountColumnConfig(),
    dividerColumnConfig({ key: 'divider-1' }),
    periodColumnConfig(),
    workloadColumnConfig(),
    estimatedCostColumnConfig(),
    dividerColumnConfig({ key: 'divider-2' }),
    automatedColumnConfig(),
  ];
};
export const getTasksColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    code: 'Code',
    BusinessAreaName: 'BusinessAreaName',
    status: 'Status',
    processesCount: 'UsersCount',
    period: 'Period',
    workload: 'Workload',
    estimatedCost: 'EstimatedCost',
    automated: 'Automated',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};
