import { SortDirection } from '@/components/Table/types';
import { tasksRepo } from '@/constants/providers';
import { TaskModel } from '@/modules/Tasks/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TasksQueryKey } from './types';

export const useGetTasksByOrganization = (
  organizationId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<TaskModel[]>,
    any,
    DataWithPagination<TaskModel[]>
  >,
) =>
  useQuery<DataWithPagination<TaskModel[]>>(
    [
      TasksQueryKey.GET_TASKS_BY_ORGANIZATION,
      { organizationId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      tasksRepo.getTasksByOrganizationId(
        organizationId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
