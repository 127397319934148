import { t } from 'i18next';
import { Dropdown } from '@/components';
import { CrossDelete } from '../../../../../../components/Icons';
import { twMerge } from 'tailwind-merge';
import { useGetUserProfiles } from '@/modules/Users/queries/get-user-profiles';
import { useMemo } from 'react';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';

export const List = ({
  items,
  deleteUser,
  selectRole,
}: {
  items: Array<{ id: number; value: string; role?: number }>;
  deleteUser: (index: number) => void;
  selectRole: (roleId: number, userId: number) => void;
}) => {
  const { data: roles } = useGetUserProfiles();
  const roleDropdownOptions = useMemo(() => getDropdownArray(roles), [roles]);

  return (
    <div
      className={twMerge(
        'divide-y',
        items.length > 0 && 'border-b-1 border-t-1',
      )}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className="flex h-auto flex-row items-center justify-between p-2"
        >
          {item.value as string}
          <div className="flex min-w-52">
            <Dropdown
              className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
              placeholder={t('profile')}
              options={roleDropdownOptions}
              onSelect={(val) => selectRole(Number(val?.value), item.id)}
              value={String(item.role)}
            />
            <div
              className="flex h-auto cursor-pointer items-center justify-center pl-3"
              onClick={() => deleteUser(index)}
            >
              <CrossDelete />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
