import { SortDirection } from '@/components/Table/types';
import { companiesRepo } from '@/constants/providers';
import { CompanyModel } from '@/modules/Companies/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CompanyKeys } from './types';

export const useGetCompaniesByOrganization = (
  organizationId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<CompanyModel[]>,
    any,
    DataWithPagination<CompanyModel[]>
  >,
) =>
  useQuery<DataWithPagination<CompanyModel[]>>(
    [
      CompanyKeys.GET_COMPANIES_BY_ORGANIZATION,
      { organizationId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      companiesRepo.getCompaniesByOrganization(
        organizationId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

export const useGetAllCompaniesByOrganization = (organizationId: number) =>
  useQuery<CompanyModel[]>(
    [CompanyKeys.GET_COMPANIES_BY_ORGANIZATION, { organizationId }],
    () => companiesRepo.getAllCompaniesByOrganization(organizationId),
    {
      enabled: !!organizationId,
    },
  );
