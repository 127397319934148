export enum TasksQueryKey {
  ALL = 'all-tasks',
  GET_ALL = 'get-tasks',
  GET = 'get-task',
  GET_TASKS_BY_PROCESS = 'get-tasks-by-process',
  GET_TASKS_BY_ORGANIZATION = 'get-tasks-by-organization',
  GET_TASKS_BY_COMPANY = 'get-tasks-by-company',
  GET_TASKS_BY_BUSINESS_AREA = 'get-tasks-by-business_area',
  GET_TASKS_WITHOUT_WORKFLOW = 'get-tasks-without-workflow',
}
