import { Spinner } from '@/components/Spinner';
import { RoutePath } from '@/core/router';
import { useGetCurrentUser } from '@/modules/Users/queries';
import { useKeycloak } from '@react-keycloak/web';
import { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoadingPage = (): ReactElement => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { data } = useGetCurrentUser(keycloak.token);

  const userOrganization = useMemo(() => {
    if (data?.organizations.length === 1) {
      return data?.organizations[0];
    }
  }, [data]);

  useEffect(() => {
    if (keycloak.authenticated && data) {
      const redirectTo =
        localStorage.getItem('redirectAfterLogin') ||
        (!userOrganization
          ? RoutePath.organizations()
          : RoutePath.organizationDetail(String(userOrganization)));

      localStorage.removeItem('redirectAfterLogin');
      navigate(redirectTo);
    }
  }, [keycloak.authenticated, navigate, data, userOrganization]);

  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner />
    </div>
  );
};
