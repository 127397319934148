import { ReactElement, ReactNode, memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  LoadingRow,
  NoDataCell,
  TableHead,
  TablePagination,
  TableRow,
} from './components';
import { useTable } from './hooks';
import {
  TableColumn,
  TableRow as TableRowInterface,
  TableSort,
  TableVariant,
} from './types';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import './styles.css';

interface TableProps<R extends TableRowInterface> {
  className?: string;
  columns: Array<TableColumn<R>>;
  rows: Array<R>;
  variant?: TableVariant;
  noOutline?: boolean;
  selectedRowId?: string | number;
  defaultSort?: TableSort;
  withPagination?: boolean;
  sorting?: TableSort[];
  currentPage?: number;
  totalItems?: number;
  totalPages?: number;
  loadingRowsCount?: number;
  isLoading?: boolean;
  noResultsLabel?: string;
  showDots?: boolean;
  multipleSorts?: boolean;
  draggable?: boolean;
  ExpandableContent?: (props: {
    row: R;
    columns: TableColumn<R>[];
  }) => ReactElement;
  onPageChange?: (page: number) => void;
  onSort?: (sort: TableSort[]) => void;
  onRowClick?: (row: R) => void;
  actionCallback?: (id: number) => void;
  renderItem?: (id?: number, disabled?: boolean) => ReactNode;
  highlightRows?: number[];
  checkPropertyTodisable?: string;
  checkValueToDisable?: number;
}

const TableComponent = <R extends TableRowInterface>({
  className,
  columns,
  rows,
  variant = 'medium',
  noOutline,
  sorting,
  defaultSort,
  selectedRowId,
  currentPage = 1,
  totalItems = 0,
  totalPages = 0,
  withPagination,
  loadingRowsCount = 5,
  isLoading,
  noResultsLabel,
  showDots,
  draggable,
  ExpandableContent,
  onPageChange,
  onSort,
  onRowClick,
  actionCallback,
  renderItem,
  highlightRows = [],
  checkValueToDisable,
  checkPropertyTodisable,
}: TableProps<R>): ReactElement => {
  const {
    pageIndex,
    sortedData,
    handleSort,
    sorts,
    handleNextPage,
    handlePageChange,
    handlePrevPage,
    nextDisabled,
    prevDisabled,
  } = useTable(
    rows,
    columns,
    currentPage,
    totalPages,
    onPageChange,
    withPagination,
    sorting,
    defaultSort,
    onSort,
  );

  const checkDisableRow = useCallback(
    (row: { [x: string]: number }) =>
      checkPropertyTodisable &&
      checkValueToDisable &&
      row[checkPropertyTodisable] &&
      row[checkPropertyTodisable] !== checkValueToDisable,
    [checkPropertyTodisable, checkValueToDisable],
  );

  return (
    <>
      <div className={twMerge(`w-full overflow-auto bg-white`, className)}>
        <div className="flex flex-row">
          <table
            className="w-full border-separate bg-inherit"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead className="bg-inherit">
              <TableHead
                columns={columns}
                variant={variant}
                onSort={handleSort}
                sorting={sorts}
                showDots={showDots}
              />
            </thead>
            <tbody>
              {isLoading &&
                Array.from({ length: loadingRowsCount ?? 0 }).map(
                  (_, rowIndex) => (
                    <LoadingRow
                      key={`loading-${rowIndex}`}
                      rowIndex={rowIndex}
                      columnsCount={columns.length}
                    />
                  ),
                )}
              {!isLoading &&
                (sortedData.length === 0 ? (
                  <NoDataCell
                    colSpan={columns.length}
                    variant={variant}
                    noResultsLabel={noResultsLabel}
                  />
                ) : (
                  <SortableContext
                    items={rows}
                    strategy={verticalListSortingStrategy}
                    disabled={!draggable}
                  >
                    {sortedData.map((row) => (
                      <TableRow
                        draggable={draggable}
                        key={row.id}
                        row={row}
                        columns={columns}
                        variant={variant}
                        noOutline={noOutline}
                        onRowClick={onRowClick}
                        selected={row.id === selectedRowId}
                        showDots={showDots}
                        ExpandableContent={ExpandableContent}
                        actionCallback={actionCallback}
                        renderItem={renderItem}
                        highlightRow={
                          !checkDisableRow(row) &&
                          highlightRows.includes(row.id as number)
                        }
                        disableRow={checkDisableRow(row) as boolean}
                      />
                    ))}
                  </SortableContext>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {withPagination && totalItems > 0 && (
        <TablePagination
          pageIndex={pageIndex}
          nextDisabled={nextDisabled}
          prevDisabled={prevDisabled}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        />
      )}
    </>
  );
};

export const Table = memo(TableComponent) as typeof TableComponent;
