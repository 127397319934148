import { SortDirection } from '@/components/Table/types';
import { automationsRepo } from '@/constants/providers';
import { AutomationModel } from '@/modules/Automations/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AutomationQueryKey } from './types';

export const useGetAutomationsByCompany = (
  companyId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<AutomationModel[]>,
    any,
    DataWithPagination<AutomationModel[]>
  >,
) =>
  useQuery<DataWithPagination<AutomationModel[]>>(
    [
      AutomationQueryKey.GET_AUTOMATIONS_BY_COMPANY,
      { companyId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      automationsRepo.getAutomationsByCompany(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
