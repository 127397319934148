import { PropsWithChildren, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

interface PageContainerProps {
  className?: string;
}

export const PageContainer = ({
  className,
  children,
}: PropsWithChildren<PageContainerProps>): ReactElement => {
  return (
    <div className={twMerge('flex w-full flex-col gap-5 p-12', className)}>
      {children}
    </div>
  );
};
