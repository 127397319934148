import { PropsWithChildren } from 'react';
import InputWithError from '../InputWithError/InputWithError';
import InputWithTitle from '../InputWithTitle/InputWithTitle';

interface FieldInfoWithTitleProps {
  title: string;
  errorText?: string;
  hasError: boolean;
}

export const FieldInfo = ({
  title,
  children,
  errorText,
  hasError,
}: PropsWithChildren<FieldInfoWithTitleProps>) => {
  return (
    <InputWithTitle title={title}>
      <InputWithError errorText={errorText} hasError={hasError}>
        {children}
      </InputWithError>
    </InputWithTitle>
  );
};
