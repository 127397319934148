import { Container, Tabs } from '@/components';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutomationPerformanceTab } from '../AutomationPerformanceTab';
//import { TaskPerformanceTab } from '../TaskPerformanceTab';
import { AssociatedTasksTab } from '../AssociatedTasksTab';

interface AutomationPerformanceProps {
  company?: string;
  businessArea?: string;
  organization?: string;
  processId?: number;
  automationId?: number;
  tasksCount?: number;
}

export const AutomationPerformance = ({
  //company,
  //businessArea,
  //organization,
  processId,
  automationId,
  tasksCount,
}: AutomationPerformanceProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
    },
    [setSelectedTab],
  );

  //TODO: Implement TaskPerformanceTab
  return (
    <div>
      <h1 className="mb-3">{t('automation_performance')}</h1>
      <Container className="mb-2 w-full">
        <Tabs
          tabs={[
            { label: t('automation_performance') },
            //{ label: t('task_performance') },
            { label: t('associated_tasks'), badgeNumber: tasksCount },
          ]}
          onTabChange={onTabChange}
        />
      </Container>
      {selectedTab === 0 && <AutomationPerformanceTab />}
      {/*selectedTab === 1 && (
        <TaskPerformanceTab
          company={company}
          businessArea={businessArea}
          organization={organization}
        />
      )*/}
      {selectedTab === 1 && automationId && (
        <AssociatedTasksTab
          processId={processId?.toString()}
          automationId={automationId}
        />
      )}
    </div>
  );
};
