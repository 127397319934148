import { HttpClient } from './http-client';
import keycloak from '@/keycloak';

export class ProtectedHttpClient extends HttpClient {
  constructor(resource: string) {
    super(resource);

    this.setOnRequest((config, headers) => {
      const token = keycloak.token;

      return {
        ...config,
        headers: {
          ...(headers ?? {}),
          Authorization: `Bearer ${token}`,
        },
      };
    });

    this.setOnResponse({
      error(error) {
        return error;
      },
    });
  }
}
