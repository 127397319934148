import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { roundToDecimal } from '../roundToDecimals';

export const rateColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
): TableColumn<T> => ({
  id: 'value',
  label: <Trans i18nKey="rate" />,
  render: (value) => {
    return (
      <div className="overflow-hidden text-ellipsis font-bold">
        {roundToDecimal(value)}€
      </div>
    );
  },
  acessorKey: 'value',
  align: 'left',
  alignHeader: 'left',
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
