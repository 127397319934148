import { DataWithPagination } from '@/shared/types/pagination';
import { OrganizationModel } from '../domain';
import { EditOrganization, OrganizationJson } from '../types';
import { ApiResponse } from '@/shared/types';

export abstract class OrganizationRepository {
  abstract updateOrganization(
    organizationJson: EditOrganization,
  ): Promise<OrganizationModel>;
  abstract createOrganization(
    organizationJson: OrganizationJson,
  ): Promise<OrganizationModel>;
  abstract deleteOrganization(orgId: number): Promise<OrganizationModel>;
  abstract getAllOrganizations(
    pageNumber: number,
    filter: string,
  ): Promise<DataWithPagination<OrganizationModel[]>>;
  abstract getAllOrganizationsWithoutPagination(): Promise<OrganizationModel[]>;
  abstract getOrganization(orgId: string): Promise<OrganizationModel>;
  abstract enableOrganization(id: number): Promise<ApiResponse<unknown>>;
  abstract disableOrganization(id: number): Promise<ApiResponse<unknown>>;
  abstract getOrganizationsByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<OrganizationModel[]>>;
}
