import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TableRow, TableSort } from '@/components/Table/types';
import { useNavigate } from 'react-router-dom';
import { getTasksColumnsNamedMapped, getTasksColumns } from './utils';
import { useGetTasks } from '../../queries';
import { CreateTaskModal } from './components/CreateTaskModal';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';

export const TaskPage = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  //TODO: uncomment when create a modal to add a new task
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const {
    data: tasks,
    isLoading: isLoadingTasks,
    isError,
  } = useGetTasks(
    page + 1,
    searchTerm,
    getTasksColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
  );

  useEffect(() => {
    if (isError) {
      toast.error(t('error_getting_tasks'));
    }
  }, [isError, t]);

  const routeChange = useCallback(
    (row?: TableRow) => {
      const path = `${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('tasks')}>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('create_task')}
        </Button>
      </CustomPageContainer.Header>
      <FilterSearchBar
        total={tasks?.totalRows || 0}
        title={t('tasks')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />

      <CreateTaskModal handleClose={handleModalState} isOpen={isOpen} />
      <Table
        columns={getTasksColumns({ onClick: routeChange })}
        rows={tasks?.items ?? []}
        isLoading={isLoadingTasks}
        noResultsLabel={t('no_processes_available')}
        withPagination
        totalItems={tasks?.totalRows}
        totalPages={tasks?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </CustomPageContainer>
  );
};
