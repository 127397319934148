import { TableColumn, TableRow } from '@/components/Table/types';
import {
  businessAreaNameColumnConfig,
  codeColumnConfig,
  estimatedCostColumnConfig,
  statusColumnConfig,
} from '@/utils';
import { WorkflowModel } from '../../domain';
import { tasksCountIconColumnConfig } from '@/utils/tableColumns/tasks-count-icon-column-config';
import { workflowNameColumnConfig } from '@/utils/tableColumns/workflow-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';

type WorkflowsColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getWorkflowsColumns = ({
  onClick,
}: WorkflowsColumnsConfig): Array<TableColumn<WorkflowModel>> => {
  return [
    workflowNameColumnConfig({ onClick }),
    codeColumnConfig(),
    statusColumnConfig(),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    tasksCountIconColumnConfig(),
    estimatedCostColumnConfig(),
  ];
};
export const getWorflowsColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    code: 'Code',
    businessAreaName: 'BusinessAreaName',
    status: 'Status',
    tasks: 'TasksCount',
    runTime: 'RunTime',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};
