export enum WorkflowsQueryKey {
  GET_All = 'get-workflows',
  GET = 'get-workflow',
  GET_WOKFLOWS_BY_BUSINESS_AREA = 'get-workflows-by-business-area',
  GET_ALL_WOKFLOWS_BY_BUSINESS_AREA = 'get-all-workflows-by-business-area',
  GET_ALL_WORKFLOWS_BY_PROCESS = 'get-all-workflows-by-process',
  GET_WOKFLOWS_BY_ORGANIZATION = 'get-workflows-by-organization',
  GET_WOKFLOWS_BY_COMPANY = 'get-workflows-by-company',
  GET_ALL_WORKFLOWS_BY_PROCESS_AUTOMATION = 'get-all-workflows-by-process-automation',
}
