import { TableColumn, TableRow } from '@/components/Table/types';
import {
  automationsCountColumnConfig,
  businessAreasCountColumnConfig,
  organizationNameColumnConfig,
  nameWithAvatarColumnConfig,
  usersCountColumnConfig,
  statusColumnConfig,
} from '@/utils';
import { CompanyModel } from '../../domain';
import { processesCountColumnConfig } from '@/utils/tableColumns/processes-count-column-config';

type CompaniesColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getCompaniesColumns = ({
  onClick,
}: CompaniesColumnsConfig): Array<TableColumn<CompanyModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    organizationNameColumnConfig(),
    statusColumnConfig(),
    usersCountColumnConfig(),
    businessAreasCountColumnConfig(),
    processesCountColumnConfig(),
    automationsCountColumnConfig({
      options: {
        className: 'w-25',
      },
    }),
  ];
};

export const getCompaniesColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    organizationName: 'OrganizationName',
    status: 'Status',
    users: 'UsersCount',
    businessAreasCount: 'BusinessAreasCount',
    processesCount: 'ProcessesCount',
    automationsCount: 'AutomationsCount',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};
