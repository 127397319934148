import {
  AutomationsTab,
  Button,
  ChevronLeft,
  CustomPageContainer,
  TabItemProps,
  TableWithFilter,
  UsersTab,
} from '@/components';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { AutomationModel } from '@/modules/Automations/domain';
import { getAutomationColumnsNamedMapped } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationsByTask } from '@/modules/Automations/queries';
import { UserModel } from '@/modules/Users/domain';
import { getUsersColumnsNamedMapped } from '@/modules/Users/pages/UsersPage/utils';
import { useGetUsersByTask } from '@/modules/Users/queries';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDisableTask, useEnableTask } from '../../queries';
import { useGetTask } from '../../queries/get-task';
import { HeaderRow } from './components/HeaderRow';
import { TaskPerformanceCards } from './components/TaskPerformanceCards';

export const TaskDetailPage = (): ReactElement => {
  const { taskId } = useParams<{ taskId: string }>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    data: task,
    isLoading: isLoadingTask,
    isError,
    error,
  } = useGetTask(taskId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_task_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(task?.status === true);
  }, [task]);

  const { mutate: enableTask } = useEnableTask();
  const { mutate: disableTask } = useDisableTask();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!task?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableTask(task?.id, {
          onError: () => {
            toast.error(t('error_enable_task'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_task'));
          },
        });
      } else {
        disableTask(task?.id, {
          onError: () => {
            toast.error(t('error_disable_task'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_task'));
          },
        });
      }
    },
    [task, enableTask, disableTask, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('users'), badgeNumber: task?.usersCount },
      { label: t('automations'), badgeNumber: task?.automationsCount },
    ],
    [t, task],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!taskId) {
      return [];
    }
    return [
      {
        index: 0,
        tab: (
          <TabContainer<UserModel>
            parentId={parseInt(taskId)}
            useGetData={useGetUsersByTask}
            getColumnsNamedMapped={getUsersColumnsNamedMapped}
          >
            <UsersTab showRole taskId={parseInt(taskId)} />
          </TabContainer>
        ),
      },
      {
        index: 1,
        tab: (
          <TabContainer<AutomationModel>
            parentId={parseInt(taskId)}
            useGetData={useGetAutomationsByTask}
            getColumnsNamedMapped={getAutomationColumnsNamedMapped}
          >
            <AutomationsTab canCreate={false} />
          </TabContainer>
        ),
      },
    ];
  }, [taskId]);

  return (
    <CustomPageContainer>
      <div>
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        {task && (
          <p className="mt-3 text-xs">
            {task?.organization} / {task?.company} / {task?.businessAreaName} /{' '}
            {task?.workflow && `${task.workflow}`} / {t('tasks')} /
            <span className="font-black"> {task?.name}</span>
          </p>
        )}
      </div>
      <HeaderRow
        task={task}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingTask}
      />
      {task?.automationId && (
        <TaskPerformanceCards isLoading={isLoadingTask} data={task} />
      )}
      <TableWithFilter>
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TableWithFilter>
    </CustomPageContainer>
  );
};
