import { ApiResponse, DataWithPagination } from '@/shared/types';
import { AutomationModel, AutomationPerformanceModel } from '../domain';
import { AssociatedTasksModel } from '../domain/associated-tasks-model';
import { StageModel } from '../domain/stage-model'; // Assuming there's a StageModel
import { DeleteAutomationDocumentJson, UpdateStageJson } from '../types';

export abstract class AutomationRepository {
  abstract getAllAutomations(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationStages(): Promise<Array<StageModel>>;
  abstract getAutomation(id: string): Promise<AutomationModel>;
  abstract getAutomationPerformance(
    automationId: string,
  ): Promise<AutomationPerformanceModel>;
  abstract getAssociatedTasks(
    automationId: string,
  ): Promise<Array<AssociatedTasksModel>>;
  abstract createAutomation(data: FormData): Promise<number>;
  abstract getAutomationsByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract getAutomationsByTask(
    taskId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<AutomationModel[]>>;
  abstract updateStage(data: UpdateStageJson): Promise<unknown>;
  abstract addDocument(data: FormData): Promise<unknown>;
  abstract deleteDocument(data: DeleteAutomationDocumentJson): Promise<unknown>;
  abstract enableAutomation(id: number): Promise<ApiResponse<unknown>>;
  abstract disableAutomation(id: number): Promise<ApiResponse<unknown>>;
}
