export enum PerformanceType {
  Good = 'good',
  Bad = 'bad',
  Neutral = 'neutral',
}

export const performanceCardColors: Record<PerformanceType, string> = {
  [PerformanceType.Good]: 'bg-americanGreen',
  [PerformanceType.Bad]: 'bg-redBellPepper',
  [PerformanceType.Neutral]: 'bg-trueskyGloxym',
};
