import { ReactElement, ReactNode, useState } from 'react';
import {
  TableColumn,
  TableRow as TableRowInterface,
  TableVariant,
} from '../../types';
import { TableRowCell } from '../TableRowCell';
import { Dots } from '@/components/Dots';
interface TableRowProps<R extends TableRowInterface> {
  row: R;
  columns: TableColumn<R>[];
  variant: TableVariant;
  onRowClick?: (row: R) => void;
  noOutline?: boolean;
  selected?: boolean;
  showDots?: boolean;
  draggable?: boolean;
  ExpandableContent?: (props: {
    row: R;
    columns: TableColumn<R>[];
  }) => ReactElement;
  actionCallback?: (id: number) => void;
  renderItem?: (id?: number, disabled?: boolean) => ReactNode;
  highlightRow: boolean;
  disableRow?: boolean;
}

export const TableRow = <R extends TableRowInterface>({
  row,
  columns,
  showDots,
  ExpandableContent,
  draggable,
  actionCallback,
  renderItem,
  highlightRow,
  disableRow,
  ...otherProps
}: TableRowProps<R>): ReactElement => {
  const [isAccordionOpen, setAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setAccordionOpen(!isAccordionOpen);
  };

  return (
    <>
      <tr
        onClick={() => ExpandableContent && handleAccordionToggle()}
        key={`accordion-${row.id}`}
      >
        {showDots && (
          <td
            className="group/cell border-b-2.5 border-transparent"
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <Dots />
          </td>
        )}

        <TableRowCell
          row={row}
          cols={columns}
          draggable={draggable}
          hasAction={!!renderItem}
          highlightRow={highlightRow}
          disableRow={disableRow}
          {...otherProps}
        />
        {renderItem && (
          <td
            className="group/action flex h-11.25 cursor-pointer items-center justify-center pl-2"
            onClick={() => actionCallback && actionCallback(Number(row?.id))}
          >
            {renderItem(Number(row?.id), disableRow)}
          </td>
        )}
      </tr>
      {isAccordionOpen && ExpandableContent && (
        <tr>
          <th colSpan={columns.length}>
            <ExpandableContent row={row} columns={columns} />
          </th>
        </tr>
      )}
    </>
  );
};
