import { AutomationPerformanceModel } from '../domain';
import { AutomationPerformanceJson } from '../types';

export const automationPerformanceMapper = (
  automationPerformanceJson: AutomationPerformanceJson,
): AutomationPerformanceModel => {
  return {
    id: automationPerformanceJson?.id ?? '',
    timeSaved: {
      total: {
        value: automationPerformanceJson?.executionMetrics?.totalRunTime ?? 0,
        predicted:
          automationPerformanceJson?.executionMetrics?.totalManualDuration ?? 0,
      },
      average: {
        value: automationPerformanceJson?.executionMetrics?.averageRunTime ?? 0,
        predicted:
          automationPerformanceJson?.executionMetrics?.manualDuration ?? 0,
      },
    },
    lastRunSuccessRate: {
      successes:
        automationPerformanceJson?.executionMetrics?.lastRunTotalSuccessful ??
        0,
      businessErrors:
        automationPerformanceJson?.executionMetrics
          ?.lastRunTotalBusinessExceptions ?? 0,
      genericErrors:
        automationPerformanceJson?.executionMetrics
          ?.lastRunTotalSystemExceptions ?? 0,
    },
    devTime: {
      maintenance: automationPerformanceJson?.devTime?.maintenanceCount ?? 0,
      development: automationPerformanceJson?.devTime?.developmentCount ?? 0,
      support: automationPerformanceJson?.devTime?.supportCount ?? 0,
    },
    investmentReturn: {
      total: {
        value:
          automationPerformanceJson?.investmentReturn?.totalReturn?.value ?? 0,
        totalInvestment:
          automationPerformanceJson?.investmentReturn?.totalReturn
            ?.totalInvestment ?? 0,
      },
      average: {
        value:
          automationPerformanceJson?.investmentReturn?.averageReturnPerCase
            ?.value ?? 0,
      },
    },
    overallSuccessRate: {
      successes:
        automationPerformanceJson?.executionMetrics?.totalSuccessfulCases ?? 0,
      businessErrors:
        automationPerformanceJson?.executionMetrics
          ?.totalBusinessExceptionsCases ?? 0,
      genericErrors:
        automationPerformanceJson?.executionMetrics
          ?.totalSystemExceptionsCases ?? 0,
    },
    investment: {
      maintenance: automationPerformanceJson?.investment?.maintenanceCost ?? 0,
      development: automationPerformanceJson?.investment?.developmentCost ?? 0,
      infrastructure:
        automationPerformanceJson?.investment?.infrastructureCost ?? 0,
      support: automationPerformanceJson?.investment?.supportCost ?? 0,
    },
    ROI: {
      date: automationPerformanceJson?.ROI?.date ?? '',
      totalInvestment: automationPerformanceJson?.ROI?.totalInvestment ?? 0,
    },
    predictedManualTime: automationPerformanceJson?.predictedManualTime ?? 0,
    predictedAverageManualTime:
      automationPerformanceJson?.predictedAverageManualTime ?? 0,
    automationRunTime: automationPerformanceJson?.automationRunTime ?? 0,
    averageAutomationRunTime:
      automationPerformanceJson?.averageAutomationRunTime ?? 0,
    totalInvestment: automationPerformanceJson?.totalInvestment ?? 0,
    totalReturn: automationPerformanceJson?.totalReturn ?? 0,
    averageCost: automationPerformanceJson?.averageCost ?? 0,
    averageManualCost: automationPerformanceJson?.averageManualCost ?? 0,
  };
};
