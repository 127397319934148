import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const NotFound = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <div className="text-6xl">404</div>
      <h1>{t('page_not_found')}</h1>
    </div>
  );
};
