import { Button, Input, Modal, TextArea } from '@/components';
import InputWithError from '@/components/InputWithError/InputWithError';
import InputWithTitle from '@/components/InputWithTitle/InputWithTitle';
import {
  OrganizationSchema,
  OrganizationSchemaType,
} from '@/modules/Organizations/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface OrganizationModalProps {
  title: string;
  description?: string;
  isLoading: boolean;
  isOpen: boolean;
  name?: string;
  type: 'create' | 'edit';
  handleOnSubmit: (e: OrganizationSchemaType) => void;
  handleClose: () => void;
}

export const OrganizationModal = ({
  title,
  isOpen,
  description,
  name,
  isLoading,
  handleOnSubmit,
  handleClose,
  type,
}: OrganizationModalProps): ReactElement => {
  const { t } = useTranslation();

  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<OrganizationSchemaType>({
    resolver: zodResolver(OrganizationSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  useEffect(() => {
    clearErrors();
    setValue('organizationName', name || '');
    setValue('organizationDescription', description || '');
  }, [clearErrors, setValue, name, description, isOpen]);

  const onSubmit: SubmitHandler<OrganizationSchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }
    handleOnSubmit(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <InputWithTitle title={t('organization_name')}>
            <InputWithError
              errorText={
                errors.organizationName?.message
                  ? t(errors.organizationName.message)
                  : undefined
              }
              hasError={!!errors.organizationName?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray italic"
                placeHolder={t('organization_name')}
                name="organizationName"
                register={register}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('description')}>
            <InputWithError
              errorText={
                errors.organizationDescription?.message
                  ? t(errors.organizationDescription.message)
                  : undefined
              }
              hasError={!!errors.organizationDescription?.message}
            >
              <TextArea
                isDisabled={isLoading}
                className="h-24 w-full bg-brightGray italic"
                placeHolder={t('description')}
                name="organizationDescription"
                register={register}
              />
            </InputWithError>
          </InputWithTitle>
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {type === 'create' ? t('create') : t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
