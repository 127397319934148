import { z } from 'zod';

export const ProcessSchema = z.object({
  organizationId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  companyId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  businessAreaId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  description: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  processName: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  processOwnerId: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  workflows: z.any().optional(),
});

export type FormProcessSchemaType = z.infer<typeof ProcessSchema>;

export interface ProcessSchemaType extends FormProcessSchemaType {
  id?: number;
}
