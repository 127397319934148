import Switch, { ReactSwitchProps } from 'react-switch';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const SwitchStatus = (props: ReactSwitchProps) => (
  <Switch
    {...props}
    checkedIcon={false}
    uncheckedIcon={false}
    height={18}
    width={40}
    handleDiameter={16}
    onColor={fullConfig.theme.colors.americanGreen}
  />
);
