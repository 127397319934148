import { useQuery } from '@tanstack/react-query';
import { OrganizationModel } from '../domain';
import { OrganizationKeys } from './types';
import { QueryOptions } from '@/modules/types';
import { organizationsRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types/pagination';
import { SortDirection } from '@/components/Table/types';

export const useGetOrganizations = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: QueryOptions<DataWithPagination<OrganizationModel[]>>,
) =>
  useQuery<DataWithPagination<OrganizationModel[]>>(
    [
      OrganizationKeys.GET_ALL,
      {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      },
    ],
    () =>
      organizationsRepo.getAllOrganizations(
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

export const useGetAllOrganizations = () =>
  useQuery<OrganizationModel[]>([OrganizationKeys.GET_ALL], () =>
    organizationsRepo.getAllOrganizationsWithoutPagination(),
  );
