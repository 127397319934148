import { toast } from 'react-toastify';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessAreaModal } from '../BusinessAreaModal';
import { useQueryClient } from '@tanstack/react-query';
import { BusinessAreasQueryKey } from '@/modules/BusinessAreas/queries/types';
import { BusinessAreaSchemaType } from '@/modules/BusinessAreas/types/businessArea-schema';
import { useGetBusinessArea } from '@/modules/BusinessAreas/queries';
import { useUpdateBusinessArea } from '@/modules/BusinessAreas/queries/update-business-area';

interface EditBusinessAreaModal {
  isOpen: boolean;
  handleClose: () => void;
  businessAreaId?: number;
}

export const EditBusinessAreaModal = ({
  isOpen,
  handleClose,
  businessAreaId,
}: EditBusinessAreaModal): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateBusinessArea();

  const { data: businessArea, isLoading: isLoadingGet } = useGetBusinessArea(
    businessAreaId?.toString(),
  );

  const handleConfirm = (data: BusinessAreaSchemaType) => {
    const convertedData = {
      id: data.id ?? businessArea?.id,
      companyId: Number(data.companyId),
      name: data.businessAreaName ?? businessArea?.name,
      code: data.businessAreaCode ?? businessArea?.code,
      status: true,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_businessArea'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([BusinessAreasQueryKey.GET]);
        handleClose();
        toast.success(t('success_update_businessArea'));
      },
    });
  };

  return (
    <BusinessAreaModal
      handleClose={handleClose}
      type="edit"
      handleOnSubmit={handleConfirm}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingGet}
      title={t('edit_business_area')}
      companyId={businessArea?.companyId}
      organizationId={businessArea?.organizationId}
      businessAreaName={businessArea?.name}
      businessAreaCode={businessArea?.code}
    />
  );
};
