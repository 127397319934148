import { ClassNameValue } from 'tailwind-merge';

export type ButtonStyleVariant =
  | 'alternative'
  | 'outline'
  | 'primary'
  | 'text'
  | 'gray';

export const buttonStylesVariants: Record<ButtonStyleVariant, ClassNameValue> =
  {
    alternative:
      'bg-blueAngel text-sm h-fit  w-fit rounded-md py-2 text-center font-bold capitalize text-white',
    primary:
      'bg-blueNuit text-sm h-fit w-fit rounded-md p-2.5 text-center font-bold capitalize text-white',
    outline:
      'text-sm h-fit w-fit rounded-md border-2 border-black bg-white py-2 text-center font-bold capitalize',
    text: 'text-inherit',
    gray: 'rounded-sm bg-rinseGray px-2.5 py-1.25',
  };
