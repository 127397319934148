import { Config } from 'tailwindcss';

const dimensions = {
  0.2: '0.0625rem', // 1px
  0.5: '0.1875rem', // 3px
  1: '0.25rem', // 4px
  1.25: '0.3125rem', // 5px
  2: '0.5rem', // 8px
  2.5: '0.625rem', // 10px
  3: '0.75rem', // 12px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8.75: '2.1875rem', // 35px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  11: '2.75rem', // 44px
  11.25: '2.8125rem', // 45px
  20: '5rem', // 80px
  24: '6rem', // 96px
  25: '6.25rem', // 100px
  45: '11.25rem', // 180px
  52: '13rem', // 208px
  62: '15.625rem', // 250px
  112: '28rem', // 448px
  128: '32rem', // 512px
  144: '36rem', // 576px
};

export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontFamily: {
      sans: ['Lato', 'sans-serif'],
    },
    fontSize: {
      xs: '0.625rem', // 10px
      sm: '0.75rem', // 12px
      md: '0.875rem', // 14px
      ml: '1rem', // 16px
      lg: '1.25rem', // 20px
      xl: '1.5rem', // 24px
      '2xl': '2rem', // 32px
      '3xl': '2.5rem', // 40px
      '6xl': '3.5rem', // 56px
    },
    lineHeight: {
      xs: '0.75rem', // 12px
      sm: '0.9rem', //14.4px
    },
    borderRadius: {
      xs: '0.125rem', // 2px
      sm: '0.1875rem', // 3px
      md: '0.3125rem', // 5px
      ml: '0.4375rem', // 7px
      lg: '0.625rem', // 10px
      xl: '0.75rem', // 12px
      '3xl': '1.5rem', // 24px
      full: '9999px',
    },
    extend: {
      colors: {
        primaryBlue: '#1F3B52',
        plumBlue: '#4C6275',
        trappedDarkness: '#0B1F30',
        blueNuit: '#095BFF',
        blueAngel: '#0029E2',
        fennelBlue: '#6B9DFF',
        brightGray: '#F2F3F5',
        rinseGray: '#D2D8DC',
        intercoastalGray: '#A5B1BA',
        redBellPepper: '#E43600',
        salmonOrange: '#FF8F6D',
        americanGreen: '#3AB838',
        shinyBlue: '#095BFF',
        darkBlue: '#0B1F30',
        spartanBlue: '#798997',
        trueskyGloxym: '#9dbdff',
        lightPurple: '#E6EFFF',
      },
      zIndex: {
        2: '2',
        99: '99',
      },
      borderWidth: {
        1: '0.0625rem', // 1px
        2: '0.125rem', // 2px
        2.5: '0.625rem', // 10px
      },
      gap: dimensions,
      margin: dimensions,
      padding: dimensions,
      height: dimensions,
      width: dimensions,
      minWidth: dimensions,
      minHeight: dimensions,
      maxHeight: dimensions,
      maxWidth: dimensions,
    },
  },
  plugins: [],
} satisfies Config;
