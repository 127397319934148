import {
  AutomationsPage,
  BusinessAreaDetailPage,
  BusinessAreasPage,
  CompaniesPage,
  CompanyDetailPage,
  DashboardPage,
  OrganizationsPage,
  ProcessDetailPage,
  ProcessesPage,
  UsersPage,
  WorkflowsPage,
} from './modules';
import { NotFound } from './components';
import { RoutePath } from './core/router';
import { AutomationDetailPage } from './modules/Automations/pages/AutomationDetailPage';
import { OrganizationDetailPage } from './modules/Organizations/pages/OrganizationDetailPage';
import { RouteEntry } from './utils';
import { LoadingPage } from './modules/Auth/pages';
import { UserDetailPage } from './modules/Users/pages/UserDetailPage/UserDetailPage';
import { TableWithFilter } from './components';
import { TaskPage } from './modules/Tasks/pages/TasksPage';
import { TaskDetailPage } from './modules/Tasks/pages/TaskDetailPage';
import { WorkflowDetailPage } from './modules/Workflows/pages/WorkflowDetailsPage';

export const routerConfiguration: RouteEntry[] = [
  {
    path: RoutePath.dashboard(),
    component: DashboardPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.automations(),
    component: () => TableWithFilter({ children: <AutomationsPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.automationDetail(),
    component: AutomationDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.businessAreas(),
    component: () => TableWithFilter({ children: <BusinessAreasPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.businessAreaDetail(),
    component: BusinessAreaDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.companies(),
    component: () => TableWithFilter({ children: <CompaniesPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.companyDetail(),
    component: CompanyDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.organizations(),
    component: () => TableWithFilter({ children: <OrganizationsPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.organizationDetail(),
    component: OrganizationDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.processes(),
    component: () => TableWithFilter({ children: <ProcessesPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.processDetail(),
    component: ProcessDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.tasks(),
    component: () => TableWithFilter({ children: <TaskPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.taskDetail(),
    component: TaskDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.users(),
    component: () => TableWithFilter({ children: <UsersPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.userDetail(),
    component: UserDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.workflows(),
    component: () => TableWithFilter({ children: <WorkflowsPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.workflowDetail(),
    component: WorkflowDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.notFound(),
    component: NotFound,
    requiresAuth: true,
  },
  {
    path: RoutePath.loadingPage(),
    component: LoadingPage,
    requiresAuth: false,
  },
];
