import { ReactElement } from 'react';
import { BlueDot, GreyDot } from '../Icons';

export const Dots = (): ReactElement => {
  return (
    <div className="flex h-full flex-col items-center space-y-2">
      <GreyDot className="flex-1" />
      <GreyDot className="flex-1" />
      <BlueDot className="flex-1" />
      <GreyDot className="flex-1" />
      <GreyDot className="flex-1" />
    </div>
  );
};
