import { ReactElement, useMemo } from 'react';
import { Container } from '../Container';
import { PieChart, PieData } from '../PieChart';
import { Tag } from '../Tag';
import {
  convertSecondsToTimeMinutesToHours,
  convertSecondsToTimeString,
} from '@/utils';
import { roundToDecimal } from '@/utils/roundToDecimals';

enum ValueType {
  hours = 'h',
  percentage = '%',
  coin = '€',
}

interface PieChartCardProps {
  data: Array<PieData>;
  chartTitle: string;
  valueType: keyof typeof ValueType;
  subtitle?: string;
  convert?: boolean; //TODO: this prop is to convert count/percentage to Time in Total label on Process and Workflow details page. Rename it or find another way to do it
}
export const PieChartCard = ({
  data,
  valueType,
  chartTitle,
  subtitle,
  convert,
}: PieChartCardProps): ReactElement => {
  const total = useMemo(
    () =>
      data.reduce((acc, obj) => {
        return acc + obj.value;
      }, 0),
    [data],
  );

  const tagsData = useMemo(() => {
    if (valueType === 'percentage') {
      return data.map((obj) => ({
        ...obj,
        value: obj.value ? Math.round((obj.value / Number(total)) * 100) : 0,
      }));
    }

    if (valueType === 'coin') {
      return data.map((obj) => ({
        ...obj,
        value: obj.value ?? 0,
      }));
    }

    if (valueType === 'hours') {
      return data.map((obj) => ({
        ...obj,
        value: obj.value
          ? convertSecondsToTimeMinutesToHours(obj.value)?.hours
          : 0,
      }));
    }

    return data;
  }, [data, total, valueType]);

  const formattedTotal = useMemo(() => {
    if (valueType === 'hours') {
      return (
        convertSecondsToTimeMinutesToHours(total)?.hours?.toString() ?? '0h'
      );
    }
    if (convert) {
      return convertSecondsToTimeString(total) ?? '0h';
    }
    return Math.round(total).toString();
  }, [total, valueType, convert]);

  return (
    <Container className="my-2" title={chartTitle}>
      <div className="flex items-stretch justify-between gap-2">
        <div className="flex items-center justify-center self-stretch">
          <PieChart
            data={data}
            mainLabel={formattedTotal}
            subtitle={subtitle}
          />
        </div>
        <div className="flex flex-col items-stretch justify-center gap-1">
          <ul>
            {tagsData.map(({ name, value, fill }) => (
              <li key={name} className="mb-1">
                <Tag
                  className="w-45 items-stretch justify-between gap-2 rounded-sm"
                  style={{ backgroundColor: fill }}
                  size="medium"
                >
                  <span>{name}</span>
                  <span>
                    {value && valueType === 'coin'
                      ? roundToDecimal(value)
                      : value}
                    {ValueType[valueType]}
                  </span>
                </Tag>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};
