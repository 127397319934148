import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const KeycloakSession = () => {
  const minValidityInSeconds = 10;
  const { initialized, keycloak } = useKeycloak();

  useEffect(() => {
    const updateToken = async () => {
      if (initialized && keycloak) {
        await keycloak.updateToken(minValidityInSeconds);
      }
    };

    updateToken();
  }, [initialized, keycloak]);

  return null;
};

export default KeycloakSession;
