import parser, { CronExpression } from 'cron-parser';

export const parseCronExpression = (cronExpression: string): CronExpression => {
  let expression = cronExpression;
  const cronFields = expression.split(' ');

  if (cronFields.length === 7) {
    // Remove year
    expression = cronFields.slice(0, 6).join(' ');
  }

  return parser.parseExpression(expression);
};
