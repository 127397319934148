import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TasksQueryKey } from './types';
import { DataWithPagination } from '@/shared/types';
import { TaskModel } from '../domain';
import { tasksRepo } from '@/constants/providers';
import { SortDirection } from '@/components/Table/types';

export const useGetTasks = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<TaskModel[]>,
    any,
    DataWithPagination<TaskModel[]>
  >,
) =>
  useQuery<DataWithPagination<TaskModel[]>>(
    [
      TasksQueryKey.GET_ALL,
      {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      },
    ],
    () => tasksRepo.getTasks(pageNumber, filter, sortBy, sortOrder),
    options,
  );
