const getFirstLetters = (name: string, startFrom = 0): string => {
  const letters = name.replace(/[^a-z0-9]/gi, '').split('');

  return letters[startFrom] ?? '';
};

export function getFirstLastNameLetters(name: string): string {
  const names = name.split(/[\s._-]/gi);

  if (names.length === 1) {
    return getFirstLetters(names[0]).toUpperCase();
  }

  const firstLetter = getFirstLetters(names[0]);
  const lastLetter = getFirstLetters(names[names.length - 1]);

  return `${firstLetter}${lastLetter}`.toUpperCase();
}
