import { RoutePath } from '@/core/router';
import { useGetCurrentUser } from '@/modules/Users/queries';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCheckLogin = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = useGetCurrentUser(keycloak.token);

  useEffect(() => {
    if (!keycloak.authenticated) {
      if (location.pathname !== '/') {
        localStorage.setItem('redirectAfterLogin', location.pathname);
      }

      navigate(RoutePath.loadingPage());
    }
  }, [keycloak.authenticated, location, navigate]);

  return {
    isAuthenticated: keycloak.authenticated ?? false,
    user: data,
  };
};
