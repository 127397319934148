import { z } from 'zod';

export const AddTaskSchema = z.object({
  tasks: z.any().optional(),
});

export type FormAddTaskSchemaType = z.infer<typeof AddTaskSchema>;

export interface AddTaskSchemaType extends FormAddTaskSchemaType {
  id?: number;
}
