import { useQuery } from '@tanstack/react-query';
import { ProcessModel } from '../domain';
import { ProcessesQueryKey } from './types';
import { processesRepo } from '@/constants/providers';

export const useGetProcess = (id: string = '') =>
  useQuery<ProcessModel>(
    [ProcessesQueryKey.GET, id],
    () => processesRepo.getProcess(id),
    {
      enabled: !!id,
    },
  );
