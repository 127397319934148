import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UserModel } from '../domain';
import { UsersKeys } from './types';
import { usersRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { SortDirection } from '@/components/Table/types';

export const useGetUsers = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) =>
  useQuery<DataWithPagination<UserModel[]>>(
    [
      UsersKeys.GET_ALL,
      {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      },
    ],
    () => usersRepo.getAllUsers(pageNumber, filter, sortBy, sortOrder),
    options,
  );
