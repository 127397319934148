import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAutomationsColumns,
  getAutomationColumnsNamedMapped,
} from './utils';
import { TableRow, TableSort } from '@/components/Table/types';
import { useNavigate } from 'react-router-dom';
import { HttpError } from '@/core/http';
import { HttpStatusCode } from 'axios';
import { RoutePath } from '@/core/router';
import { CreateAutomationModal } from './components/CreateAutomationModal';
import { useGetAutomations } from '../../queries';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';

export const AutomationsPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();

  const handleModalState = () => {
    setIsOpen(!isOpen);
  };

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const {
    data: automations,
    isLoading: isLoadingAutomations,
    isError,
    error,
  } = useGetAutomations(
    page + 1,
    searchTerm,
    getAutomationColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
  );

  useEffect(() => {
    if (
      isError &&
      error &&
      (error as HttpError<unknown>)?.code === HttpStatusCode.NotFound
    ) {
      navigate(RoutePath.notFound());
    }
  }, [isError, error, navigate]);

  const routeChange = (row?: TableRow) => {
    if (!row) {
      return;
    }
    const path = `${row?.id}`;
    navigate(path);
  };

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('automations')}>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('add_automation')}
        </Button>
      </CustomPageContainer.Header>
      <FilterSearchBar
        total={automations?.totalRows || 0}
        title={t('automations')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      ></FilterSearchBar>
      <CreateAutomationModal handleClose={handleModalState} isOpen={isOpen} />
      <Table
        columns={getAutomationsColumns({ onClick: routeChange })}
        rows={automations?.items ?? []}
        isLoading={isLoadingAutomations}
        noResultsLabel={t('no_automations_available')}
        withPagination
        totalItems={automations?.totalRows}
        totalPages={automations?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </CustomPageContainer>
  );
};
