import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../Button';
import { twMerge } from 'tailwind-merge';

export const CustomDatePicker = ({
  selected,
  onChange,
  minDate,
  maxDate,
  disabled,
}: {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}) => {
  const CustomInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref) => (
      <Button
        className={twMerge(
          'align-left h-10 w-full justify-start rounded-md bg-brightGray italic text-primaryBlue',
          disabled && 'lowercase',
        )}
        onClick={onClick}
        buttonRef={ref as any}
        isDisabled={disabled}
      >
        {value || 'dd.mm.yyyy'}
      </Button>
    ),
  );

  CustomInput.displayName = 'CustomInput';

  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      dateFormat="d MMMM, yyyy"
      className="w-full bg-brightGray italic"
      customInput={<CustomInput />}
      wrapperClassName="w-full justify-left"
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
    />
  );
};
