import { useGetAutomation } from '@/modules/Automations/queries';
import { AutomationQueryKey } from '@/modules/Automations/queries/types';
import { useUpdateAutomation } from '@/modules/Automations/queries/update-automation';
import { AutomationSchemaType } from '@/modules/Automations/types/automation-schema';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AutomationModal } from '../AutomationModal';

interface EditAutomationModal {
  isOpen: boolean;
  id?: number;
  handleClose: () => void;
}

export const EditAutomationModal = ({
  isOpen,
  id,
  handleClose,
}: EditAutomationModal): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateAutomation();
  const { data: automation, isLoading: isLoadingGet } = useGetAutomation(
    id?.toString(),
  );

  const handleConfirm = (data: AutomationSchemaType) => {
    if (!automation) {
      return;
    }

    const convertedData = {
      id: data.id ?? automation.id,
      name: data.automationName ?? automation.name,
      description: data.description ?? automation.description,
      developerId: Number(data.developerId) ?? automation.developerId,
      stage: data.stageId ?? automation.stageId.toString(),
      priority: data.priority ?? automation.priority,
      epicId: data.epicId ?? automation.epicId,
      orchestratorProcessId:
        data.orchestratorProcessId ?? automation.orchestratorProcessId,
      status: true,
      processId: Number(data.processId) ?? automation.processId,
      businessAreaId: Number(data.businessAreaId) ?? automation.businessAreaId,
      code: automation.code,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_automation'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([AutomationQueryKey.ById]);
        handleClose();
        toast.success(t('success_update_automation'));
      },
    });
  };

  return (
    <AutomationModal
      type="edit"
      handleOnSubmit={handleConfirm}
      handleClose={handleClose}
      title={t('edit_automation')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingGet}
      automationName={automation?.name}
      automationCode={automation?.code}
      description={automation?.description}
      developerId={automation?.developerId}
      stageId={automation?.stageId}
      priority={automation?.priority}
      organizationId={automation?.organizationId}
      companyId={automation?.companyId}
      businessAreaId={automation?.businessAreaId}
      processId={automation?.processId}
      workflowId={automation?.workflowId}
      epicId={automation?.epicId}
      orchestratorProcessId={automation?.orchestratorProcessId}
    />
  );
};
