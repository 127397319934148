import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OrganizationModal } from '../OrganizationModal';
import { useGetOrganization } from '@/modules/Organizations/queries/get-organization';
import { useUpdateOrganization } from '@/modules/Organizations/queries/update-organization';
import { useQueryClient } from '@tanstack/react-query';
import { OrganizationKeys } from '@/modules/Organizations/queries/types';
import { OrganizationSchemaType } from '@/modules/Organizations/types';

interface EditOrganizationModalProps {
  isOpen: boolean;
  organizationId?: number;
  handleClose: () => void;
}

export const EditOrganizationModal = ({
  isOpen,
  organizationId,
  handleClose,
}: EditOrganizationModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateOrganization();
  const { data: organization, isLoading: isLoadingGet } = useGetOrganization(
    organizationId?.toString(),
  );

  const handleConfirm = (data: OrganizationSchemaType) => {
    if (!organizationId) {
      return toast.error(t('generic_errors'));
    }

    mutate(
      {
        id: data.id ?? organizationId,
        description: data.organizationDescription,
        name: data.organizationName,
        status: 1,
      },
      {
        onError: () => {
          toast.error(t('error_update_organization'));
        },
        onSuccess: () => {
          queryClient.invalidateQueries([OrganizationKeys.GET]);
          queryClient.invalidateQueries([OrganizationKeys.GET_ALL]);
          handleClose();
          toast.success(t('success_update_organization'));
        },
      },
    );
  };

  return (
    <OrganizationModal
      handleClose={handleClose}
      type="edit"
      handleOnSubmit={handleConfirm}
      title={t('edit_organization')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingGet}
      name={organization?.name}
      description={organization?.description}
    />
  );
};
