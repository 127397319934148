import { ClassNameValue } from 'tailwind-merge';
import { TableVariant, Alignment } from '../../types';

export const variantStyles: Record<TableVariant, ClassNameValue> = {
  small: 'text-endingNavyBlue',
  medium: 'text-spartanBlue',
};

export const alignStyles: Record<Alignment, ClassNameValue> = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};
