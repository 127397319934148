import { get } from 'lodash';
import { ReactElement } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { TableColumn, TableRow, TableVariant } from '../../types';
import { alignStyles } from './styles';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { HandlerIcon } from '@/components/Icons';
interface TableRowCellProps<R extends TableRow> {
  row: R;
  cols: Array<TableColumn<R>>;
  variant?: TableVariant;
  noOutline?: boolean;
  selected?: boolean;
  draggable?: boolean;
  onRowClick?: (row: R) => void;
  hasAction?: boolean;
  highlightRow?: boolean;
  disableRow?: boolean;
}

export const TableRowCell = <R extends TableRow>({
  row,
  cols,
  variant,
  noOutline,
  selected,
  onRowClick,
  draggable,
  hasAction,
  highlightRow = false,
  disableRow = false,
}: TableRowCellProps<R>): ReactElement => {
  const handleOnRowClick = () => onRowClick?.(row);

  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    backgroundColor: 'bg-lightPurple',
  };

  return (
    <>
      {cols.map((column, index) => {
        const value = get(row, column.acessorKey ?? column.id);
        return (
          <td
            key={`${column.id}-item-${row.id ?? ''}`}
            className={twMerge(
              'group/cell border-b-2.5 border-transparent',
              column.className,
            )}
            align={column.align ?? 'center'}
          >
            <div
              key={`${column.id}-row-${row.id ?? ''}`}
              ref={setNodeRef}
              style={style}
              className={twMerge(
                'inline-flex h-11.25 w-full items-center overflow-hidden bg-white',
                `text-sm text-primaryBlue group-first/cell:rounded-l-lg group-last/cell:rounded-r-lg`,
                !noOutline &&
                  `border-b-1 border-t-1 border-rinseGray group-first/cell:border-l-1 group-last/cell:border-r-1`,
                selected &&
                  `border-b-1 border-t-1 border-shinyBlue group-first/cell:border-l-1 group-last/cell:border-r-1`,
                index == 0 && 'ml-2 rounded-l-lg border-l-1',
                !column.cellSeparator && 'px-2.5',
                variant === 'small' && 'h-8.75',
                !onRowClick && 'cursor-default',
                highlightRow && 'bg-lightPurple',
                disableRow && 'cursor-not-allowed opacity-50',
                hasAction &&
                  'group-[:nth-last-child(2)]/cell:rounded-r-lg group-[:nth-last-child(2)]/cell:border-r-1',
              )}
              onClick={handleOnRowClick}
              tabIndex={0}
              role="button"
            >
              {column.cellSeparator && (
                <span className="h-[60%] border-l-1 border-spartanBlue" />
              )}
              <div
                className={twJoin(
                  'inline-flex w-[inherit] whitespace-nowrap',
                  column.align ? alignStyles[column.align] : 'justify-center',
                )}
              >
                {column.render ? column.render(value, row) : value}
              </div>
              {draggable && cols.length === index + 1 && (
                <span
                  className="flex cursor-pointer"
                  {...attributes}
                  {...listeners}
                >
                  <HandlerIcon /> {/* TODO: Improve styles for the handler */}
                </span>
              )}
            </div>
          </td>
        );
      })}
    </>
  );
};
