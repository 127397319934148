import { Button, FilterSearchBar, Table } from '@/components';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { CreateTaskModal } from '@/modules/Tasks/pages/TasksPage/components/CreateTaskModal';
import { TaskSchemaType } from '@/modules/Tasks/types';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  periodColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface TasksTabProps {
  canCreate?: boolean;
  disableFields?: (keyof TaskSchemaType)[];
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
}

export const TasksTab = ({
  canCreate,
  disableFields,
  organizationId,
  companyId,
  businessAreaId,
}: TasksTabProps): ReactElement => {
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const navigate = useNavigate();

  const {
    data: tasks,
    isLoading: isLoadingTasks,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/tasks/${row?.id}`;
    navigate(path);
  };

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={tasks?.totalRows || 0}
          title={t('tasks')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        {canCreate && (
          <div className="flex items-center">
            <Button onClick={handleModalState} variant="primary">
              {t('add_tasks')}
            </Button>
          </div>
        )}
      </div>
      <CreateTaskModal
        isOpen={isOpen}
        handleClose={handleModalState}
        organizationId={organizationId}
        disableFields={disableFields}
        businessAreaId={businessAreaId}
        companyId={companyId}
      />
      <Table
        onRowClick={routeChange}
        columns={[
          nameWithAvatarColumnConfig({}),
          codeColumnConfig(),
          businessAreaNameColumnConfig(),
          statusColumnConfig(),
          usersCountColumnConfig(),
          periodColumnConfig(),
          workloadColumnConfig(),
          estimatedCostColumnConfig(),
          automatedColumnConfig(),
        ]}
        rows={tasks?.items ?? []}
        isLoading={isLoadingTasks}
        noResultsLabel={t('no_tasks_available')}
        withPagination
        totalItems={tasks?.totalRows}
        totalPages={tasks?.totalPages}
        currentPage={page}
        onSort={onSort}
        onPageChange={onPageChange}
      />
    </>
  );
};
