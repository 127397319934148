import { usersRepo } from '@/constants/providers';
import { QueryOptions } from '@/modules/types';
import { useQuery } from '@tanstack/react-query';
import { UserProfilesModel } from '../domain/user-profiles-model';
import { UsersKeys } from './types';

export const useGetUserProfiles = (
  options?: QueryOptions<UserProfilesModel[]>,
) =>
  useQuery<UserProfilesModel[]>(
    [UsersKeys.GET_USERS_PROFILES],
    () => usersRepo.getUserProfiles(),
    {
      ...options,
    },
  );
