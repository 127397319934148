import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useGetCompanies } from '../../queries';
import { CreateCompanyModal } from './components/CreateCompanyModal';
import { getCompaniesColumns, getCompaniesColumnsNamedMapped } from './utils';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';

export const CompaniesPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = () => {
    setIsOpen(!isOpen);
  };
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();

  const getAllCompaniesOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_companies'));
      },
    }),
    [t],
  );

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const { data: companies, isLoading: isLoadingCompanies } = useGetCompanies(
    page + 1,
    searchTerm,
    getCompaniesColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
    getAllCompaniesOptions,
  );

  const routeChange = useCallback(
    (row?: TableRow) => {
      const path = `${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('companies')}>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('add_company')}
        </Button>
      </CustomPageContainer.Header>
      <FilterSearchBar
        total={companies?.totalRows || 0}
        title={t('companies')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />
      <CreateCompanyModal handleClose={handleModalState} isOpen={isOpen} />
      <Table
        columns={getCompaniesColumns({ onClick: routeChange })}
        rows={companies?.items ?? []}
        isLoading={isLoadingCompanies}
        noResultsLabel={t('no_companies_available')}
        withPagination
        totalItems={companies?.totalRows}
        totalPages={companies?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </CustomPageContainer>
  );
};
