import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type WorkflowNameColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onClick?: (row?: T) => void;
};

export const workflowNameColumnConfig = <T extends TableRow>({
  options,
  onClick,
}: WorkflowNameColumnConfig<T>): TableColumn<T> => ({
  id: 'name',
  label: <Trans i18nKey="workflow_name" />,
  render: (value, row) => (
    <div
      className="cursor-pointer overflow-hidden text-ellipsis font-black"
      onClick={() => onClick?.(row)}
    >
      {value}
    </div>
  ),
  acessorKey: 'name',
  sortable: true,
  align: 'left',
  alignHeader: 'left',
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
