import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { TableSort } from '../Table/types';

export const FilterAndPaginationContext = createContext<{
  searchTerm: string;
  page: number;
  tableSort: TableSort[];
  onSort: (data: TableSort[]) => void;
  onPageChange: (pageNumber: number) => void;
  handleSearchChange: (value: string) => void;
  reset: () => void;
}>({
  searchTerm: '',
  page: 0,
  tableSort: [],
  onPageChange: () => {},
  handleSearchChange: () => {},
  onSort: () => {},
  reset: () => {},
});

export const TableWithFilter = ({
  children,
}: {
  children?: ReactNode | ReactElement;
}): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const onPageChange = useCallback((pageNumber: number) => {
    setPage(pageNumber);
  }, []);

  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm('all=' + value);
  }, []);

  const reset = useCallback(() => {
    setSearchTerm('');
    setPage(0);
    setTableSort([]);
  }, []);

  return (
    <FilterAndPaginationContext.Provider
      value={{
        tableSort,
        onSort: setTableSort,
        searchTerm,
        page,
        onPageChange,
        handleSearchChange,
        reset,
      }}
    >
      {children}
    </FilterAndPaginationContext.Provider>
  );
};
