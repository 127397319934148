import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type CompanyNameColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const companyNameColumnConfig = <T extends TableRow>({
  options,
}: CompanyNameColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'company',
  label: <Trans i18nKey="company" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{value}</div>
  ),
  acessorKey: 'company',
  sortable: true,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
