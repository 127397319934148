import { z } from 'zod';

export const ChangePasswordSchema = z
  .object({
    newPassword: z
      .string({ required_error: 'error_field_required' })
      .min(1, { message: 'error_field_required' }),
    confirmPassword: z
      .string({ required_error: 'error_field_required' })
      .min(1, { message: 'error_field_required' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  });

export type FormChangePasswordSchemaType = z.infer<typeof ChangePasswordSchema>;

export interface ChangePasswordSchemaType extends FormChangePasswordSchemaType {
  id?: number;
}
