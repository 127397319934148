import {
  RowValue,
  SortDirection,
  TableColumn,
  TableRow,
  TableSort,
} from '../types';

type SortRules = {
  [key in SortDirection]: (a: RowValue, b: RowValue) => number;
};

export const sortRules: SortRules = {
  ascending: (a: RowValue, b: RowValue) => {
    if (!a || !b) {
      return 0;
    }
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b);
    }
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
    return 0;
  },
  descending: (a: RowValue, b: RowValue) => {
    if (!a || !b) {
      return 0;
    }
    if (typeof a === 'string' && typeof b === 'string') {
      return b.localeCompare(a);
    }

    if (typeof a === 'number' && typeof b === 'number') {
      return b - a;
    }
    return 0;
  },
};

export const applySortRules = (
  sort: SortDirection,
  a: TableRow,
  b: TableRow,
  acessorKey = '',
) => {
  const sortRule = sortRules[sort];
  return sortRule?.(a[acessorKey], b[acessorKey]);
};

export const sortTableRows = <R extends TableRow>(
  tableRows: Array<R>,
  sortConfig: Array<TableSort>,
  tableColumnsConfig: Array<TableColumn<R>>,
) => {
  return [...tableRows].sort((a, b) => {
    let compareResult = 0;

    sortConfig.every((sort) => {
      const column = tableColumnsConfig.find((c) => c.id === sort.columnId);

      if (!column) {
        return true;
      }

      const sortResult = column?.sortFn
        ? column.sortFn(a, b, sort.direction)
        : applySortRules(sort.direction, a, b, column.acessorKey);

      if (sortResult !== 0) {
        compareResult = sortResult;
      }

      return !(sortResult !== 0);
    });
    return compareResult;
  });
};
