import { TableVariant } from '../../types';

interface NoDataCellProps {
  variant: TableVariant;
  noResultsLabel?: string;
  colSpan: number;
}

export const NoDataCell = ({
  variant,
  noResultsLabel,
  colSpan,
}: NoDataCellProps): JSX.Element => {
  return (
    <tr>
      {variant === 'small' ? (
        <td colSpan={colSpan}>
          <div className="flex h-10 items-center justify-center rounded-lg bg-white text-xs italic">
            {noResultsLabel}
          </div>
        </td>
      ) : (
        <td colSpan={colSpan} className="py-8 text-center text-md">
          {noResultsLabel}
        </td>
      )}
    </tr>
  );
};
