import { SortDirection } from '@/components/Table/types';
import { processesRepo } from '@/constants/providers';
import { ProcessModel } from '@/modules/Processes/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProcessesQueryKey } from './types';

export const useGetProcessesByBusinessArea = (
  businessAreaId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<ProcessModel[]>,
    any,
    DataWithPagination<ProcessModel[]>
  >,
) =>
  useQuery<DataWithPagination<ProcessModel[]>>(
    [
      ProcessesQueryKey.GET_PROCESSES_BY_BUSINESS_AREA,
      { businessAreaId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      processesRepo.getProcessesByBusinessArea(
        businessAreaId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

export const useGetAllProcessesByBusinessArea = (businessAreaId: number) =>
  useQuery<ProcessModel[]>(
    [ProcessesQueryKey.GET_ALL_PROCESSES_BY_BUSINESS_AREA, { businessAreaId }],
    () => processesRepo.getAllProcessesByBusinessArea(businessAreaId),
    {
      enabled: !!businessAreaId,
    },
  );
