import { useCheckLogin } from './hooks/use-check-login.hook';
import { RouterProvider } from './core/contexts';
import { RouterMap } from './core/components/RouterMap';
import { routerConfiguration } from './router-configuration';
import { RoutePath } from './core/router';

export const App = () => {
  return (
    <RouterProvider
      checkLoginStatus={useCheckLogin}
      unauthorizedRedirectTo={RoutePath.loadingPage()}
    >
      <RouterMap routes={routerConfiguration} />
    </RouterProvider>
  );
};
