import { ReactNode } from 'react';

export type Alignment = 'left' | 'center' | 'right';
export type TableVariant = 'small' | 'medium';

export type RowValue = string | number | boolean | any;

export interface TableRow {
  id: string | number;
  [key: string]: RowValue;
}

export interface TablePaginationProps {
  totalItems: number;
  pageIndex: number;
  totalPages: number;
  nextDisabled: boolean;
  visiblePages?: number;
  prevDisabled: boolean;
  onPageChange: (page: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
}

export interface TableColumn<R extends TableRow> {
  id: string;
  acessorKey?: string;
  label: ReactNode;
  align?: Alignment;
  alignHeader?: Alignment;
  render?: (value: RowValue, row: R) => ReactNode;
  sortable?: boolean;
  cellSeparator?: boolean;
  columnSeparator?: boolean;
  sortFn?: (a: R, b: R, sortDirection: SortDirection) => number;
  className?: string;
  key?: string;
}

export type SortDirection = 'ascending' | 'descending';
export enum EnumSortDirection {
  'ascending' = 'ascending',
  'descending' = 'descending',
}

export interface TableSort {
  columnId: string;
  direction: SortDirection;
}

export interface SortProps {
  sorting?: TableSort[];
  onSort?: (columnId: string) => void;
}
export const DEFAULT_PAGE_SIZE = 10;
