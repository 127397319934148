import { SortDirection } from '@/components/Table/types';
import { organizationsRepo } from '@/constants/providers';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { OrganizationKeys } from './types';

export const useGetOrganizationsByUser = (
  userId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<OrganizationModel[]>,
    any,
    DataWithPagination<OrganizationModel[]>
  >,
) =>
  useQuery<DataWithPagination<OrganizationModel[]>>(
    [
      OrganizationKeys.GET_ORGANIZATIONS_BY_USER,
      { userId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      organizationsRepo.getOrganizationsByUser(
        userId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
