import { PieChartCard } from '@/components/PieChartCard';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SuccessRate,
  TimeDistribution,
} from '@/core/domain/global-performance-model';
import { ProcessModel } from '@/modules/Processes/domain';
import { useGetChartColorsAndLabel } from '@/hooks';

interface ProcessPerformanceChartsProps {
  processPerformance?: ProcessModel;
}

export const ProcessPerformanceCharts = ({
  processPerformance,
}: ProcessPerformanceChartsProps): ReactElement => {
  const { t } = useTranslation();
  const { rateChart, processTimeDistributionChart } =
    useGetChartColorsAndLabel();

  const lastRunSuccessRateData = useMemo(
    () =>
      rateChart.map((chartData) => ({
        ...chartData,
        value:
          processPerformance?.lastRunSuccessRate[
            chartData.id as keyof SuccessRate
          ] ?? 0,
      })),
    [processPerformance, rateChart],
  );

  const overallSuccessRateData = useMemo(
    () =>
      rateChart.map((chartData) => ({
        ...chartData,
        value:
          processPerformance?.overallSuccessRate[
            chartData.id as keyof SuccessRate
          ] ?? 0,
      })),
    [processPerformance, rateChart],
  );

  const processTimeDistributionData = useMemo(
    () =>
      processTimeDistributionChart.map((chartData) => ({
        ...chartData,
        value:
          processPerformance?.processTimeDistribution[
            chartData.id as keyof TimeDistribution
          ] ?? 0,
      })),
    [processPerformance, processTimeDistributionChart],
  );

  return (
    <div className="flex flex-col gap-0">
      <div className="flex flex-wrap gap-2">
        <PieChartCard
          data={processTimeDistributionData}
          chartTitle={t('process_time_distribution')}
          valueType="percentage"
          subtitle={t('total')}
          convert={true}
        />
        <PieChartCard
          data={lastRunSuccessRateData}
          chartTitle={t('last_run_success_rate')}
          valueType="percentage"
          subtitle={t('cases')}
        />
        <PieChartCard
          data={overallSuccessRateData}
          chartTitle={t('overall_success_rate')}
          valueType="percentage"
          subtitle={t('cases')}
        />
      </div>
    </div>
  );
};
