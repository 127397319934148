import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

export const businessOrganizationNameColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
): TableColumn<T> => ({
  id: 'organizationName',
  label: <Trans i18nKey="organization" />,
  render: (value) => {
    return <div className="overflow-hidden text-ellipsis">{value}</div>;
  },
  acessorKey: 'organizationName',
  align: 'left',
  alignHeader: 'left',
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
