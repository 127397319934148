import { Button, Dropdown, Input, Modal } from '@/components';
import {
  BusinessAreaSchema,
  BusinessAreaSchemaType,
} from '@/modules/BusinessAreas/types/businessArea-schema';
import InputWithError from '@/components/InputWithError/InputWithError';
import InputWithTitle from '@/components/InputWithTitle/InputWithTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';

interface BusinessAreaModalProps {
  title: string;
  handleOnSubmit: (e: BusinessAreaSchemaType) => void;
  handleClose: () => void;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  organizationId?: number;
  companyId?: number;
  businessAreaName?: string;
  businessAreaCode?: string;
  disableFields?: string[];
}

export const BusinessAreaModal = ({
  title,
  isOpen,
  isLoading,
  handleOnSubmit,
  handleClose,
  type,
  organizationId,
  companyId,
  businessAreaCode,
  businessAreaName,
  disableFields,
}: BusinessAreaModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    control,
    watch,
  } = useForm<BusinessAreaSchemaType>({
    defaultValues: useMemo(() => {
      return {
        organizationId: organizationId?.toString() || '',
        companyId: companyId?.toString() || '',
        businessAreaName: businessAreaName || '',
        businessAreaCode: businessAreaCode || '',
      };
    }, [organizationId, companyId, businessAreaName, businessAreaCode]),
    resolver: zodResolver(BusinessAreaSchema),
    mode: 'all',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: organizations } = useGetAllOrganizations();

  const organization = watch('organizationId');

  const { data: companies } = useGetAllCompaniesByOrganization(
    parseInt(organization),
  );

  const { dropdownOrganizationsOptions, dropdownCompaniesOptions } =
    useMemo(() => {
      return {
        dropdownOrganizationsOptions: getDropdownArray(organizations),
        dropdownCompaniesOptions: getDropdownArray(companies),
      };
    }, [organizations, companies]);

  useEffect(() => {
    clearErrors();

    setValue('organizationId', organizationId?.toString() || '');
    setValue('companyId', companyId?.toString() || '');
    setValue('businessAreaName', businessAreaName || '');
    setValue('businessAreaCode', businessAreaCode || '');
  }, [
    clearErrors,
    setValue,
    isOpen,
    organizationId,
    companyId,
    businessAreaName,
    businessAreaCode,
  ]);

  const onSubmit: SubmitHandler<BusinessAreaSchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }
    handleOnSubmit(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <InputWithTitle title={t('organization')}>
            <InputWithError
              errorText={
                errors.organizationId?.message
                  ? t(errors.organizationId.message)
                  : undefined
              }
              hasError={!!errors.organizationId?.message}
            >
              <Controller
                name="organizationId"
                control={control}
                rules={{ required: true }}
                defaultValue={'1'}
                render={({
                  field: { onChange, name, value, ...otherFieldProps },
                }) => (
                  <Dropdown
                    {...otherFieldProps.onBlur}
                    className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                    placeholder={t('organization')}
                    options={dropdownOrganizationsOptions}
                    onSelect={(val) => onChange(val.value)}
                    value={value}
                    disabled={disableFields && disableFields.includes(name)}
                  />
                )}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('company')}>
            <InputWithError
              errorText={
                errors.companyId?.message
                  ? t(errors.companyId.message)
                  : undefined
              }
              hasError={!!errors.companyId?.message}
            >
              <Controller
                name="companyId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, name, value, ...otherFieldProps },
                }) => (
                  <Dropdown
                    {...otherFieldProps.onBlur}
                    className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                    placeholder={t('company')}
                    options={dropdownCompaniesOptions}
                    onSelect={(val) => onChange(val.value)}
                    value={value}
                    disabled={disableFields && disableFields.includes(name)}
                  />
                )}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('business_area_name')}>
            <InputWithError
              errorText={
                errors.businessAreaName?.message
                  ? t(errors.businessAreaName.message)
                  : undefined
              }
              hasError={!!errors.businessAreaName?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray italic"
                placeHolder={t('business_area_name')}
                name="businessAreaName"
                register={register}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('business_area_code')}>
            <InputWithError
              errorText={
                errors.businessAreaCode?.message
                  ? t(errors.businessAreaCode.message)
                  : undefined
              }
              hasError={!!errors.businessAreaCode?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray italic"
                placeHolder={t('business_area_code')}
                name="businessAreaCode"
                register={register}
              />
            </InputWithError>
          </InputWithTitle>
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {type === 'create' ? t('create') : t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
