import { TableColumn, TableRow } from '@/components/Table/types';
import {
  averageCasesColumnConfig,
  idColumnConfig,
  runTimeColumnConfig,
  stageColumnConfig,
  statusColumnConfig,
  successColumnConfig,
} from '@/utils';
import { AutomationModel } from '../../domain';
import { businessAreaNameColumnConfig } from '@/utils';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { automationsNameColumnConfig } from '@/utils/tableColumns/automations-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';

type AutomationsColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getAutomationsColumns = ({
  onClick,
}: AutomationsColumnsConfig): Array<TableColumn<AutomationModel>> => {
  return [
    automationsNameColumnConfig({ onClick }),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    stageColumnConfig(),
    statusColumnConfig(),
    idColumnConfig(),
    dividerColumnConfig(),
    runTimeColumnConfig(),
    averageCasesColumnConfig(),
    successColumnConfig(),
  ];
};

export const getAutomationColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    businessAreaName: 'BusinessAreaName',
    stage: 'Stage',
    status: 'Status',
    runTime: 'RunTime',
    averageCases: 'AverageCases',
    sucess: 'Sucess',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

export const AUTOMATION_PRIORITIES = [
  'very_low',
  'low',
  'medium',
  'high',
  'very_high',
];
