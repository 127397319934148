import { DataWithPagination, Pagination } from '../types';

export const mapPagination = (
  dataJson?: DataWithPagination<unknown>,
): Pagination => ({
  currentPage: dataJson?.currentPage || 0,
  hasNext: dataJson?.hasNext || false,
  hasPrevious: dataJson?.hasPrevious || false,
  rowsPerPage: dataJson?.rowsPerPage || 0,
  totalPages: dataJson?.totalPages || 0,
  totalRows: dataJson?.totalRows || 0,
});
