import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_PAGE_SIZE,
  EnumSortDirection,
  TableColumn,
  TableRow,
  TableSort,
} from '../types';
import { sortTableRows } from './utils';
import {
  UseTablePaginationReturn,
  useTablePagination,
} from './use-table-pagination.hook';

interface UseTableReturn<R extends TableRow> extends UseTablePaginationReturn {
  sortedData: R[];
  handleSort: (columnId: string) => void;
  sorts: TableSort[];
}

export function useTable<R extends TableRow>(
  rows: R[],
  columns: TableColumn<R>[],
  currentPage: number,
  totalPages: number,
  onPageChange?: (page: number) => void,
  withPagination?: boolean,
  sorting?: TableSort[],
  defaultSort?: TableSort,
  onSort?: (sorts: TableSort[]) => void,
  multipleSorts?: number,
): UseTableReturn<R> {
  const [sorts, setSorts] = useState<TableSort[]>(sorting ?? []);

  const {
    pageIndex,
    nextDisabled,
    prevDisabled,
    handleNextPage,
    handlePrevPage,
    handlePageChange,
  } = useTablePagination(currentPage, totalPages, onPageChange);

  const sortedData = useMemo<Array<R>>(() => {
    const startIndex = pageIndex * DEFAULT_PAGE_SIZE;
    const endIndex = startIndex + DEFAULT_PAGE_SIZE;

    if (!sorts.length || onSort) {
      return rows;
    }
    const sortedRows = sortTableRows(rows, sorts, columns);

    if (!withPagination) {
      return sortedRows;
    }

    return sortedRows.slice(startIndex, endIndex);
  }, [sorts, onSort, rows, columns, withPagination, pageIndex]);

  const handleSort = useCallback(
    (columnId: string) => {
      const sort = sorts.find((s) => s.columnId === columnId);

      if (!sort) {
        if (!multipleSorts) {
          setSorts(() => [
            { columnId, direction: EnumSortDirection.ascending },
          ]);
          return;
        }

        setSorts((last) => [
          ...last,
          { columnId, direction: EnumSortDirection.ascending },
        ]);
        return;
      }

      if (sort.direction === EnumSortDirection.descending) {
        setSorts((last) => last.filter((s) => s.columnId !== columnId));
        return;
      }

      setSorts((last) =>
        last.map((s) =>
          s.columnId === columnId
            ? { ...s, direction: EnumSortDirection.descending }
            : s,
        ),
      );
    },
    [sorts, multipleSorts],
  );

  const sortingState = useMemo(() => sorting ?? sorts ?? [], [sorting, sorts]);

  useEffect(() => {
    if (!sorts.length && defaultSort) {
      setSorts([defaultSort]);
    }

    onSort?.(sorts);
  }, [onSort, sorts, defaultSort]);

  return {
    sortedData,
    handleSort,
    sorts: sortingState,
    pageIndex,
    nextDisabled,
    prevDisabled,
    handleNextPage,
    handlePrevPage,
    handlePageChange,
  };
}
