import { twMerge } from 'tailwind-merge';

export const Spinner = ({
  className = 'h-12 w-12',
}: {
  className?: string;
}) => {
  return (
    <div className="flex items-center justify-center">
      <div
        className={twMerge(
          'loader rounded-full border-4 border-t-4 ease-linear',
          className,
        )}
      ></div>
    </div>
  );
};
