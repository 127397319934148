import { Button, Table } from '@/components';
import { TabContext } from '@/components/TabContainer';
import { t } from 'i18next';
import { useCallback, useContext, useMemo, useState } from 'react';
import { getUserRatesColumns } from '../../../UsersPage/utils';
import { roundToDecimal } from '@/utils/roundToDecimals';
import { CreateHourlyRateModal } from '../CreateHourlyRateModal';
import { EditHourlyRateModal } from '../EditHourlyRateModal';
import { UserRateModel } from '@/modules/Users/domain/user-rate-model';

export const HourlyRatesTab = ({
  canCreate = true,
  userId,
}: {
  canCreate?: boolean;
  userId: number;
}) => {
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);
  const [selectedHourlyRate, setSelectedHourlyRate] = useState<UserRateModel>();

  const {
    data: userRates,
    isLoading: isLoadingUserRates,
    isOpen,
    page,
    onPageChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const handleEditModalState = useCallback(
    (hourlyRateId: number) => {
      const hourlyRate = (userRates?.items as UserRateModel[]).find(
        (rate) => rate.id === hourlyRateId,
      );
      setSelectedHourlyRate(hourlyRate);
      setIsEditOpenModal(true);
    },
    [userRates],
  );

  const handleEditModalClose = useCallback(() => {
    setIsEditOpenModal(false);
    setSelectedHourlyRate(undefined);
  }, []);

  const lastChange = useMemo(() => {
    const date =
      userRates?.items[0]?.updatedAt ??
      userRates?.items[1]?.updatedAt ??
      userRates?.items[0]?.startDate ??
      new Date();

    return t('last_rate_changed', {
      date: new Date(date).toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
    });
  }, [userRates]);

  const minDate = useMemo(() => {
    if (userRates?.items?.[0]?.startDate) {
      const date = new Date(userRates?.items?.[0]?.startDate);
      return new Date(date.setDate(date.getDate() + 1));
    }
  }, [userRates]);

  const columnsToEdit = useMemo(() => {
    return (userRates?.items as UserRateModel[])
      ?.slice(0, 2)
      .map((column) => column.id);
  }, [userRates]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <span className="font-bold text-blueNuit">{t('hourly_rates')}</span>
        {canCreate && (
          <Button onClick={handleModalState} variant="primary">
            {t('add_hourly_rate')}
          </Button>
        )}
      </div>
      <CreateHourlyRateModal
        isOpen={isOpen}
        handleClose={handleModalState}
        userId={userId}
        minDate={minDate}
      />
      {selectedHourlyRate && (
        <EditHourlyRateModal
          isOpen={isOpenEditModal}
          handleClose={handleEditModalClose}
          userId={userId}
          rate={selectedHourlyRate?.value}
          jiraAccountId={selectedHourlyRate?.jiraAccountId}
          startDate={selectedHourlyRate?.startDate}
          endDate={selectedHourlyRate?.endDate}
          hourlyRateId={selectedHourlyRate.id}
        />
      )}
      <div className="flex flex-col">
        <h1>{roundToDecimal(userRates?.items[0]?.value || 0)}€</h1>
        <span className="text-xs text-primaryBlue">{lastChange}</span>
      </div>
      <Table
        columns={getUserRatesColumns({
          onClick: handleEditModalState,
          columnsToEdit: columnsToEdit,
        })}
        rows={userRates?.items ?? []}
        isLoading={isLoadingUserRates}
        noResultsLabel={t('no_rates_available')}
        withPagination
        totalItems={userRates?.totalRows}
        totalPages={userRates?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </>
  );
};
