import { round } from './math.util';

export const secondsToTimeDurationString = (seconds: number) => {
  const days = round(seconds / 86400);
  const hours = round(seconds / 3600);
  const minutes = round(seconds / 60, 0);

  if (Math.floor(hours / 100) > 0) {
    return `${days} d`;
  }

  if (Math.floor(minutes / 100) > 0) {
    return `${hours} h`;
  }

  return `${minutes} m`;
};
