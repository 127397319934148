import { ProtectedHttpClient } from '@/core/http';
import { HttpAutomationRepository } from '@/modules/Automations/repositories/http-automation.repository';
import { HttpBusinessAreaRepository } from '@/modules/BusinessAreas/repositories/http-businessArea.repository';
import { HttpCompanyRepository } from '@/modules/Companies/repositories/http-company.repository';
import { HttpOrganizationRepository } from '@/modules/Organizations/repositories/http-organization.repository';
import { HttpProcessRepository } from '@/modules/Processes/repositories/http-process.repository';
import { TaskRepository } from '@/modules/Tasks/repositories';
import { HttpTasksRepository } from '@/modules/Tasks/repositories/http-task.repository';
import { HttpUserRepository } from '@/modules/Users/repositories/http-user.repository';
import { HttpWorkflowRepository } from '@/modules/Workflows/repositories/http-workflow.repository';

// HTTP Clients
const automationsClient = new ProtectedHttpClient('automations');
const businessAreasClient = new ProtectedHttpClient('business-areas');
const companiesClient = new ProtectedHttpClient('companies');
const organizationsClient = new ProtectedHttpClient('organizations');
const usersClient = new ProtectedHttpClient('users');
const workflowsClient = new ProtectedHttpClient('workflows');
const processesClient = new ProtectedHttpClient('processes');
const tasksClient = new ProtectedHttpClient('tasks');

// Create repositories
export const automationsRepo = new HttpAutomationRepository(automationsClient);
export const businessAreasRepo = new HttpBusinessAreaRepository(
  businessAreasClient,
);
export const companiesRepo = new HttpCompanyRepository(companiesClient);
export const organizationsRepo = new HttpOrganizationRepository(
  organizationsClient,
);
export const usersRepo = new HttpUserRepository(usersClient);
export const workflowsRepo = new HttpWorkflowRepository(workflowsClient);
export const processesRepo = new HttpProcessRepository(processesClient);
export const tasksRepo: TaskRepository = new HttpTasksRepository(tasksClient);
