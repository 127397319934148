import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

export const dateColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
  title?: string,
): TableColumn<T> => ({
  id: options?.key || 'date',
  label: <Trans i18nKey={title || 'date'} />,
  render: (value) =>
    value ? (
      <div className="overflow-hidden text-ellipsis">
        {new Date(value).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}
      </div>
    ) : (
      '-'
    ),

  acessorKey: options?.key || 'date',
  align: 'left',
  alignHeader: 'left',
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
