export enum OrganizationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type OrganizationJson = {
  id?: number;
  name?: string;
  description?: string;
  organizationStatus?: { id: number; name: OrganizationStatus };
  usersCount?: number;
  companiesCount?: number;
  businessAreasCount?: number;
  tasksCount?: number;
  automationsCount?: number;
  processesCount?: number;
  workflowsCount?: number;
  predictedManualTime?: number;
  predictedAverageManualTime?: number;
  automationRunTime?: number;
  averageAutomationRunTime?: number;
  totalInvestment?: number;
  totalReturn?: number;
  averageCost?: number;
  averageManualCost?: number;
};

export interface PagedOrganizations {
  items: Array<OrganizationJson>;
  rowsPerPage: number;
  totalRows: number;
  totalPages: number;
  currentPage: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface ApiResponseOrganizations<T> {
  isSuccess: boolean;
  timestamp: string;
  data: {
    organizations: T;
  };
}

export interface ApiResponseOrganization<T> {
  isSuccess: boolean;
  timestamp: string;
  data: {
    organization: T;
  };
}

export interface EditOrganization {
  id: number;
  name: string;
  description?: string;
  status: number;
}
