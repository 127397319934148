import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProcessSchemaType } from '@/modules/Processes/types/process-schema';
import { ProcessesQueryKey } from '@/modules/Processes/queries';
import { ProcessModal } from '../ProcessModal';
import { useCreateProcess } from '@/modules/Processes/queries/create-process';
import { OrganizationKeys } from '@/modules/Organizations/queries/types';
import { CompanyKeys } from '@/modules/Companies/queries';
import { BusinessAreasQueryKey } from '@/modules/BusinessAreas/queries/types';

interface CreateProcessModalProps {
  isOpen: boolean;
  handleClose: () => void;
  organizationId?: number;
  disableFields?: string[];
  companyId?: number;
  businessAreaId?: number;
}

export const CreateProcessModal = ({
  isOpen,
  handleClose,
  organizationId,
  disableFields,
  companyId,
  businessAreaId,
}: CreateProcessModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateProcess();

  const handleConfirm = (data: ProcessSchemaType) => {
    const convertedData = {
      name: data.processName,
      description: data.description,
      businessAreaId: Number(data.businessAreaId),
      workflows: data.workflows.map((workflow: string) => Number(workflow)),
      ownerId: Number(data.processOwnerId),
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_process'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([ProcessesQueryKey.ALL]);
        queryClient.invalidateQueries([OrganizationKeys.GET]);
        queryClient.invalidateQueries([CompanyKeys.GET]);
        queryClient.invalidateQueries([BusinessAreasQueryKey.GET]);

        handleClose();
        toast.success(t('success_creating_process'));
      },
    });
  };

  return (
    <ProcessModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_process')}
      isOpen={isOpen}
      isLoading={isLoading}
      organizationId={organizationId}
      disableFields={disableFields}
      companyId={companyId}
      businessAreaId={businessAreaId}
    />
  );
};
