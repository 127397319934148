import { ApiResponse, DataWithPagination } from '@/shared/types';
import { UserModel } from '../domain';
import {
  mapUserJsonToUserModel,
  userProfilesMapper,
  userRatesWithPaginationMapper,
  usersMapper,
  usersWithPaginationMapper,
} from '../mappers';
import {
  ApiResponseUser,
  ApiResponseUserRates,
  ApiResponseUsers,
  ApiResponseUsersProfiles,
  ChangePasswordJson,
  CreateUserJson,
  EditUserJson,
  EditUserRoleJson,
  HourlyRateJson,
  PagedUserRates,
  PagedUsers,
  UserJson,
  UserProfilesJson,
} from '../types';
import { UserRepository } from './user.repository';
import { HttpClient } from '@/core/http/http-client';
import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { UserProfilesModel } from '../domain/user-profiles-model';
import { UserRateModel } from '../domain/user-rate-model';

export class HttpUserRepository extends UserRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getAllUsers(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<UserModel[]>> {
    const users = await this.client.get<ApiResponseUsers<PagedUsers>>({
      url: '/me/users',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return usersWithPaginationMapper(users);
  }

  async getAllUsersBaseInfo(searchTerm?: string): Promise<Array<UserModel>> {
    const users = await this.client.get<ApiResponseUsers<UserJson[]>>({
      url: 'base-info',
      query: {
        searchTerm,
      },
    });

    return usersMapper(users.data.users);
  }

  async getUser(userId: string): Promise<UserModel> {
    const userJson = await this.client.get<ApiResponseUser<UserJson>>({
      url: `${userId}`,
    });

    return mapUserJsonToUserModel(userJson?.data.user);
  }

  async getUserProfiles(): Promise<UserProfilesModel[]> {
    const data = await this.client.get<
      ApiResponseUsersProfiles<UserProfilesJson>
    >({
      url: `profile-types`,
    });
    return userProfilesMapper(data?.data.profileTypes);
  }

  enableUser = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableUser = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  addAccess = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/add-access`,
    });
  };

  removeAccess = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/remove-access`,
    });
  };

  createUser = async (
    userJson: CreateUserJson,
  ): Promise<ApiResponse<{ data: { userId: number } }>> => {
    return (await this.client.post({
      body: userJson,
    })) as ApiResponse<{ data: { userId: number } }>;
  };

  csvUserImport = async (data: FormData): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `csv-import`,
    });
  };

  async getUsersByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<UserModel[]>> {
    const users = await this.client.get<ApiResponseUsers<PagedUsers>>({
      url: `/business-areas/${businessAreaId}/users`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return usersWithPaginationMapper(users);
  }

  async getUsersByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<UserModel[]>> {
    const users = await this.client.get<ApiResponseUsers<PagedUsers>>({
      url: `/companies/${companyId}/users`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return usersWithPaginationMapper(users);
  }

  async getUsersByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<UserModel[]>> {
    const users = await this.client.get<ApiResponseUsers<PagedUsers>>({
      url: `/organizations/${organizationId}/users`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return usersWithPaginationMapper(users);
  }

  async getUsersByTask(
    taskId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<UserModel[]>> {
    const users = await this.client.get<ApiResponseUsers<PagedUsers>>({
      url: `/tasks/${taskId}/users`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return usersWithPaginationMapper(users);
  }

  updateUser = async (
    userJson: EditUserJson,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.put<ApiResponse<unknown>>(
      `${userJson.id}`,
      userJson,
    );
  };

  updateRole = async (
    userJson: EditUserRoleJson,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.patch<ApiResponse<unknown>>(
      `${userJson.id}/role`,
      userJson,
    );
  };

  async getCurrentUser(token?: string): Promise<UserModel> {
    const userJson = await this.client.get<ApiResponseUser<UserJson>>({
      url: `/me`,
      config: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    return mapUserJsonToUserModel(userJson?.data.user);
  }

  async getUserRate(
    userId: number,
  ): Promise<DataWithPagination<UserRateModel[]>> {
    const userRateJson = await this.client.get<
      ApiResponseUserRates<PagedUserRates>
    >({
      url: `${userId}/hourly-rates`,
    });
    return userRatesWithPaginationMapper(userRateJson);
  }

  createUserRate = async (
    userJson: HourlyRateJson,
  ): Promise<ApiResponse<{ data: { userId: number } }>> => {
    return (await this.client.post({
      url: `hourly-rate`,
      body: userJson,
    })) as ApiResponse<{ data: { userId: number } }>;
  };

  updateUserRate = async (
    data: HourlyRateJson,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.put<ApiResponse<unknown>>(`hourly-rate`, data);
  };

  changePassword = async (
    data: ChangePasswordJson,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.put<ApiResponse<unknown>>(`change-password`, data);
  };
}
