import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type CodeColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const codeColumnConfig = <T extends TableRow>({
  options,
}: CodeColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'code',
  label: <Trans i18nKey="code" />,
  acessorKey: 'code',
  sortable: true,
  ...options,
});
