import { useQuery } from '@tanstack/react-query';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowsQueryKey } from './types';
import { WorkflowModel } from '@/modules/Workflows/domain';

export const useGetAllWorkflowsByBusinessArea = (
  businessAreaId: number,
  withProcess: boolean = true,
) =>
  useQuery<WorkflowModel[]>(
    [WorkflowsQueryKey.GET_WOKFLOWS_BY_BUSINESS_AREA, { businessAreaId }],
    () =>
      workflowsRepo.getWorkflowsByBusinessAreaId(businessAreaId, withProcess),
    {
      enabled: !!businessAreaId,
    },
  );
