import { Modal } from '@/components';
import { TaskSchemaType } from '@/modules/Tasks/types';

import { ReactElement } from 'react';
import { TaskForm } from '../TaskForm';

interface TaskModalProps {
  handleOnSubmit: (e: TaskSchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  taskName?: string;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  workflowId?: number;
  taskDescription?: string;
  taskPeriodicity?: string;
  labourWorkload?: string;
  disableFields?: (keyof TaskSchemaType)[];
  labourWorkloadType?: string;
  averageCases?: number;
}

export const TaskModal = (props: TaskModalProps): ReactElement => {
  const { isOpen, title, handleClose } = props;
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      <TaskForm {...props} />
    </Modal>
  );
};
