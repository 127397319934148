import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type StatusColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const statusColumnConfig = <T extends TableRow>({
  options,
}: StatusColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'status',
  acessorKey: 'status',
  label: <Trans i18nKey="status" />,
  render: (value) =>
    typeof value == 'boolean' ? (
      <Trans i18nKey={value ? 'active' : 'inactive'} />
    ) : (
      <Trans i18nKey={value as string} />
    ),
  sortable: true,
  ...options,
});
