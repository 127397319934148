import {
  BusinessAreasTab,
  Button,
  ChevronLeft,
  CompaniesTab,
  CustomPageContainer,
  OrganizationsTab,
  ProcessesTab,
  TabItemProps,
  TableWithFilter,
  TasksTab,
} from '@/components';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { getBusinessAreaColumnsNamedMapped } from '@/modules/BusinessAreas/pages/BusinessAreasPage/utils';
import { useGetBusinessAreasByUser } from '@/modules/BusinessAreas/queries';
import { CompanyModel } from '@/modules/Companies/domain';
import { getCompaniesColumnsNamedMapped } from '@/modules/Companies/pages/CompaniesPage/utils';
import { useGetCompaniesByUser } from '@/modules/Companies/queries';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { getOrganizationsColumnsNamedMapped } from '@/modules/Organizations/pages/OrganizationsPage/utils';
import { useGetOrganizationsByUser } from '@/modules/Organizations/queries';
import { getProcessesColumnsNamedMapped } from '@/modules/Processes';
import { ProcessModel } from '@/modules/Processes/domain';
import { useGetProcessesByUser } from '@/modules/Processes/queries';
import { TaskModel } from '@/modules/Tasks/domain';
import { getTasksColumnsNamedMapped } from '@/modules/Tasks/pages/TasksPage';
import { useGetTasksByUser } from '@/modules/Tasks/queries/get-tasks-by-user';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserRateModel } from '../../domain/user-rate-model';
import {
  useDisableUser,
  useEnableUser,
  useGetUser,
  useGetUserRates,
} from '../../queries';
import { useAddAccess } from '../../queries/add-access';
import { useRemoveAccess } from '../../queries/remove-access';
import { UsersKeys } from '../../queries/types';
import { HeaderRow } from './components/HeaderRow';
import { HourlyRatesTab } from './components/HourlyRatesTab';

export const UserDetailPage = (): ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: user, isError, error } = useGetUser(userId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_user_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  const { mutate: enableUser } = useEnableUser();
  const { mutate: disableUser } = useDisableUser();
  const { mutate: addAccess } = useAddAccess();
  const { mutate: removeAccess } = useRemoveAccess();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!user?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableUser(user?.id, {
          onError: () => {
            toast.error(t('error_change_user_status'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_user'));
            queryClient.invalidateQueries([UsersKeys.GET]);
          },
        });
      } else {
        disableUser(user?.id, {
          onError: () => {
            toast.error(t('error_change_user_status'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_user'));
            queryClient.invalidateQueries([UsersKeys.GET]);
          },
        });
      }
    },
    [user, enableUser, disableUser, t],
  );

  const handleAccess = useCallback(
    (checked: boolean) => {
      if (!user?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        addAccess(user?.id, {
          onError: () => {
            toast.error(t('error_changing_access'));
          },
          onSuccess: () => {
            toast.success(t('success_add_access'));
            queryClient.invalidateQueries([UsersKeys.GET]);
          },
        });
      } else {
        removeAccess(user?.id, {
          onError: () => {
            toast.error(t('error_changing_access'));
          },
          onSuccess: () => {
            toast.success(t('success_remove_access'));
            queryClient.invalidateQueries([UsersKeys.GET]);
          },
        });
      }
    },
    [user, addAccess, removeAccess, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('organizations'), badgeNumber: user?.organizationsCount },
      {
        label: t('companies'),
        badgeNumber: user?.companiesCount,
      },
      {
        label: t('business-areas'),
        badgeNumber: user?.businessAreasCount,
      },
      { label: t('processes'), badgeNumber: user?.processesCount },
      { label: t('tasks'), badgeNumber: user?.tasksCount },
      { label: t('hourly_rates') },
    ],
    [t, user],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!userId) {
      return [];
    }
    return [
      {
        tab: (
          <TabContainer<OrganizationModel>
            parentId={parseInt(userId)}
            useGetData={useGetOrganizationsByUser}
            getColumnsNamedMapped={getOrganizationsColumnsNamedMapped}
          >
            <OrganizationsTab canAttachUser canCreate={false} userId={userId} />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<CompanyModel>
            parentId={parseInt(userId)}
            useGetData={useGetCompaniesByUser}
            getColumnsNamedMapped={getCompaniesColumnsNamedMapped}
          >
            <CompaniesTab canAttachUser canCreate={false} userId={userId} />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<BusinessAreaModel>
            parentId={parseInt(userId)}
            useGetData={useGetBusinessAreasByUser}
            getColumnsNamedMapped={getBusinessAreaColumnsNamedMapped}
          >
            <BusinessAreasTab canAttachUser canCreate={false} userId={userId} />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<ProcessModel>
            parentId={parseInt(userId)}
            useGetData={useGetProcessesByUser}
            getColumnsNamedMapped={getProcessesColumnsNamedMapped}
          >
            <ProcessesTab canAttachUser canCreate={false} userId={userId} />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<TaskModel>
            parentId={parseInt(userId)}
            useGetData={useGetTasksByUser}
            getColumnsNamedMapped={getTasksColumnsNamedMapped}
          >
            <TasksTab canCreate />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<UserRateModel>
            parentId={parseInt(userId)}
            useGetData={useGetUserRates}
          >
            <HourlyRatesTab canCreate userId={parseInt(userId)} />
          </TabContainer>
        ),
      },
    ];
  }, [userId]);

  return (
    <CustomPageContainer>
      <div>
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        <p className="mt-3 text-xs">
          {t('users')} /{' '}
          <span className="font-black">
            {user?.firstName} {user?.lastName}
          </span>
        </p>
      </div>
      <HeaderRow
        user={user}
        handleStatus={handleStatus}
        handleAccess={handleAccess}
        status={status}
      />
      <TableWithFilter>
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TableWithFilter>
    </CustomPageContainer>
  );
};
