import { mapPagination } from '@/shared/mappers';
import { parseCronExpression } from '@/utils/parse-cron-expression';
import cronstrue from 'cronstrue';
import { AutomationModel, FileModel, SchedulingModel } from '../domain';
import {
  ApiResponseAutomations,
  AutomationJson,
  FileJson,
  PagedAutomations,
} from '../types';

export const automationMapper = (
  automationJson: AutomationJson,
): AutomationModel => {
  const mapFiles = (files?: Array<FileJson>): Array<FileModel> => {
    return files
      ? files.map((file) => ({
          id: file.id ?? '',
          name: file.name ?? '',
          url: file.url ?? '',
          automationFileId: file.automationFileId ?? '',
        }))
      : [];
  };

  const mapScheduling = (
    scheduleCronExpression?: string,
    lastRun?: string,
  ): SchedulingModel => {
    const lastRunDate = lastRun ? new Date(lastRun) : undefined;
    if (!scheduleCronExpression) {
      return { lastRun: lastRunDate, schedule: '' };
    }
    const expression = parseCronExpression(scheduleCronExpression);

    return {
      lastRun: lastRunDate,
      nextRun: expression.next().toDate(),
      schedule: cronstrue.toString(scheduleCronExpression),
    };
  };

  return {
    id: automationJson.id ?? 0,
    stage: automationJson?.stage ?? '',
    stageId: automationJson.stageId ?? 0,
    version: automationJson.version ?? '',
    developer: automationJson.developer ?? '',
    epicId: automationJson.epicId ?? '',
    name: automationJson.name ?? '',
    description: automationJson.description ?? '',
    scheduling: mapScheduling(
      automationJson.scheduleCronExpression,
      automationJson.lastRun,
    ),
    organizationId: automationJson.organizationId ?? 0,
    organization: automationJson.organization ?? '',
    companyId: automationJson.companyId ?? 0,
    company: automationJson.company ?? '',
    workflowId: automationJson.workflowId ?? 0,
    processId: automationJson.processId ?? 0,
    businessAreaId: automationJson.businessAreaId ?? 0,
    businessAreaName: automationJson.businessAreaName ?? '',
    status: automationJson.status ?? false,
    averageCases: automationJson.averageCases ?? 0,
    pdds: mapFiles(automationJson?.pdds),
    sdds: mapFiles(automationJson?.sdds),
    manuals: mapFiles(automationJson?.manuals),
    supportDocuments: mapFiles(automationJson?.supportDocuments),
    code: automationJson.code ?? '',
    priority: automationJson.priority ?? '',
    automationFileId: automationJson.automationFileId ?? 0,
    orchestratorProcessId: automationJson.orchestratorProcessId ?? '',
    runTime: automationJson.runTime ?? '',
    developerId: automationJson.developerId ?? 0,
    tasks: automationJson?.tasks ?? [],
    success: automationJson.success ?? 0,
  };
};

export const automationsMapper = (
  automationsJson: Array<AutomationJson>,
): Array<AutomationModel> => automationsJson.map(automationMapper);

export const automationsWithPaginationMapper = (
  automationsJson: ApiResponseAutomations<PagedAutomations>,
) => {
  const { data } = automationsJson;
  return {
    items: automationsMapper(data.automations.items),
    ...mapPagination(data.automations),
  };
};
