import { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { SortProps, TableColumn, TableRow, TableVariant } from '../../types';
import { alignStyles, variantStyles } from './styles';
import { TableHeadSortableCell } from '../TableHeadSortableCell';

interface TableHeadCellProps<R extends TableRow> extends SortProps {
  cell: TableColumn<R>;
  variant: TableVariant;
}

export const TableHeadCell = <R extends TableRow>({
  cell,
  variant,
  onSort,
  sorting,
}: TableHeadCellProps<R>): ReactElement => {
  return (
    <th
      scope="col"
      className={twMerge(
        'sticky top-0 z-2 border-b-2.5 border-transparent bg-inherit px-2.5 text-xs font-black leading-xs',
        variantStyles[variant],
        cell.columnSeparator && 'with-divider',
        cell.className,
      )}
    >
      <div
        className={twMerge(
          'relative inline-flex w-full items-center',
          alignStyles[cell.alignHeader || 'center'],
        )}
      >
        {cell.sortable ? (
          <TableHeadSortableCell
            cellId={cell.id}
            label={cell.label}
            onSort={onSort}
            sorting={sorting}
            variant={variant}
          />
        ) : (
          cell.label
        )}
      </div>
    </th>
  );
};
