import { mapPagination } from '@/shared/mappers';
import { TaskModel } from '../domain';
import { ApiResponseTasks, PagedTasks, TaskJson } from '../types/task-json';

export const taskMapper = (taskJson: TaskJson): TaskModel => ({
  id: taskJson?.id ?? 0,
  name: taskJson?.name ?? '',
  description: taskJson?.description ?? '',
  code: taskJson?.code ?? '',
  businessAreaId: taskJson?.businessAreaId ?? 0,
  businessAreaName: taskJson?.businessAreaName ?? '',
  workflowName: taskJson?.workflowName ?? '',
  workflowId: taskJson?.workflowId ?? 0,
  company: taskJson?.company ?? '',
  companyId: taskJson?.companyId ?? 0,
  organization: taskJson?.organization ?? '',
  organizationId: taskJson?.organizationId ?? 0,
  status: taskJson?.status ?? false,
  usersCount: taskJson?.usersCount ?? 0,
  automationsCount: taskJson?.automationsCount ?? 0,
  period: taskJson?.period ?? '',
  workload: taskJson?.workload ?? 0,
  estimatedCost: taskJson?.estimatedCost ?? 0,
  automation: taskJson?.automation ?? '',
  runTime: taskJson?.runTime ?? 0,
  averageCases: taskJson?.averageCases ?? 0,
  sucess: taskJson?.sucess ?? 0,
  supportDocuments:
    taskJson?.supportDocuments?.map((doc) => ({
      id: doc.id ?? '',
      name: doc.name ?? '',
      url: doc.url ?? '',
      taskFileId: doc.taskFileId ?? '',
    })) ?? [],
  order: taskJson?.order ?? 0,
  automationId: taskJson?.automationId,
  workloadTypeId: taskJson?.workloadTypeId ?? 0,
  workloadType: taskJson?.workloadType ?? '',
  predictedManualTime: taskJson?.predictedManualTime ?? 0,
  predictedAverageManualTime: taskJson?.predictedAverageManualTime ?? 0,
  automationRunTime: taskJson?.automationRunTime ?? 0,
  averageAutomationRunTime: taskJson?.averageAutomationRunTime ?? 0,
  totalInvestment: taskJson?.totalInvestment ?? 0,
  totalReturn: taskJson?.totalReturn ?? 0,
  averageCost: taskJson?.averageCost ?? 0,
  averageManualCost: taskJson?.averageManualCost ?? 0,
  workflow: taskJson?.workflow ?? '',
});

export const tasksWithPaginationMapper = (
  organizationJson: ApiResponseTasks<PagedTasks>,
) => {
  const { data } = organizationJson;
  return {
    items: tasksMapper(data.tasks.items),
    ...mapPagination(data.tasks),
  };
};

export const tasksMapper = (tasksJson: Array<TaskJson>): Array<TaskModel> =>
  tasksJson.map(taskMapper);
