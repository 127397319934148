import { Button } from '@/components/Button';
import { ChevronLeft } from '@/components/Icons';
import { ReactElement } from 'react';
import { TablePaginationProps } from '../../types';

export const TablePagination = ({
  pageIndex,
  nextDisabled,
  prevDisabled,
  totalPages,
  //visiblePages = 3,
  onPrevPage,
  onPageChange,
  onNextPage,
}: TablePaginationProps): ReactElement => {
  //TODO: Implement pagination with visiblePages feature or delete the comment lines if not needed
  /*
  const startPage = Math.max(0, pageIndex - Math.floor(visiblePages / 2));
  const endPage = Math.min(
    totalPages,
    pageIndex === 0 ? startPage + visiblePages - 1 : startPage + visiblePages,
  );
*/
  const startPage = 0;

  const pages = Array.from(
    { length: totalPages - startPage },
    (_, i) => i + startPage,
  );

  return (
    <div className="flex w-full justify-center gap-1.25">
      <Button variant="gray" isDisabled={prevDisabled} onClick={onPrevPage}>
        <ChevronLeft />
      </Button>
      {pages.map((page) => {
        return (
          <Button
            className={pageIndex === page ? 'bg-primaryBlue text-white' : ''}
            key={page}
            variant="gray"
            onClick={() => onPageChange(page)}
          >
            {page + 1}
          </Button>
        );
      })}
      <Button variant="gray" isDisabled={nextDisabled} onClick={onNextPage}>
        <ChevronLeft className="rotate-180 transform" />
      </Button>
    </div>
  );
};
