import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CompanyKeys } from './types';
import { companiesRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { CompanyModel } from '../domain';
import { SortDirection } from '@/components/Table/types';

export const useGetCompanies = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<CompanyModel[]>,
    any,
    DataWithPagination<CompanyModel[]>
  >,
) =>
  useQuery<DataWithPagination<CompanyModel[]>>(
    [CompanyKeys.ALL, { pageNumber, filter, sortBy, sortOrder }],
    () => companiesRepo.getCompanies(pageNumber, filter, sortBy, sortOrder),
    options,
  );
