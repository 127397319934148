export enum UsersKeys {
  GET_ALL = 'get-users',
  GET = 'get-user',
  GET_BASE = 'get-users-base-info',
  GET_USERS_BY_BUSINESS_AREA = 'get-users-by-business-area',
  GET_USERS_BY_COMPANY = 'get-users-by-company',
  GET_USERS_BY_ORGANIZATION = 'get-users-by-organization',
  GET_USERS_BY_TASK = 'get-users-by-task',
  GET_USERS_PROFILES = 'get-users-profiles',
  GET_CURRENT_USER = 'get-current-user',
  GET_USER_RATES = 'get-user-rates',
}
