import { Button, Dropdown, Modal, SelectDropdownOption } from '@/components';
import { useGetUserProfiles } from '@/modules/Users/queries/get-user-profiles';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface AttachUserToEntityModalProps {
  entityType: 'organizations' | 'companies' | 'businessArea' | 'process';
  isOpen: boolean;
  isLoading: boolean;
  isConfirmDisabled: boolean;
  onHandleClose: () => void;
  onHandleConfirm: (roleId: string) => void;
}

export const AttachUserToEntityModal = ({
  entityType,
  isOpen,
  onHandleClose,
  onHandleConfirm,
  children,
  isLoading,
  isConfirmDisabled,
}: PropsWithChildren<AttachUserToEntityModalProps>): ReactElement => {
  const { t } = useTranslation();
  const [dropdownRoleValue, setDropdownRoleValue] = useState<string>('');

  const { data } = useGetUserProfiles();
  const roleDropdownOptions = useMemo(() => getDropdownArray(data), [data]);
  const handleConfirm = () => {
    onHandleConfirm(dropdownRoleValue);
  };

  const handleOnSelectRoleDropdownValue = useCallback(
    (option: SelectDropdownOption<string>) =>
      setDropdownRoleValue(option.value),
    [],
  );

  useEffect(() => {
    setDropdownRoleValue('');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('attach_entity', { entity: t(entityType) })}
      className="h-fit w-128"
      handleClose={onHandleClose}
    >
      <div className="mt-6 flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          {children}
          <div className="text-sm font-bold capitalize">{t('role')}</div>
          <Dropdown
            className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
            placeholder={t('select')}
            options={roleDropdownOptions}
            value={dropdownRoleValue}
            onSelect={handleOnSelectRoleDropdownValue}
          />
        </div>
        <div className="mt-8 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={onHandleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading || isConfirmDisabled || !dropdownRoleValue}
            onClick={handleConfirm}
            className="h-9 w-24"
          >
            {t('create')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
