import { Button, Dropdown, SelectDropdownOption } from '@/components';
import { AttachUserToEntityModal } from '@/components/AttachUserToEntityModal';
import { queryClient } from '@/constants';
import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useAttachUserBusinessArea } from '@/modules/BusinessAreas/queries/attach-user-businessArea';
import { BusinessAreasQueryKey } from '@/modules/BusinessAreas/queries/types';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { UsersKeys } from '@/modules/Users/queries/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface AttachUserToBusinessModalProps {
  userId: number;
}

export const AttachUserToBusinessModal = ({
  userId,
}: AttachUserToBusinessModalProps): ReactElement => {
  const { t } = useTranslation();
  const [dropdownOrganizationValue, setOrganizationDropdownEntityValue] =
    useState<string>('');
  const [dropdownCompanyValue, setDropdownCompanyValue] = useState<string>('');
  const [dropdownBusinessValue, setDropdownBusinessValue] =
    useState<string>('');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalState = () => setIsOpen(!isOpen);

  const { mutate, isLoading } = useAttachUserBusinessArea();

  const handleConfirm = (roleId: string) => {
    mutate(
      {
        userProfiles: [
          {
            userId: userId ?? 0,
            profileTypeId: parseInt(roleId),
          },
        ],
        businessAreaId: parseInt(dropdownBusinessValue),
      },
      {
        onError: () => {
          toast.error(
            t('error_attaching_user_to_entity', { entity: t('businessArea') }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries([
            BusinessAreasQueryKey.GET_BUSINESS_AREAS_BY_USER,
          ]);
          queryClient.invalidateQueries([UsersKeys.GET]);
          handleModalState();
          toast.success(
            t('success_attaching_user_to_entity', {
              entity: t('businessArea'),
            }),
          );
        },
      },
    );
  };

  const { data: organizationData } = useGetAllOrganizations();

  const { data: companiesData } = useGetAllCompaniesByOrganization(
    parseInt(dropdownOrganizationValue),
  );

  const { data: businessData } = useGetAllBusinessAreasByCompany(
    parseInt(dropdownCompanyValue),
  );

  const organizationDropdownOptions = useMemo(
    () => getDropdownArray(organizationData),
    [organizationData],
  );

  const companiesDropdownOptions = useMemo(
    () => getDropdownArray(companiesData),
    [companiesData],
  );

  const businessDropdownOptions = useMemo(
    () => getDropdownArray(businessData),
    [businessData],
  );

  const handleOnSelectOrganizationDropdownValue = useCallback(
    (option: SelectDropdownOption<string>) =>
      setOrganizationDropdownEntityValue(option.value),
    [],
  );

  const handleOnSelectCompanyDropdownValue = useCallback(
    (option: SelectDropdownOption<string>) =>
      setDropdownCompanyValue(option.value),
    [],
  );

  const handleOnSelectBusinessDropdownValue = useCallback(
    (option: SelectDropdownOption<string>) =>
      setDropdownBusinessValue(option.value),
    [],
  );

  useEffect(() => {
    setOrganizationDropdownEntityValue('');
  }, [isOpen]);

  useEffect(() => {
    setDropdownCompanyValue('');
  }, [dropdownOrganizationValue]);

  useEffect(() => {
    setDropdownBusinessValue('');
  }, [dropdownCompanyValue]);

  return (
    <>
      <Button onClick={handleModalState} variant="primary">
        {t('attach_entity', { entity: t('businessArea') })}
      </Button>
      <AttachUserToEntityModal
        isOpen={isOpen}
        isLoading={isLoading}
        entityType={'businessArea'}
        onHandleClose={handleModalState}
        onHandleConfirm={handleConfirm}
        isConfirmDisabled={!dropdownBusinessValue}
      >
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('organizations') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={organizationDropdownOptions}
          value={dropdownOrganizationValue}
          onSelect={handleOnSelectOrganizationDropdownValue}
        />
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('companies') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={companiesDropdownOptions}
          value={dropdownCompanyValue}
          onSelect={handleOnSelectCompanyDropdownValue}
        />
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('businessArea') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={businessDropdownOptions}
          value={dropdownBusinessValue}
          onSelect={handleOnSelectBusinessDropdownValue}
        />
      </AttachUserToEntityModal>
    </>
  );
};
