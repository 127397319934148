import { useQuery } from '@tanstack/react-query';
import { AutomationQueryKey } from './types';
import { AutomationModel } from '../domain';
import { QueryOptions } from '@/modules/types';
import { automationsRepo } from '@/constants/providers';

export const useGetAutomation = (
  id: string = '',
  options?: QueryOptions<AutomationModel>,
) =>
  useQuery<AutomationModel>(
    [AutomationQueryKey.ById, id],
    () => automationsRepo.getAutomation(id),
    {
      ...options,
      enabled: !!id,
    },
  );
