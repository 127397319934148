import { ApiResponse, DataWithPagination } from '@/shared/types';
import { CompanyModel } from '../domain';
import { CreateCompanyJson, EditCompanyJson } from '../types';

export abstract class CompanyRepository {
  abstract getCompanies(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<CompanyModel[]>>;
  abstract updateCompany(data: EditCompanyJson): Promise<CompanyModel>;
  abstract createCompany(data: CreateCompanyJson): Promise<CompanyModel>;
  abstract getCompany(id: string): Promise<CompanyModel>;
  abstract enableCompany(id: number): Promise<ApiResponse<unknown>>;
  abstract disableCompany(id: number): Promise<ApiResponse<unknown>>;
  abstract getCompaniesByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<CompanyModel[]>>;
  abstract getAllCompaniesByOrganization(
    organizationId: number,
  ): Promise<CompanyModel[]>;
  abstract getCompaniesByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<CompanyModel[]>>;
  abstract addRatesFile(data: FormData): Promise<unknown>;
}
