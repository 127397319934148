import { ReactNode, useCallback, useState } from 'react';
import { ArrowCollapse, HandlerIcon, UnLink } from '../Icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const Collapse = ({
  children,
  title,
  subText,
  defaultExpanded = true,
  draggable = false,
  action,
  id = 0,
  extraButton,
}: {
  children: ReactNode;
  title?: string;
  subText?: string;
  defaultExpanded?: boolean;
  draggable?: boolean;
  action?: (id: number) => void;
  id?: number;
  extraButton?: ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, []);

  return (
    <div
      className={`${isExpanded && "h-auto bg-[url('assets/icons/grey_dot.svg')] bg-repeat-y"} pb-5`}
      style={{ ...style, backgroundPositionX: '6px' }}
      ref={setNodeRef}
    >
      <div className="row-auto flex h-5 w-full cursor-pointer items-center gap-2">
        <div
          className="flex w-full flex-row items-center gap-2"
          onClick={toggleIsExpanded}
        >
          <ArrowCollapse
            className={`transition-all duration-300 ease-in-out ${isExpanded ? 'rotate-0' : 'rotate-180'}`}
          />
          <span className="text-sm font-bold">{title}</span>
          {subText && <span className="text-sm">{subText}</span>}
        </div>
        <div className="flex w-full items-center justify-end  gap-4">
          {extraButton && extraButton}
          {draggable && (
            <span
              className="flex cursor-pointer"
              {...attributes}
              {...listeners}
            >
              <HandlerIcon />
            </span>
          )}
          {action && (
            <span className="flex cursor-pointer" onClick={() => action(id)}>
              <UnLink />
            </span>
          )}
        </div>
      </div>

      <div
        className={`transition-height ml-5 mt-5 overflow-hidden duration-300 ease-in-out ${isExpanded ? 'h-auto' : 'h-0'}`}
      >
        {children}
      </div>
    </div>
  );
};
