import { ReactElement } from 'react';
import { LoadingCell } from '../LoadingCell';

interface LoadingRowProps {
  columnsCount?: number;
  rowIndex: number;
}

export const LoadingRow = ({
  rowIndex,
  columnsCount = 0,
}: LoadingRowProps): ReactElement => (
  <tr>
    {Array.from({
      length: columnsCount,
    }).map((__, colIndex) => (
      <td
        className="group text-center"
        key={`loading-row-${rowIndex}-col-${colIndex}`}
      >
        <LoadingCell />
      </td>
    ))}
  </tr>
);
