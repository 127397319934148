import { ReactElement } from 'react';
import {
  AutomationPerformanceCards,
  AutomationPerformanceCharts,
} from './components';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useParams } from 'react-router-dom';

export const AutomationPerformanceTab = (): ReactElement => {
  const { automationId } = useParams<{ automationId: string }>();
  const {
    data: automationPerformance,
    isLoading: isLoadingAutomationPerformance,
  } = useGetAutomationPerformance(automationId);

  return (
    <div>
      <AutomationPerformanceCards
        automationPerformance={automationPerformance}
        isLoading={isLoadingAutomationPerformance}
      />
      <AutomationPerformanceCharts
        automationPerformance={automationPerformance}
      />
      {/* <ROIDateCard ROI={automationPerformance?.ROI} /> TODO: Send ROI Date from API*/}
    </div>
  );
};
