import { CustomPageContainer } from '@/components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const DashboardPage = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('dashboard')} />
    </CustomPageContainer>
  );
};
