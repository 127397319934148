import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LoggedLayout } from '@/components';
import { UserModel } from '@/modules/Users/domain';
import { AuthUserContext } from '@/providers/authUserProvider';

interface PrivateRouteProps {
  checkLoginStatus: () => { isAuthenticated: boolean; user?: UserModel };
  unauthorizedRedirectTo: string;
  children: ReactElement | null;
}

const Redirect = ({ to }: { to: string }) => {
  const location = useLocation();

  return <Navigate to={to} state={{ from: location }} />;
};

export const PrivateRoute = ({
  children,
  checkLoginStatus,
  unauthorizedRedirectTo,
}: PrivateRouteProps): ReactElement => {
  const { isAuthenticated, user } = checkLoginStatus();
  const isLogged = isAuthenticated || false;

  if (!children) return <div />;

  return !isLogged ? (
    <Redirect to={unauthorizedRedirectTo} />
  ) : (
    <AuthUserContext.Provider value={{ authUser: user }}>
      <LoggedLayout>{children}</LoggedLayout>
    </AuthUserContext.Provider>
  );
};
