import { Button } from '@/components';
import { SearchAutoComplete } from '@/components/SearchAutoComplete/SearchAutoComplete';
import { useDebounced } from '@/hooks';
import { UserModel } from '@/modules/Users/domain';
import { useGetUsersBaseInfo } from '@/modules/Users/queries';
import { QueryOptions } from '@tanstack/react-query';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from '../List';
import { useAttachUserOrganization } from '@/modules/Organizations/queries/attach-user-organizations';
import { useAttachUserCompany } from '@/modules/Companies/queries/attach-user-company';
import { useAttachUserBusinessArea } from '@/modules/BusinessAreas/queries/attach-user-businessArea';
import { useAttachUserTask } from '@/modules/Tasks/queries/attach-user-task';
import { toast } from 'react-toastify';
import { queryClient } from '@/constants';
import { OrganizationKeys } from '@/modules/Organizations/queries/types';
import { CompanyKeys } from '@/modules/Companies/queries';
import { BusinessAreasQueryKey } from '@/modules/BusinessAreas/queries/types';
import { TasksQueryKey } from '@/modules/Tasks/queries';

interface AddExistingTabProps {
  handleClose: () => void;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  taskId?: number;
}

export const AddExistingTab = ({
  handleClose,
  organizationId,
  companyId,
  businessAreaId,
  taskId,
}: AddExistingTabProps): ReactElement => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounced(searchTerm, 500) as string;

  const { data: users } = useGetUsersBaseInfo(debouncedSearchTerm, {
    enabled: debouncedSearchTerm?.length !== 0,
  } as QueryOptions<UserModel[], unknown, UserModel[]>);
  const [selectedUsers, setSelectedUsers] = useState<
    Array<{ id: number; value: string; role?: number }>
  >([]);

  const queryOptions = {
    onError: () => {
      toast.error(t('error_adding_existing_user'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([OrganizationKeys.GET]);
      queryClient.invalidateQueries([CompanyKeys.GET]);
      queryClient.invalidateQueries([BusinessAreasQueryKey.GET]);
      queryClient.invalidateQueries([TasksQueryKey.GET]);
      handleClose();
      toast.success(t('success_adding_existing_user'));
    },
  };

  const { mutate: attachUserOrganization } = useAttachUserOrganization();

  const { mutate: attachUserCompany } = useAttachUserCompany();

  const { mutate: attachUserBusinessArea } = useAttachUserBusinessArea();

  const { mutate: attachUserTask } = useAttachUserTask();

  const items = useMemo(() => {
    if (users) {
      return users.map((user: UserModel) => ({
        id: user.id,
        value: user.email,
      }));
    }
    return [];
  }, [users]);

  const handleOnSearch = useCallback((search: string) => {
    setSearchTerm(search);
  }, []);

  const handleOnSelect = useCallback((item: { id: number; value: string }) => {
    setSelectedUsers((prevState: Array<{ id: number; value: string }>) => [
      ...prevState,
      item,
    ]);
    setSearchTerm('');
  }, []);

  const deleteUser = useCallback((index: number) => {
    setSelectedUsers((prevState: Array<{ id: number; value: string }>) =>
      prevState.filter((_, i) => i !== index),
    );
  }, []);

  const selectRole = useCallback((roleId: number, userId: number) => {
    setSelectedUsers((prevState: Array<{ id: number; value: string }>) =>
      prevState.map((user) =>
        user.id === userId ? { ...user, role: roleId } : user,
      ),
    );
  }, []);

  const onSubmit = () => {
    const convertedData: {
      userId: number;
      profileTypeId: number;
    }[] = selectedUsers
      .filter(
        (user): user is { id: number; value: string; role: number } =>
          user.role !== undefined,
      )
      .map((user) => ({ userId: user.id, profileTypeId: user.role }));

    const canAttachUsers = convertedData.length === selectedUsers.length;
    if (!canAttachUsers) {
      toast.error(t('error_select_roles'));
      return;
    }

    if (organizationId) {
      attachUserOrganization(
        {
          userProfiles: convertedData,
          organizationId: organizationId,
        },
        queryOptions,
      );
    }
    if (companyId) {
      attachUserCompany(
        {
          userProfiles: convertedData,
          companyId: companyId,
        },
        queryOptions,
      );
    }
    if (businessAreaId) {
      attachUserBusinessArea(
        {
          userProfiles: convertedData,
          businessAreaId: businessAreaId,
        },
        queryOptions,
      );
    }
    if (taskId) {
      attachUserTask(
        {
          userProfiles: convertedData,
          taskId: taskId,
        },
        queryOptions,
      );
    }
  };

  return (
    <form>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <span className="mb-3 text-sm text-blueNuit">
              {t('add_existing_user')}
            </span>
            <div className="mb-2 text-sm font-bold capitalize">
              {t('search_existing_user_by_email')}
            </div>
            <div className="flex h-full w-full flex-row items-center justify-between gap-4">
              <div className="mb-3 mt-2 h-full flex-1">
                <SearchAutoComplete
                  value={searchTerm}
                  options={items}
                  onChange={handleOnSearch}
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
          </div>
          <div>
            <List
              items={selectedUsers}
              deleteUser={deleteUser}
              selectRole={selectRole}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-between">
        <Button onClick={handleClose} variant="outline" className="h-9 w-24">
          {t('cancel')}
        </Button>
        <Button onClick={onSubmit} className="h-9 w-24">
          {t('add')}
        </Button>
      </div>
    </form>
  );
};
