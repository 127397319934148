import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AutomationQueryKey } from './types';
import { AutomationModel } from '../domain';
import { DataWithPagination } from '@/shared/types';
import { automationsRepo } from '@/constants/providers';
import { SortDirection } from '@/components/Table/types';

export const useGetAutomations = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<AutomationModel[]>,
    any,
    DataWithPagination<AutomationModel[]>
  >,
) =>
  useQuery<DataWithPagination<AutomationModel[]>>(
    [AutomationQueryKey.GET_ALL, { pageNumber, filter, sortBy, sortOrder }],
    () =>
      automationsRepo.getAllAutomations(pageNumber, filter, sortBy, sortOrder),
    options,
  );
