import { SortDirection } from '@/components/Table/types';
import { processesRepo } from '@/constants/providers';
import { ProcessModel } from '@/modules/Processes/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProcessesQueryKey } from './types';

export const useGetProcessesByCompany = (
  companyId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<ProcessModel[]>,
    any,
    DataWithPagination<ProcessModel[]>
  >,
) =>
  useQuery<DataWithPagination<ProcessModel[]>>(
    [
      ProcessesQueryKey.GET_PROCESSES_BY_COMPANY,
      { companyId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      processesRepo.getProcessesByCompany(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
