import { PropsWithChildren, ReactElement } from 'react';
import { Button } from '../Button';
import { twJoin, twMerge } from 'tailwind-merge';

export interface TabProps {
  active?: boolean;
  label?: string;
  badgeNumber?: number;
  onClick?: () => void;
}

export const Tab = ({
  children,
  active,
  badgeNumber,
  onClick,
}: PropsWithChildren<TabProps>): ReactElement => (
  <Button
    variant="text"
    onClick={onClick}
    className={twMerge(
      'h-10 w-full min-w-25 max-w-52 flex-col items-stretch overflow-hidden rounded-sm',
      !active && 'bg-white',
      active && 'border-b-4 border-b-blueNuit bg-rinseGray',
    )}
  >
    <div className="flex items-center gap-2 px-2 text-left">
      <span
        className={twJoin(
          'w-fit text-sm font-black leading-sm',
          active && 'text-blueNuit',
          !active && 'text-primaryBlue',
        )}
      >
        {children}
      </span>
      {!!badgeNumber && (
        <span className="ml-auto mr-0 flex h-5 max-h-7 min-h-5 w-5 min-w-5 max-w-7 items-center justify-center rounded-full bg-intercoastalGray text-xs font-bold text-white">
          {badgeNumber}
        </span>
      )}
    </div>
  </Button>
);
