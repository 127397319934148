import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import { BusinessAreaModel } from '../domain';
import {
  businessAreasMapper,
  businessAreasWithPaginationMapper,
  mapBusinessAreaJsonToBusinessAreaModel,
} from '../mappers';
import {
  ApiResponseBusinessArea,
  ApiResponseBusinessAreas,
  BusinessAreaJson,
  PagedBusinessAreas,
} from '../types';
import { BusinessAreaRepository } from './businessArea.repository';
import { AttachUserBusinessAreaModel } from '../types/attach-user-businessArea-model';

export class HttpBusinessAreaRepository extends BusinessAreaRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  createBusinessArea = async (
    createBusinessAreasJson: BusinessAreaJson,
  ): Promise<BusinessAreaModel> => {
    const createdBusinessAreaJson = await this.client.post<BusinessAreaJson>({
      url: '',
      body: createBusinessAreasJson,
    });
    return mapBusinessAreaJsonToBusinessAreaModel(createdBusinessAreaJson);
  };

  async getAllBusinessAreas(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<BusinessAreaModel[]>> {
    const businessAreasJson = await this.client.get<
      ApiResponseBusinessAreas<PagedBusinessAreas>
    >({
      url: '/me/business-areas',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return businessAreasWithPaginationMapper(businessAreasJson);
  }

  async getBusinessArea(businessAreaId: string): Promise<BusinessAreaModel> {
    const businessAreaJson = await this.client.get<
      ApiResponseBusinessArea<BusinessAreaJson>
    >({
      url: `${businessAreaId}`,
    });

    return mapBusinessAreaJsonToBusinessAreaModel(
      businessAreaJson?.data.businessArea,
    );
  }

  enableBusinessArea = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  attachUserBusinessArea = async (
    data: AttachUserBusinessAreaModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-user`,
    });
  };

  disableBusinessArea = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  updateBusinessArea = async (
    data: BusinessAreaJson,
  ): Promise<BusinessAreaModel> => {
    const updatedBusinessArea = await this.client.put<BusinessAreaJson>(
      `${data.id}`,
      data,
    );
    return mapBusinessAreaJsonToBusinessAreaModel(updatedBusinessArea);
  };

  async getBusinessAreasByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<BusinessAreaModel[]>> {
    const businessAreas = await this.client.get<
      ApiResponseBusinessAreas<PagedBusinessAreas>
    >({
      url: `/companies/${companyId}/business-areas`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return businessAreasWithPaginationMapper(businessAreas);
  }

  async getAllBusinessAreasByCompany(
    companyId: number,
  ): Promise<BusinessAreaModel[]> {
    const businessAreas = await this.client.get<
      ApiResponseBusinessAreas<BusinessAreaJson[]>
    >({
      url: `/companies/${companyId}/business-areas/base-info`,
    });

    return businessAreasMapper(businessAreas.data.businessAreas);
  }

  async getBusinessAreasByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<BusinessAreaModel[]>> {
    const businessAreas = await this.client.get<
      ApiResponseBusinessAreas<PagedBusinessAreas>
    >({
      url: `/organizations/${organizationId}/business-areas`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return businessAreasWithPaginationMapper(businessAreas);
  }

  async getBusinessAreasByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<BusinessAreaModel[]>> {
    const businessAreas = await this.client.get<
      ApiResponseBusinessAreas<PagedBusinessAreas>
    >({
      url: `/users/${userId}/business-areas`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return businessAreasWithPaginationMapper(businessAreas);
  }
}
