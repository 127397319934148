import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { useTranslation } from 'react-i18next';
import { SwitchStatus } from '@/components';
import { SyntheticEvent, useCallback, useState } from 'react';
import { TaskModel } from '@/modules/Tasks/domain';
import { FileCard } from '../FileCard';
import { EditTaskModal } from '../../../TasksPage/components/EditTaskModal';
import { LabourWorkloadTypes } from '@/modules/Tasks/types/labourWorkLoadTypes';

interface HeaderRowProps {
  task?: TaskModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const HeaderRow = ({
  task,
  handleStatus,
  status,
  isLoading,
}: HeaderRowProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <Container isLoading={!task}>
      <div className="flex justify-between">
        <span>
          <h2>{task?.name}</h2>
          <h6>
            <span className="font-bold">
              {task?.organization}, {task?.company}, {task?.businessAreaName}
            </span>
          </h6>
        </span>
        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span
              className={`p-0 ${status ? 'text-americanGreen' : 'brightGray'}`}
            >
              {status ? 'Active' : 'Inactive'}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_task')}
          </Button>
          <EditTaskModal
            isOpen={isOpen}
            id={task?.id}
            handleClose={handleModalState}
          />
        </div>
      </div>
      <div className="flex gap-2.5">
        <Card title={t('task_description')}>{<p>{task?.description}</p>}</Card>
        {task?.code && <Card title={t('code')}>{<p>{task?.code}</p>}</Card>}
      </div>
      <div className="flex gap-2.5">
        <Card title={t('task_periodicity')} isLoading={isLoading}>
          {task?.period}
        </Card>
        {task?.workloadType === LabourWorkloadTypes.TotalTime ? (
          <Card title={t('labour_workload')} isLoading={isLoading}>
            <span className="font-bold">{t('total_time')} </span>
            {task?.workload}
            {t('minutes')}
          </Card>
        ) : (
          <Card title={t('labour_workload')} isLoading={isLoading}>
            {task?.workload}
            {t('minutes')}
            <span className="font-bold"> {t('per_case')}</span>
          </Card>
        )}
        <Card title={t('estimated_cost')} isLoading={isLoading}>
          {task?.estimatedCost.toFixed(2)}
        </Card>
      </div>
      <span className="flex flex-row gap-2.5">
        <FileCard
          title={t('pdd')}
          files={task?.supportDocuments}
          isLoading={isLoading}
          id={task?.id}
        ></FileCard>
      </span>
    </Container>
  );
};
