import {
  forwardRef,
  MouseEvent,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { DropdownInput } from '..';
import { Button } from '../Button';
export interface MultiSelectDropdownOption<T> {
  label: string;
  value: T;
}

interface MultiSelectDropdownProps<T> {
  className?: string;
  placeholder?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  options: MultiSelectDropdownOption<T>[];
  value?: T[];
  onSelect?: (option: MultiSelectDropdownOption<T>, newValue: T[]) => void;
}

const MultiSelectDropdown = forwardRef<
  HTMLDivElement,
  MultiSelectDropdownProps<unknown>
>(
  (
    { className, options, placeholder, buttonRef, value = [], onSelect },
    ref,
  ): ReactElement => {
    const buttonWrapperRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClickOutSide: EventListener = (e) => {
      if (!buttonWrapperRef?.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutSide);
      return () => {
        document.removeEventListener('mousedown', handleClickOutSide);
      };
    }, []);

    const selectedOptionLabels = useMemo(() => {
      const selectedOptions = options?.filter((option) =>
        value.includes(option.value),
      );
      if (selectedOptions.length > 0) {
        return selectedOptions.map((option) => option.label).join(', ');
      }
    }, [value, options]);

    const handleOptionClick = useCallback(
      (option: MultiSelectDropdownOption<unknown>) => () => {
        const newValue = value.includes(option.value)
          ? value.filter((val) => val !== option.value)
          : [...value, option.value];

        onSelect?.(option, newValue);
      },
      [onSelect, value],
    );

    const handleSelectDropdownButtonClick = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsOpen((prev) => !prev);
    };

    return (
      <div
        ref={buttonWrapperRef || ref}
        className={twMerge('relative', className)}
      >
        <DropdownInput
          placeholder={placeholder}
          isOpen={isOpen}
          buttonRef={buttonRef}
          inputValue={selectedOptionLabels}
          onButtonClick={handleSelectDropdownButtonClick}
        >
          <ul className="max-h-48">
            {options.map((opt: MultiSelectDropdownOption<unknown>) => {
              return (
                <li className="group flex" key={`${opt.value}`}>
                  <Button
                    variant="text"
                    className={twMerge(
                      'flex w-full flex-grow text-ellipsis whitespace-nowrap bg-white p-2 text-start outline-none hover:bg-spartanBlue hover:text-white  group-last:rounded-b-md',
                      value.includes(opt.value)
                        ? 'bg-spartanBlue text-white'
                        : 'text-primaryBlue',
                    )}
                    onClick={handleOptionClick(opt)}
                  >
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {opt.label}
                    </span>
                  </Button>
                </li>
              );
            })}
          </ul>
        </DropdownInput>
      </div>
    );
  },
);

MultiSelectDropdown.displayName = 'MultiSelectDropdown';

export { MultiSelectDropdown };
