import { QueryOptions, useQuery } from '@tanstack/react-query';
import { UserModel } from '../domain';
import { UsersKeys } from './types';
import { usersRepo } from '@/constants/providers';

export const useGetUsersBaseInfo = (
  searchTerm?: string,
  options?: QueryOptions<Array<UserModel>>,
) =>
  useQuery<Array<UserModel>>(
    [UsersKeys.GET_BASE, { searchTerm }],
    () => usersRepo.getAllUsersBaseInfo(searchTerm),
    options,
  );
