import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type TasksCountColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const tasksCountColumnConfig = <T extends TableRow>({
  options,
}: TasksCountColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'tasks',
  label: <Trans i18nKey="tasks" />,
  acessorKey: 'tasksCount',
  sortable: true,
  ...options,
});
