import { UserModel } from '@/modules/Users/domain';
import React, { createContext } from 'react';

interface AuthUserContextValue {
  authUser?: UserModel;
}

export const AuthUserContext = createContext<AuthUserContextValue>({});

export const AuthUserProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user: UserModel;
}) => (
  <AuthUserContext.Provider value={{ authUser: user }}>
    {children}
  </AuthUserContext.Provider>
);
