import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from 'react-hook-form';
import { FieldInfo } from '../FieldInfo';
import { Dropdown, SelectDropdownOption } from '../Dropdown';
import { useTranslation } from 'react-i18next';

interface FilterBusinessAreaFormGroupProps<T extends FieldValues> {
  organizationOptions: SelectDropdownOption<string>[];
  companyOptions: SelectDropdownOption<string>[];
  businessAreaOptions: SelectDropdownOption<string>[];
  disableFields?: string[];
  errors: FieldErrors<T>;
  control: Control<T, T>;
}

export const FilterBusinessAreaFormGroup = <T extends FieldValues>({
  organizationOptions,
  companyOptions,
  businessAreaOptions,
  disableFields,
  errors,
  control,
}: FilterBusinessAreaFormGroupProps<T>) => {
  const { t } = useTranslation();

  return (
    <>
      <FieldInfo
        title={t('organization')}
        errorText={
          errors.organizationId?.message
            ? t(errors.organizationId.message as string)
            : undefined
        }
        hasError={!!errors.organizationId?.message}
      >
        <Controller
          name={'organizationId' as Path<T>}
          control={control}
          rules={{ required: true }}
          render={({
            field: { onChange, value, name, ...otherFieldProps },
          }) => (
            <Dropdown
              {...otherFieldProps.onBlur}
              className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
              placeholder={t('organization')}
              options={organizationOptions}
              onSelect={(val) => onChange(val.value)}
              value={value}
              disabled={disableFields && disableFields.includes(name)}
            />
          )}
        />
      </FieldInfo>
      <FieldInfo
        title={t('company')}
        errorText={
          errors.companyId?.message
            ? t(errors.companyId.message as string)
            : undefined
        }
        hasError={!!errors.companyId?.message}
      >
        <Controller
          name={'companyId' as Path<T>}
          control={control}
          rules={{ required: true }}
          render={({
            field: { onChange, name, value, ...otherFieldProps },
          }) => (
            <Dropdown
              {...otherFieldProps.onBlur}
              className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
              placeholder={t('company')}
              options={companyOptions}
              onSelect={(val) => onChange(val.value)}
              value={value}
              disabled={disableFields && disableFields.includes(name)}
            />
          )}
        />
      </FieldInfo>
      <FieldInfo
        title={t('businessArea')}
        errorText={
          errors.businessAreaId?.message
            ? t(errors.businessAreaId.message as string)
            : undefined
        }
        hasError={!!errors.businessAreaId?.message}
      >
        <Controller
          name={'businessAreaId' as Path<T>}
          control={control}
          rules={{ required: true }}
          render={({
            field: { onChange, name, value, ...otherFieldProps },
          }) => (
            <Dropdown
              {...otherFieldProps.onBlur}
              className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
              placeholder={t('businessArea')}
              options={businessAreaOptions}
              onSelect={(val) => onChange(val.value)}
              value={value}
              disabled={disableFields && disableFields.includes(name)}
            />
          )}
        />
      </FieldInfo>
    </>
  );
};
