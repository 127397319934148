import { ReactElement, useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { RouterContext } from '../../contexts';

import { NotFound } from '@/components';
import { RouteEntry, buildRouterConfiguration } from '@/utils';

interface AppRouterProps {
  routes: RouteEntry[];
  catchNotFound?: boolean;
}

export const RouterMap = ({
  routes,
  catchNotFound = true,
}: AppRouterProps): ReactElement | null => {
  const { checkLoginStatus, unauthorizedRedirectTo } =
    useContext(RouterContext);

  const routesMapping = buildRouterConfiguration(routes, {
    checkLoginStatus,
    unauthorizedRedirectTo,
  });

  if (catchNotFound) {
    routesMapping.push({
      path: '*',
      element: <NotFound />,
    });
  }

  return useRoutes(routesMapping);
};
