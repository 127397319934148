import { Collapse, Table } from '@/components';
import { t } from 'i18next';
import {
  nameWithAvatarColumnConfig,
  businessAreaNameColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  periodColumnConfig,
  workloadColumnConfig,
  estimatedCostColumnConfig,
  automatedColumnConfig,
  codeColumnConfig,
} from '@/utils';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from '@/components/Checkbox';
import {
  useGetAllWorkflowsByProcessIdAutomation,
  WorkflowsQueryKey,
} from '@/modules/Workflows/queries';
import { useAssociateAutomation } from '@/modules/Tasks/queries/associate-automation';
import { useDisassociateAutomation } from '@/modules/Tasks/queries/disassociate-automation';
import { toast } from 'react-toastify';
import { queryClient } from '@/constants';
import { AutomationQueryKey } from '@/modules/Automations/queries/types';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { TableRow } from '@/components/Table/types';
import { useNavigate } from 'react-router-dom';

export const AssociatedTasksTab = ({
  processId = '',
  automationId,
}: {
  processId?: string;
  automationId: number;
}) => {
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
  const { data } = useGetAllWorkflowsByProcessIdAutomation(parseInt(processId));
  const { mutate: associateAutomation } = useAssociateAutomation();
  const { mutate: disassociateAutomation } = useDisassociateAutomation();
  const navigate = useNavigate();

  const initialSelectedTasks: number[] = useMemo(() => {
    const temp: number[] = [];
    data?.forEach((workflow) =>
      workflow.tasks?.forEach((task) => {
        if (task.automationId && task.automationId === automationId) {
          temp.push(task.id);
        }
      }),
    );
    return temp;
  }, [data, automationId]);

  useEffect(() => {
    if (selectedTasks.length !== initialSelectedTasks.length) {
      setSelectedTasks(initialSelectedTasks);
    }
  }, [initialSelectedTasks, selectedTasks]);

  const handleSelectTasks = useCallback(
    (taskId?: number) => {
      if (!taskId) return;
      const index = selectedTasks.indexOf(taskId);
      if (index === -1) {
        setSelectedTasks([...selectedTasks, taskId]);
        associateAutomation(
          { taskId, automationId },
          {
            onError: () => {
              toast.error(t('error_associating_automation'));
            },
            onSuccess: () => {
              queryClient.invalidateQueries([AutomationQueryKey.ById]);
              queryClient.invalidateQueries([
                WorkflowsQueryKey.GET_ALL_WORKFLOWS_BY_PROCESS_AUTOMATION,
              ]);
              toast.success(t('success_associating_automation'));
            },
          },
        );
      } else {
        setSelectedTasks(selectedTasks.filter((id) => id !== taskId));
        disassociateAutomation(
          { taskId, automationId },
          {
            onError: () => {
              toast.error(t('error_disassociating_automation'));
            },
            onSuccess: () => {
              queryClient.invalidateQueries([AutomationQueryKey.ById]);
              queryClient.invalidateQueries([
                WorkflowsQueryKey.GET_ALL_WORKFLOWS_BY_PROCESS_AUTOMATION,
              ]);
              toast.success(t('success_disassociating_automation'));
            },
          },
        );
      }
    },
    [
      setSelectedTasks,
      selectedTasks,
      associateAutomation,
      disassociateAutomation,
      automationId,
    ],
  );

  const routeChange = (row?: TableRow) => {
    const path = `/tasks/${row?.id}`;
    navigate(path);
  };

  return (
    <>
      <h1 className="mb-3 py-7">{t('process')}</h1>
      {data?.map((workflow, index) => (
        <Collapse
          key={index}
          title={workflow?.name}
          subText={`(${workflow?.tasks.length} Tasks)`}
        >
          <Table
            columns={[
              nameWithAvatarColumnConfig({ onClick: routeChange }),
              codeColumnConfig(),
              processOrganizationNameColumnConfig(),
              processCompanyNameColumnConfig(),
              businessAreaNameColumnConfig(),
              statusColumnConfig(),
              usersCountColumnConfig(),
              dividerColumnConfig({ key: 'divider-1' }),
              periodColumnConfig(),
              workloadColumnConfig(),
              estimatedCostColumnConfig(),
              dividerColumnConfig({ key: 'divider-2' }),
              automatedColumnConfig(),
            ]}
            rows={workflow?.tasks || []}
            noResultsLabel={t('no_tasks_available')}
            renderItem={(id, disabled) => (
              <Checkbox
                checked={id ? selectedTasks.includes(id) : false}
                disabled={disabled}
                onClick={() => handleSelectTasks(id)}
              />
            )}
            highlightRows={selectedTasks}
            checkPropertyTodisable={'automationId'}
            checkValueToDisable={automationId}
          />
        </Collapse>
      ))}
    </>
  );
};
