import { useQuery } from '@tanstack/react-query';
import { UsersKeys } from './types';
import { usersRepo } from '@/constants/providers';
import { UserModel } from '../domain';

export const useGetCurrentUser = (token?: string) =>
  useQuery<UserModel>(
    [UsersKeys.GET_CURRENT_USER],
    () => usersRepo.getCurrentUser(token),
    {
      enabled: !!token,
    },
  );
